import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    padding: [[28, 0]],
    border: [[1.4, 'dashed', '#4772FA']],
    borderRadius: 8,
    outline: 'none'
  },
  selectedFile: {
    backgroundColor: 'white',
    padding: [[28, 38]],
    position: 'relative'
  },
  emptyMessage: {
    color: '#828282',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    margin: 0,
    textAlign: 'center'
  },
  name: {
    color: '#262626'
  },
  clickHereLabel: {
    color: '#4772FA',
    cursor: 'pointer'
  },
  attachmentIcon: {
    position: 'relative',
    top: 2
  },
  fileInfo: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    display: 'inline-block',
    margin: [[0, 0, 0, 14]]
  },
  size: {
    color: '#262626',
    fontSize: 14,
    opacity: 0.5
  },
  removeFile: {
    border: 0,
    padding: 0,
    backgroundColor: 'transparent',
    fontSize: 14,
    color: '#262626',
    opacity: 0.3,
    textAlign: 'right',
    position: 'absolute',
    top: 18,
    right: 18,
    cursor: 'pointer'
  },
  dragActive: {
    backgroundColor: '#FFFFFF'
  }
}));
