import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={38} height={28} viewBox="0 0 38 28" fill="none" {...props}>
      <path
        d="M34.549.64H3.45A3.44 3.44 0 000 4.093v19.816a3.44 3.44 0 003.451 3.451H34.55A3.44 3.44 0 0038 23.91V4.091A3.44 3.44 0 0034.549.64z"
        fill="#DADADA"
      />
      <path
        d="M29.094 14a4.458 4.458 0 01-4.453-4.453 4.458 4.458 0 014.453-4.453 4.458 4.458 0 014.453 4.453A4.458 4.458 0 0129.094 14z"
        fill="#A5A5A5"
      />
      <path
        d="M14.547 14h-8.98a1.113 1.113 0 01-1.114-1.113v-6.68c0-.615.498-1.113 1.113-1.113h8.98c.616 0 1.114.498 1.114 1.113v6.68c0 .615-.498 1.113-1.113 1.113z"
        fill="#E4E4E4"
      />
      <path
        d="M32.434 22.906h-3.34a1.113 1.113 0 110-2.226h3.34a1.113 1.113 0 110 2.226zM8.906 22.906h-3.34a1.113 1.113 0 110-2.226h3.34a1.113 1.113 0 110 2.226zM24.64 22.906h-3.413a1.113 1.113 0 110-2.226h3.414a1.113 1.113 0 110 2.226zM16.773 22.906h-3.34a1.113 1.113 0 110-2.226h3.34a1.113 1.113 0 110 2.226z"
        fill="#F4F4F4"
      />
      <path
        d="M22.34 5.094A4.399 4.399 0 0019 6.63a4.286 4.286 0 00-1.113 2.917c0 1.113.4 2.137 1.113 2.917A4.399 4.399 0 0022.34 14c2.449 0 4.527-2.004 4.527-4.453 0-2.45-2.078-4.453-4.527-4.453z"
        fill="#C7C7C7"
      />
    </svg>
  )
}

export default SvgComponent
