import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    width: '100vw',
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    zIndex: 1000,
  },
  wrapper: {
    position: 'relative',
    transform: 'scale(0.5)',
    [theme.breakpoints.up('sm')]: {
      transform: 'scale(0.75)'
    },
    [theme.breakpoints.up('md')]: {
      transform: 'scale(1)'
    },
  },
  dot: {
    position: 'absolute',
    width: 23,
    height: 23,
    backgroundColor: '#3CD3AE',
    top: 80,
    right: 0,
    borderRadius: 9.4,
    opacity: 0.6
  },
  dot1: {
    animationFillMode: 'both',
    animation: "$dot 2.1s 1s infinite ease-in-out"
  },
  dot2: {
    animationFillMode: 'both',
    animation: "$dot 2.1s 0s infinite ease-in-out"
  },
  '@keyframes dot': {
    '0%': {
      transform: 'scale(0)'
    },
    '50%': {
      transform: 'scale(1.0)'
    },
    '100%': {
      transform: 'scale(0)'
    }
  },
}));
