import HttpRequest from '../../services/http_request';
import Qs from 'qs';
import normalize from 'json-api-normalize';
import { PAGINATION } from '../../lib/enums';

export const ListMarketingAgents = async (page=1, search={}, sort) => {
  return new Promise( async (resolve, reject) => {
    try {
      const queryParams = Qs.stringify(
        {
          items: PAGINATION.ROWS_PER_PAGE,
          page,
          q: search,
          sort
        }, {
          encode: false
        }
      );

      const { data } = await HttpRequest({
        method: 'GET',
        url: `/admin/marketing_agents?${queryParams}`,
      });

      resolve({
        items: normalizerCompletedPlan(data.marketingAgents),
        pagination: data.metadata
      });
    } catch (error) {
      reject({
        items: [],
        pagination: null
      });
    }
  });
}

export const CreateMarketingAgent = async params =>{
  try{
    const resp = await HttpRequest({
      method: 'POST',
      url: '/admin/marketing_agents',
      data: params
    });

    return {
      success: true,
      resp: resp,
      item: normalizer(resp.data.marketingAgent)
    }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};

export const UpdateMarketingAgent = async (id, params) =>{
  try{
    const resp = await HttpRequest({
      method: 'PUT',
      url: `/admin/marketing_agents/${id}`,
      data: params
    });

    return {
      success: true,
      resp: resp
    }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};

export const ShowMarketingAgent = async id => {
  return new Promise( async (resolve, reject) => {
    try {
      const { data } = await HttpRequest({
        method: 'GET',
        url: `/admin/marketing_agents/${id}`,
      });

      resolve({
        item: normalizerCompletedPlan(data.marketingAgent)
      });
    } catch (error) {
      reject({
        item: null
      });
    }
  });
}

const normalizer = data => normalize(data).get([
  'id',
  'firstName',
  'lastName',
  'email',
  'phoneNumber',
  'residenceCity',
  'residenceCountry'
]);

const normalizerCompletedPlan = data => normalize(data).get([
  'id',
  'firstName',
  'lastName',
  'email',
  'phoneNumber',
  'plans.id',
  'plans.name',
  'currentMarketingScore.marketingLevelId',
  'currentMarketingScore.score',
  'currentMarketingScore.completed',
  'currentMarketingScore.marketingLevel.name',
  'currentMarketingScore.marketingLevel.endPoints',
]);
