import { createMuiTheme } from '@material-ui/core/styles';

//override the styles here
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4772FA',
      dark: '#335EE6'
    },
    secondary: {
      main: '#3CD3AE'
    }
  },
  typography: {
    "fontFamily": "\"Poppins\", \"Roboto\"",
  }
});

export default theme;
