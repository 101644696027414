import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3h3a1 1 0 011 1v10a1 1 0 01-1 1H1a1 1 0 01-1-1V4a1 1 0 011-1h3V2a1 1 0 012 0v1h4V2a1 1 0 112 0v1zM2 13h12V5h-2v1a1 1 0 11-2 0V5H6v1a1 1 0 01-2 0V5H2v8z"
        fill="#FEC703"
      />
    </svg>
  )
}

export default SvgComponent
