import { createContext } from 'react';
import { SET_GLOBAL_DATA } from './constants';
import normalize from 'json-api-normalize';
import HttpRequest from '../../services/http_request';

export const GlobalDataContext = createContext(null);

export function setGlobalData(data) {
  return {
    type: SET_GLOBAL_DATA,
    data
  };
}

export default function GlobalData(state, action){
  switch (action.type) {
    case SET_GLOBAL_DATA:
      return {
        ...state,
        ...action.data
      }
    default:
      return state
  }
}

export const initialGlobalData = {
  advertisingCategory: [],
  plans: []
}

export const FetchGlobalData = async (dispatch) => {
  try {
    const {data: {
      advertisingCategories,
      plans
    }} = await HttpRequest({
      method: 'GET',
      url: '/admin/global_data'
    });

    dispatch(setGlobalData(
      {
        advertisingCategory: advertisingCategoryNormalizer(advertisingCategories),
        plans: plansNormalizer(plans),
      }
    ));

    return {
      success: true
    }
  } catch (error) {
    return {
      success: false,
      errors: {
        email: 'Correo eléctronico o password invalidos'
      }
    }
  }
};

const advertisingCategoryNormalizer = data => normalize(data).get([
  'id',
  'name'
]);

const plansNormalizer = data => normalize(data).get([
  'id',
  'name',
  'rate',
  'time',
  'active',
  'planType'
]);
