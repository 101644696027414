import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  root: {
    background: '#FFFFFF',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    borderRadius: 2,
    padding: [[36, 24]]
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30
  },
  title: {
    color: '#000000',
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0.4,
    margin: 0
  },
  year: {
    color: '#929CB0',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0.4,
    textTransform: 'uppercase',
    margin: [[0, 4]]
  },
  graphNavigation: {
    alignItems: 'center',
    display: 'flex'
  },
  iconNavigationIcon: {
    color: '#929CB0'
  }
}));

export default styles;
