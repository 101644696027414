import HttpRequest from '../../services/http_request';
import Qs from 'qs';
import normalize from 'json-api-normalize';
import { PAGINATION } from  '../../lib/enums';

export const HighlightProfessional = async id => {
  try{
      const resp = await HttpRequest({
        method: 'POST',
        url: `/admin/professionals/${id}/highlight`,
      });

      return {
        success: true,
        resp: resp
      }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
}

export const DownplayProfessional = async id => {
  try{
      const resp = await HttpRequest({
        method: 'POST',
        url: `/admin/professionals/${id}/downplay`,
      });

      return {
        success: true,
        resp: resp
      }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
}

export const ShowProfessional = async id => {
  return new Promise( async (resolve, reject) => {
    try {
      const { data } = await HttpRequest({
        method: 'GET',
        url: `/admin/professionals/${id}`,
      });

      const normalizedData = normalizerCompledProfessionalInfo(data.professional);

      resolve({
        item: {
          id: normalizedData.id,
          email: normalizedData.email,
          firstName: normalizedData.firstName,
          lastName: normalizedData.lastName,
          identificationType: normalizedData.identificationType,
          identificationNumber: normalizedData.identificationNumber,
          gender: normalizedData.gender,
          phoneNumber: normalizedData.phoneNumber,
          birthdate: normalizedData.birthdate,
          residenceCountry: normalizedData.residenceCountry,
          residenceCity: normalizedData.residenceCity,
          accountLinked: normalizedData.accountLinked,
          services: normalizedData.professionalServices,
          ...normalizedData.professionalAdditionalInfo,
          ...normalizedData.metadataProfessional
        }
      });
    } catch (error) {
      reject({
        item: null
      });
    }
  });
}

export const ListProfessionals = async (page=1, search={}, sort) => {
  return new Promise( async (resolve, reject) => {
    try {
      const queryParams = Qs.stringify(
        {
          items: PAGINATION.ROWS_PER_PAGE,
          page,
          q: search,
          sort
        }, {
          encode: false
        }
      );

      const { data } = await HttpRequest({
        method: 'GET',
        url: `/admin/professionals?${queryParams}`,
      });

      resolve({
        items: normalizerCompledProfessionalInfo(data.professionals),
        pagination: data.metadata
      });
    } catch (error) {
      reject({
        items: [],
        pagination: null
      });
    }
  });
}

export const UpdateProfessional = async (id, params) =>{
  try{
      const resp = await HttpRequest({
        method: 'PUT',
        url: '/professionals/',
        data: params,
      });

      return {
        success: true,
        resp: resp
      }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};

const normalizerCompledProfessionalInfo = data => normalize(data).get([
  'id',
  'email',
  'firstName',
  'lastName',
  'identificationType',
  'identificationNumber',
  'gender',
  'phoneNumber',
  'birthdate',
  'residenceCountry',
  'residenceCity',
  'professionalAdditionalInfo.acceptedTermsAndConditions',
  'professionalAdditionalInfo.certificateOfDegree',
  'professionalAdditionalInfo.certificateOfDegreeFileName',
  'professionalAdditionalInfo.certificateOfDegreeFileSize',
  'professionalAdditionalInfo.description',
  'professionalAdditionalInfo.idCard',
  'professionalAdditionalInfo.identificationCard',
  'professionalAdditionalInfo.identificationCardFileName',
  'professionalAdditionalInfo.identificationCardFileSize',
  'professionalAdditionalInfo.profession',
  'professionalAdditionalInfo.professionGraduationYear',
  'professionalAdditionalInfo.professionalCard',
  'professionalAdditionalInfo.professionalCardFileName',
  'professionalAdditionalInfo.professionalCardFileSize',
  'professionalAdditionalInfo.rate',
  'professionalAdditionalInfo.specialty',
  'professionalAdditionalInfo.specialtyGraduationYear',
  'professionalAdditionalInfo.highlighted',
  'metadataProfessional.availableTime',
  'metadataProfessional.howDidYouKnowAboutUs',
  'professionalServices.name',
  'professionalServices.rate'
]);
