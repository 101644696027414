import React, { Fragment, useState, useCallback, useEffect, useMemo } from 'react';

//Import external libraries
import _snakeCase from 'lodash.snakecase';

//Import utils or services

//Import shared components, order A-Z
import Alert from '../shared/alert';
import Loading from '../shared/loading';
import Table from '../shared/table';
import TableWrapper from '../shared/table_wrapper';
import LockIcon from '../shared/icons/lock';
import UnlockIcon from '../shared/icons/unlock';

//Import controllers
import { ListCustomers, ActivateCustomer } from '../../controllers/customers'

//Import styles

//Import constants or services
import { columns } from './data_table_schema';

import ActivateToggleModal from './activate_toggle_modal';

const Customers = () =>{
  const [alert] = useState({});
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search] = useState({});
  const [paginationInfo, setPaginationInfo] = useState();
  const [order] = useState('desc');
  const [orderBy] = useState('firstName');
  const [activateToggleInfo, setActivateToggleInfo] = useState({})

  const fetchData = useCallback( async (page = 1) => {
    setLoading(true);
    const { items, pagination} = await ListCustomers(page, {...search}, `${_snakeCase(orderBy)} ${order}`);
    setItems(items);
    setPaginationInfo(pagination);
    setLoading(false);
  }, [search, order, orderBy]);

  useEffect(() => {
    fetchData();
  }, [search, order, orderBy, fetchData]);

  const handleActivateToggle = useCallback(({ original: { id, active } }) => {
    return (
      <button
        onClick={evt => {
          evt.stopPropagation();
          setActivateToggleInfo({ id, active });
        }}
      >
        {active ? <UnlockIcon width={20} height={20} /> : <LockIcon width={20} height={20} /> }
      </button>
    )
  }, []);

  const activateToggleConfirmContent = useMemo(() => {
    return activateToggleInfo.active ? (
      <Fragment>
        <p style={{ margin: '0 0 16px 0', fontSize: 20, fontWeight: 500 }}>¿Esta seguro de bloquear el acceso?</p>
        <p style={{ margin: '0 0 32px 0', fontSize: 16, fontWeight: 300 }}>Una vez bloqueado, el cliente no podrá volver a utilizar la aplicación</p>
      </Fragment>

    ) : (
      <Fragment>
        <p style={{ margin: '0 0 16px 0', fontSize: 20, fontWeight: 500 }}>¿Esta seguro de desbloquear el acceso?</p>
        <p style={{ margin: '0 0 32px 0', fontSize: 16, fontWeight: 300 }}>Una vez desbloqueado, el cliente podrá volver a usar la aplicación</p>
      </Fragment>
    )
  }, [activateToggleInfo.active])

  const handleCloseActiveToggleConfirm = useCallback(() => {
    setActivateToggleInfo({});
  }, [])

  const handleActivateToggleSubmit = useCallback(async (evt) => {
    evt.preventDefault();
    await ActivateCustomer(activateToggleInfo.id, !activateToggleInfo.active);
    handleCloseActiveToggleConfirm();
    fetchData(paginationInfo.page);
  }, [activateToggleInfo, paginationInfo?.page, fetchData, handleCloseActiveToggleConfirm])

  return(
    <div>
      <Alert type={alert.type} message={alert.message} display={!!alert.type}/>
      <TableWrapper
        totalItems={paginationInfo ? `+${paginationInfo.count}` : '+0'}
        title='Usuarios'
        table={
          <Table
            columns={columns}
            data={items}
            loading={loading}
            fetchData={fetchData}
            paginationInfo={paginationInfo}
            customRowAction={handleActivateToggle}
          />
        }
      />
      {activateToggleInfo.id ? (
        <ActivateToggleModal
          onClose={handleCloseActiveToggleConfirm}
          content={activateToggleConfirmContent}
          onSubmit={handleActivateToggleSubmit}
        />
      ) : null}
    </div>
  )
}

export default Customers;
