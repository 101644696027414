export const columns = [{
   Header: 'id', //Label de la columna de la tabla
   accessor: 'id', //Attributo que se va a mostrar como el valor de la celda
 },
 {
  Header: 'Nombres', //Label de la columna de la tabla
  accessor: 'firstName' //Attributo que se va a mostrar como el valor de la celda
},
{
  Header: 'Apellidos', //Label de la columna de la tabla
  accessor: 'lastName', //Attributo que se va a mostrar como el valor de la celda
},
{
  Header: 'Correo', //Label de la columna de la tabla
  accessor: 'email', //Attributo que se va a mostrar como el valor de la celda
},
{
  Header: 'Teléfono', //Label de la columna de la tabla
  accessor: 'phoneNumber', //Attributo que se va a mostrar como el valor de la celda
}];
