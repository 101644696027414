import React, { memo } from 'react';

import Modal from '../shared/modal';

const ActivateToggleModal = ({ loading, content, onSubmit, onClose }) => {
  return (
    <Modal
      title="Acceso a la aplicación"
      buttonSaveLabel="Confirmar"
      onSubmit={onSubmit}
      isSubmitting={loading}
      onClose={onClose}
      content={content}
    />
  )
}

export default memo(ActivateToggleModal);
