import React, { Fragment, useState, useCallback, useEffect, useMemo } from 'react';

//Import external libraries
import _snakeCase from 'lodash.snakecase';

//Import utils or services

//Import shared components, order A-Z
import Alert from '../shared/alert';
import Loading from '../shared/loading';
import Table from '../shared/table';
import TableWrapper from '../shared/table_wrapper';
import LockIcon from '../shared/icons/lock';
import UnlockIcon from '../shared/icons/unlock';

//Import controllers
import { ListAppointments } from '../../controllers/appointments'

//Import styles

//Import constants or services
import { columns } from './data_table_schema';

const Appointments = () =>{
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search] = useState({});
  const [paginationInfo, setPaginationInfo] = useState();
  const [order] = useState('desc');
  const [orderBy] = useState('firstName');
  const [activateToggleInfo, setActivateToggleInfo] = useState({})

  const fetchData = useCallback( async (page = 1) => {
    setLoading(true);
    const { items, pagination} = await ListAppointments(page, {...search}, `${_snakeCase(orderBy)} ${order}`);
    setItems(items);
    setPaginationInfo(pagination);
    setLoading(false);
  }, [search, order, orderBy]);

  useEffect(() => {
    fetchData();
  }, [search, order, orderBy, fetchData]);

  return(
    <div>
      <TableWrapper
        totalItems={paginationInfo ? `+${paginationInfo.count}` : '+0'}
        title='Consultas'
        table={
          <Table
            columns={columns}
            data={items}
            loading={loading}
            fetchData={fetchData}
            paginationInfo={paginationInfo}
          />
        }
      />
    </div>
  )
}

export default Appointments;
