import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  header: {
    display: 'flex',
    paddingBottom: 16,
    paddingTop: 24,
  },
  headerActions: {
    position: 'relative',
    top: 4,
    '& > button': {
      marginRight: 10
    },
    '& > button:last-child': {
      marginRight: 0
    }
  },
  pageInformation: {
    marginBottom: 22,
    flex: 1
  },
  pageTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
    opacity: 0.9,
    margin: [[0, 0, 8]]
  },
  pageSubtitle: {
    color: '#4F4F4F',
    fontSize: 16,
    margin: 0
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  wrapperContent: {
    display: 'flex',
    margin: [[0, 'auto']],
    width: '81.6666%'
  },
  wrapperBody: {
    flexDirection: 'column',
    '& .table-cell': {
      justifyContent: 'space-between'
    },
  },
  breadcrumb: {
    alignItems: 'center',
    color: '#929CB0',
    display: 'flex',
    fontSize: 14,
    letterSpacing: 0.4,
    '& > a': {
      alignItems: 'center',
      color: '#929CB0',
      display: 'flex',
      marginRight: 4,
      textDecoration: 'none'
    },
    '& > span': {
      fontWeight: 'bold',
      marginLeft: 4
    }
  }
}));

export default styles;
