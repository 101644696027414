import React from 'react';
import clsx from 'clsx';
import MUIFormGroup from '@material-ui/core/FormGroup';
import MUIInputLabel from '@material-ui/core/InputLabel';
import MUISelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MUIFormHelperText from '@material-ui/core/FormHelperText';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import useStyles from './__styles__';

const SelectField = ({ label, name, value, onChange, options = [], error, errorMessage, displayEmpty = false, displayEmptyLabel = 'Todos', disabled, classes = {}, ...props}) => {
  const styles = useStyles();

  return (
    <MUIFormGroup>
      {
        label
        &&
        <MUIInputLabel
          classes={{
            root: styles.label
          }}
          error={error}
        >
          {label}
          {props.required && <span className={styles.required}>*</span>}
        </MUIInputLabel>
      }
      <MUISelect
        name={name}
        value={value}
        variant="outlined"
        fullWidth={true}
        onChange={onChange}
        error={Boolean(error)}
        disabled={disabled}
        className={styles.selectRoot}
        IconComponent={KeyboardArrowDownIcon}
        classes={{
          root: clsx(styles.baseRoot, classes.root),
          icon: styles.icon
        }}
      >
        {
          displayEmpty
          &&
          <MenuItem value=""><span className={styles.displayEmptyOption}>{displayEmptyLabel}</span></MenuItem>
        }
        {
          options.map( option => (
            <MenuItem value={option.value} key={option.value} disabled={option.disabled}>{option.label}</MenuItem>
          ))
        }
      </MUISelect>
      {errorMessage && <MUIFormHelperText error>{errorMessage}</MUIFormHelperText>}
    </MUIFormGroup>
  );
}

export default SelectField;
