import React from 'react';
import clsx from 'clsx';
import { animated } from 'react-spring';

import NameIcon from '../shared/icons/name';

import useStyles from './__styles__';

const LoadingScreen = ({...props}) => {
  const styles = useStyles();
  return (
    <animated.div className={styles.root} {...props}>
      <div className={styles.wrapper}>
        <div className={clsx(styles.dot, styles.dot1)}></div>
        <div className={clsx(styles.dot, styles.dot2)}></div>
        <NameIcon size={250}/>
      </div>
    </animated.div>
  )
}

export default LoadingScreen;
