import React, { useState } from 'react';
import clsx from 'clsx';
import _isFunction from 'lodash/isFunction';

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useStyles from './__styles__';

const CollapseWrapper = ({ header, defaultExpanded, body, classes={} }) => {
  const styles = useStyles();

  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <div className={clsx(styles.baseRoot, classes.root)}>
      <div className={styles.header}>
        {_isFunction(header)
          ? header({ ...this.props, ...this.state })
          : <p className={styles.headerDefaultTitle}>{header}</p>}
        <IconButton
          onClick={() => setExpanded(!expanded)}
          className={clsx(styles.expandButton, {
            [styles.expandOpen]: expanded
          })}
          aria-expanded={expanded}
          aria-label="Show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </div>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        className={clsx(styles.bodyRoot, classes.body)}
      >
        {body}
      </Collapse>
    </div>
  )
}

export default CollapseWrapper;
