import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import lscache from 'lscache';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import IconButton from '@material-ui/core/IconButton';

import AdminLogoIcon from '../shared/icons/admin_logo';
import UserIcon from '../shared/icons/circle_user';

import { CurrentUserContext, VerifyAuthentication, Logout } from '../../controllers/users/current';

import useStyles from './__styles__/marketing_agent';

const MarketingAgentLayout = ({ component: Component }) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const { currentUser, dispatchCurrentUser } = useContext(CurrentUserContext);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = useCallback(async () => {
    await Logout(dispatchCurrentUser);
  }, [dispatchCurrentUser]);

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <div className={styles.mainHeader}>
          <AdminLogoIcon className={styles.sidebarLogo}/>
          <IconButton
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <UserIcon color="#FFFFFF"/>
          </IconButton>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 10}}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow">
                      <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        <div className={styles.mainContent}>
          <Component />
        </div>
      </div>
    </div>
  )
}

export default MarketingAgentLayout;
