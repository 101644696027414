import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Router, Switch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { history } from './history';

import PrivateRoute from './private_route';
import AuthRoute from './auth_route';
import Route from './route';

//Auth routes
import SignIn from '../components/sign_in';
import ForgotPassword from '../components/forgot_password';
import SetPassword from '../components/set_password';
import LoadingScreen from '../components/loading_screen';

//Public routes
import Register from '../components/register';
import Subscribe from '../components/subscribe';

//Admin routes
import Advertisements from '../components/advertisements';
import Appointments from '../components/appointments';
import Customers from '../components/customers';
import Applicants from '../components/applicants';
import ApplicantDetail from '../components/applicant_detail';
import MarketingAgentDetail from '../components/marketing_agent_detail';
import MarketingAgents from '../components/marketing_agents';
import MarketingCustomers from '../components/marketing_customers';
import MarketingDashboard from '../components/marketing_dashboard';
import MarketingLevels from '../components/marketing_levels';
import MarketingPlans from '../components/marketing_plans';
import PaymentSucess from '../components/payment_success';
import SubcriptionProcessingPayment from '../components/subscribe/processing_payment';
import Professionals from '../components/professional';
import ProfessionalDetail from '../components/professional_detail';

import { CurrentUserContext } from '../controllers/users/current';

const Routes = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const [isAdmin, setIsAdmin] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser.role === 'marketing_agent') {
      setIsAdmin(false);
    }
    if (currentUser.role === 'admin') {
      setIsAdmin(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, parseInt(process.env.REACT_APP_DEFAULT_LOADING_SCREEN_TIME) * 1000)
  }, [currentUser.role]);

  return (
    <Router history={history}>
      <Switch>
        <AuthRoute exact path="/sign_in" component={SignIn} />
        <AuthRoute exact path="/forgot_password" component={ForgotPassword} />
        <AuthRoute exact path="/set_password" component={SetPassword} />
        <Route exact path="/subscribe/processing" component={SubcriptionProcessingPayment} />
        <Route exact path="/payment_success" component={PaymentSucess} />
        <Route
          exact
          path="/register"
          component={Register}
        />
        <Route
          exact
          path="/subscribe"
          component={Subscribe}
        />
        {
          loading && (
            <LoadingScreen />
          )
        }
        <CSSTransition
          in={!loading}
          timeout={parseInt(process.env.REACT_APP_DEFAULT_LOADING_SCREEN_TIME) * 1000}
          mountOnEnter
          unmountOnExit
          classNames="content"
        >
          <div>
            <PrivateRoute exact path="/" component={isAdmin ? Applicants : MarketingDashboard} layout={isAdmin ? 'admin' : 'marketingAgent'} />
            {
              isAdmin === true
              &&
              <React.Fragment>
                <PrivateRoute exact path="/applicants/:id" component={ApplicantDetail} layout='admin'/>
                <PrivateRoute exact path="/appointments" component={Appointments} layout='admin'/>
                <PrivateRoute exact path="/advertisements" component={Advertisements} layout='admin' />
                <PrivateRoute exact path="/marketing_agents" component={MarketingAgents} layout='admin'/>
                <PrivateRoute exact path="/customers" component={Customers} layout='admin'/>
                <PrivateRoute exact path="/marketing_agents/:id" component={MarketingAgentDetail} layout='admin'/>
                <PrivateRoute exact path="/marketing_levels" component={MarketingLevels} layout='admin'/>
                <PrivateRoute exact path="/marketing_plans" component={MarketingPlans} layout='admin'/>
                <PrivateRoute exact path="/professionals" component={Professionals} layout='admin'/>
                <PrivateRoute exact path="/professionals/:id" component={ProfessionalDetail} layout='admin'/>
              </React.Fragment>
            }
            {
              isAdmin === false
              &&
              <React.Fragment>
                <PrivateRoute exact path="/customers" component={MarketingCustomers} layout='marketingAgent'/>
              </React.Fragment>
            }
          </div>
        </CSSTransition>
        <Route component={() => (<div>404</div>)} />
      </Switch>
    </Router>
  )
}

export default Routes;
