import React, { Fragment, useState, useEffect, useMemo, useCallback } from 'react';
import queryString from 'query-string';

//Import external libraries

//Import utils or services
import { currencyFormatter } from '../../lib/utils';
import { history } from '../../config/history';

//Import shared components, order A-Z
import Alert from '../shared/alert';
import AppleStoreIcon from '../shared/icons/apple_store';
import Button from '../shared/button';
import LogoIcon from '../shared/icons/logo';
import PlayStoreIcon from '../shared/icons/play_store';
import LoadingScreen from '../loading_screen';

//Import controllers
import { VerifySubscriptionToken, UseSubcription } from '../../controllers/subscriptions';

//Import styles
import useStyles from './__styles__';

//Import local components
import Form from './form';

//Import constants or services

const Subscribe = () => {
  const styles = useStyles();
  const [displayForm, setDisplayForm] = useState(false);
  const [token, setToken] = useState(null);
  const [customer, setCustomer] = useState({});
  const [plan, setPlan] = useState({});
  const [agreement, setAgreement] = useState({});
  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(true);

  const sendToLanding = useCallback(() => {
    window.location = 'https://www.portafolioblue.com/';
  }, []);

  useEffect(() => {
    const { token } = queryString.parse(window.location.search);
    if (!token) {
      sendToLanding();
    }
    const fetch = async () => {
      const { success, resp } = await VerifySubscriptionToken(token);
      if (success) {
        setLoading(false);
        const { token, customer, agreementtable: plan, ...agreement } = resp;
        setToken(token);
        setCustomer(customer);
        setPlan(plan);
        setAgreement(agreement);
      } else {
        sendToLanding();
      }
    }
    fetch();
  }, []);

  const handleAlert = useCallback(message => {
    setAlert(message);
    setTimeout(() => setAlert({}), 5000);
  }, []);

  const handleSubcriptionPaid = useCallback(async invitationToken => {
    const { success } = await UseSubcription(token);
    if (success) {
      history.push({ pathname: '/subscribe/processing' });
    }
  }, [token]);

  return (
    loading ? (
      <LoadingScreen />
    ) : (
      <Fragment>
        <header className={styles.header}>
          <div className={styles.headerWrapper}>
            <h1 className={styles.headerTitle}>
              <span>Se parte de nuestros comunidad Blue, ¡Suscribete!</span>
              <LogoIcon />
            </h1>
            <nav className={styles.headerNavigationBar}>
              <a href='/features'>Features</a>
              <a href='/ads'>Ads</a>
              <a href='/contact'>Contact</a>
            </nav>
            <div className={styles.headerMobileStoreBar}>
              <a href='/apple_store'><AppleStoreIcon /></a>
              <a href='/play_store'><PlayStoreIcon /></a>
            </div>
          </div>
        </header>
        <main className={styles.main}>
          <div className={styles.mainWrapper}>
            <div className={styles.pageTitleWrapper}>
              <h2 className={styles.pageTitle}>Pago de la suscripción</h2>
              <p className={styles.pageTitleMessage}>Un promotor te está invitando a ser parte de Blue, completa tu pago.</p>
            </div>
            <div className={styles.planInfo}>
              <p className={styles.planInfoTitle}>Información</p>
              <div>
                <div className={styles.planInfoAttribute}>
                  <p className={styles.planInfoAttributeLabel}>Nombre del plan</p>
                  <p className={styles.planInfoAttributeValue}>{plan.name}</p>
                </div>
                <div className={styles.planInfoAttribute}>
                  <p className={styles.planInfoAttributeLabel}>Valor</p>
                  <p className={styles.planInfoAttributeValue}>{currencyFormatter(plan.rate, 'cop')}</p>
                </div>
                <div className={styles.planInfoAttribute}>
                  <p className={styles.planInfoAttributeLabel}>Tipo de plan</p>
                  <p className={styles.planInfoAttributeValue}>{plan.planType}</p>
                </div>
              </div>
              <div className={styles.paymentInfo}>
                <div className={styles.paymentInfoAttribute}>
                  <p className={styles.paymentInfoAttributeLabel}>Total</p>
                  <p className={styles.paymentInfoAttributeValue}>{currencyFormatter(plan.rate, 'cop')}</p>
                </div>
                <Button label='Pagar' onClick={() => setDisplayForm(true)}/>
              </div>
            </div>
          </div>
        </main>
        {displayForm && (
          <Form
            onClose={() => setDisplayForm(false)}
            customer={customer}
            plan={plan}
            agreement={agreement}
            onAlert={handleAlert}
            onSubcriptionPaid={handleSubcriptionPaid}
          />)
        }
      </Fragment>
    )
  )
}

export default Subscribe;
