import React from 'react';

import clsx from 'clsx';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '../button';
import CloseIcon from '../icons/close';

import useStyles from './__styles__';

const Modal = ({ title, buttonSaveLabel, content, isSubmitting, onClose, onSubmit, disabled, actionsAlign='right'}) => {
  const styles = useStyles();

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: styles.paper
      }}
    >
      <DialogTitle classes={{root: styles.dialogTitle}}>
        <span>{title}</span>
        <div className={styles.dialogTitleSpacer}></div>
        <button onClick={onClose} className={styles.dialogTitleCloseButton}><CloseIcon size={16} /></button>
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent classes={{root: styles.dialogContent}}>
          {content}
        </DialogContent>
        <DialogActions
          classes={{
            root: clsx(styles.dialogActions, {[styles.actionsLeft]: actionsAlign === 'left'})
          }}
        >
          <Button
            type='submit'
            color="primary"
            label={buttonSaveLabel}
            loading={isSubmitting}
            disabled={disabled}
          />
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default Modal;
