import React, { useState, useContext, useCallback }from 'react';

//Import external libraries

//Import shared components, order A-Z
import Grid from '@material-ui/core/Grid';
import DateOutLinedField from '../shared/outlined_fields/date';
import DragAndDropFile from '../shared/fields/drag_and_drop_file';
import Modal from '../shared/modal';
import TextOutlinedField from '../shared/outlined_fields/text';
import SelectOutlinedField from '../shared/outlined_fields/select';

import { CreateAdvertisements, UpdateAdvertisements } from '../../controllers/advertisements';
import { GlobalDataContext } from '../../controllers/global_data';

const Form = ({ onClose, fetchData, advertisement={ advertisingCategory:{ id: '' }, image:{} }, labelButton }) => {
  const { globalData } = useContext(GlobalDataContext);
  const [errorField, setErrorField] = useState({});
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(
    {
      ...advertisement,
      advertisingCategoryId: advertisement.advertisingCategory ? advertisement.advertisingCategory.id : ''
    }
  );

  const handleSetFormData = useCallback(({target: { name, value }}) => {
    setFormData({
      ...formData,
      [name]: value
    });
  }, [formData, setFormData]);

  console.log(formData);

  const canContinue = useCallback(() => {
      return formData.name &&
             formData.description &&
             formData.startAt &&
             formData.endAt &&
             formData.link &&
             formData.advertisingCategoryId &&
             formData.image && Object.keys(formData.image).length
  }, [formData]);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    let success = true;
    let errors = {};

    if (!!advertisement.id){
      const resp = await UpdateAdvertisements(advertisement.id, formData);
      success = resp.success;
      errors = resp.errors;
    }else{
      const resp = await CreateAdvertisements(formData);
      success = resp.success;
      errors = resp.errors;
    }
    setLoading(false);

    if (!success){
      return setErrorField(errors);
    }

    fetchData();
    onClose();
  }

  return (
    <Modal
      title='Nueva Publicidad'
      buttonSaveLabel={labelButton}
      disabled= {!canContinue()}
      onSubmit={handleSubmit}
      isSubmitting={loading}
      onClose={onClose}
      content={(
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextOutlinedField
              error={Boolean(errorField.name)}
              errorMessage={errorField.name}
              label='Nombres'
              name='name'
              value={formData.name}
              onChange={handleSetFormData}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectOutlinedField
              error={Boolean(errorField.advertisingCategory)}
              errorMessage={errorField.advertisingCategory}
              label='Selecciona una categoría'
              name='advertisingCategoryId'
              options={
                globalData.advertisingCategory.map(advertisingCategory => (
                {
                  label: advertisingCategory.name, value: advertisingCategory.id
                }
              ))}
              value={formData.advertisingCategoryId}
              onChange={handleSetFormData}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <DateOutLinedField
              error={Boolean(errorField.startAt)}
              errorMessage={errorField.startAt}
              label='Fecha Inicio'
              name='startAt'
              value={formData.startAt}
              onChange={value => handleSetFormData({target: {name: 'startAt', value}})}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <DateOutLinedField
              error={Boolean(errorField.endAt)}
              errorMessage={errorField.endAt}
              label='Fecha Fin'
              name='endAt'
              minDate={formData.startAt}
              value={formData.endAt}
              onChange={value => handleSetFormData({target: {name: 'endAt', value}})}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextOutlinedField
              error={Boolean(errorField.description)}
              errorMessage={errorField.description}
              label='Descripción'
              name='description'
              value={formData.description}
              onChange={handleSetFormData}
              readOnly={false}
              multiline={true}
              rows={5}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextOutlinedField
              error={Boolean(errorField.link)}
              errorMessage={errorField.link}
              label='Link'
              name='link'
              value={formData.link}
              onChange={handleSetFormData}
              readOnly={false}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <DragAndDropFile
              error={Boolean(errorField.image)}
              errorMessage={errorField.image}
              label='Sube una imagen para tú Publicidad'
              data={formData.image && formData.image.hasOwnProperty('name') ? formData.image : formData.imageInfo}
              onChange={value => handleSetFormData({target: {name: 'image', value}})}
              typeAccept={'image/jpeg, image/png'}
            />
          </Grid>
        </Grid>
      )}
    />
  )
}

export default Form;
