import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  header: {
    display: 'flex',
    marginTop: 10
  },
  headerActions: {
    position: 'relative',
    top: -10,
    '& > button': {
      marginRight: 10
    },
    '& > button:last-child': {
      marginRight: 0
    }
  },
  professionalInformation: {
    marginBottom: 22,
    flex: 1
  },
  professionalName: {
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
    opacity: 0.9,
    margin: [[0, 0, 8]]
  },
  professionalMainActivity: {
    color: '#4F4F4F',
    fontSize: 16,
    margin: 0
  }
}));

export default styles;
