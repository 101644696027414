import HttpRequest from '../../services/http_request';
import normalize from 'json-api-normalize';

const API_PATH = '/subscriptions'

export const VerifySubscriptionToken = async token => {
  try{
    const { data } = await HttpRequest({
      method: 'GET',
      url: `${API_PATH}/verify_token`,
      params: {
        token
      }
    });

    return {
      success: true,
      resp: {
        token,
        ...normalizer(data)
      }
    }
  } catch (error){
    console.log(error)
    return {
      success: false,
      errors: error?.response?.data?.errors
    }
  }
}

export const PaySubscription = async (agreementId, params) => {
  try {
    const resp = await HttpRequest({
      method: 'POST',
      url: `/agreements/${agreementId}/pay`,
      data: {
        pay: {
          ...params
        }
      }
    });

    return {
      success: true,
      item: resp
    }
  } catch(error) {
    return {
      success: false,
      errors: error?.response?.data?.errors
    }
  }
}

export const UseSubcription = async token => {
  try{
    const resp = await HttpRequest({
      method: 'POST',
      url: `${API_PATH}/use`,
      data: {
        token
      }
    });

    return{
      success: true,
      resp: resp
    }
  }catch(errors){
    return{
      success: false,
      resp: errors
    }
  }
}

const normalizer = data => normalize(data).get([
  'id',
  'createdAt',
  'paidAt',
  'status',
  'customer.id',
  'customer.firstName',
  'customer.lastName',
  'customer.email',
  'agreementtable.id',
  'agreementtable.name',
  'agreementtable.rate',
  'agreementtable.time',
  'agreementtable.planType'
]);
