import normalize from 'json-api-normalize';
import {PAGINATION} from '../../lib/enums';
import Qs from 'qs';

import HttpRequest from '../../services/http_request';

export const ListCustomers = async (page=1, search={}, sort, items=PAGINATION.ROWS_PER_PAGE) => {
  return new Promise( async (resolve, reject) => {
    try {
      const queryParams = Qs.stringify(
        {
          items,
          page,
          q: search,
          sort
        }, {
          encode: false
        }
      );

      const { data } = await HttpRequest({
        method: 'GET',
        url: `/admin/customers?${queryParams}`,
      });

      resolve({
        items: normalizer(data.customers),
        pagination: data.metadata
      });
    } catch (error) {
      reject({
        items: [],
        pagination: null
      });
    }
  });
}

export const CreateCustomer = async (params) => {
  try{
    const resp = await HttpRequest({
      method: 'POST',
      url: '/admin/customers',
      data: {
        customer: {
          ...params
        }
      }
    });

    return {
      success: true,
      resp: resp
    }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
}

export const ActivateCustomer = async (customerId, active) => {
  try{
    const resp = await HttpRequest({
      method: 'POST',
      url: `/admin/customers/${customerId}/activate`,
      data: {
        customer: {
          active
        }
      }
    });

    return {
      success: true,
      resp: resp
    }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
}

export const InvitationPasswordCustomer = async (params) => {
  try {
    const resp = await HttpRequest({
      method: 'PUT',
      url: 'customer/invitation',
      data: {
        customer: {
          ...params
        },
      }
    });

    return {
      success: true
    };
  } catch (error) {
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
}

const normalizer = data => normalize(data).get([
  'id',
  'firstName',
  'lastName',
  'phoneNumber',
  'residenceCity',
  'residenceCountry',
  'active',
  'activeAgreement.id',
  'activeAgreement.status',
  'activeAgreement.agreementtable.id',
  'activeAgreement.agreementtable.name',
  'activeAgreement.agreementtable.rate',
  'activeAgreement.agreementtable.time',
  'activeAgreement.agreementtable.planType',
  'activeAgreement.paidAt',
  'initializedAgreement.id',
  'initializedAgreement.status',
  'initializedAgreement.agreementtable.id',
  'initializedAgreement.agreementtable.name',
  'initializedAgreement.agreementtable.rate',
  'initializedAgreement.agreementtable.time',
  'initializedAgreement.agreementtable.planType',
  'customerAdditionalInfo.info',
]);
