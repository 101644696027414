import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';

import withLoading from '../with_loading';
import Alert from '../shared/alert';
import AppleStoreIcon from '../shared/icons/apple_store';
import BookIcon from '../shared/icons/book';
import Button from '../shared/button';
import IceCreamIcon from '../shared/icons/ice_cream';
import LogoIcon from '../shared/icons/logo';
import PlayStoreIcon from '../shared/icons/play_store';
import UserIcon from '../shared/icons/user';

import useStyles from './__styles__';

import { STEPS } from './constants';
import InformationStep from './steps/information';
import DocumentsStep from './steps/documents';
import AdditionalStep from './steps/additional';
import CompletedStep from './steps/completed';

import { CreateApplicant, VerifyIdentificationApplicant } from '../../controllers/applicants';

import { phoneNumberValidator, emailValidator } from '../../lib/validators';

const Register = () => {
  const styles = useStyles();
  const [currentStep, setCurrentStep] = useState(STEPS.INFORMATION);
  const [errorField, setErrorField] = useState({});
  const [saving, setSaving] = useState(false);
  const [alert, setAlert] = useState({});
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    identificationType: '',
    identificationNumber: '',
    idCard: '',
    service1: '',
    service2: '',
    rateService1: '',
    rateService2: '',
    residenceCountry: '',
    residenceCity: '',
    birthdate: null,
    gender: '',
    profession: '',
    professionGraduationYear: null,
    specialty: '',
    specialtyGraduationYear: null,
    availableTime: [],
    profilePicture: null,
    certificateOfDegree: null,
    professionalCard: null,
    identificationCard: null,
    howDidYouKnowAboutUs: '',
    description: '',
    acceptedTermsAndConditions: false,
    rate: ''
  });
  const [validStep, setValidStep] = useState(false);

  const handleSetFormData = useCallback(({target: { name, value }}) => {
    setFormData({
      ...formData,
      [name]: value
    });
  }, [formData, setFormData]);

  const handleContinue = async (evt) =>{
    evt.preventDefault();

   if (currentStep === STEPS.INFORMATION){
     setSaving(true);
     const { success, errors } = await VerifyIdentificationApplicant(formData);
     setSaving(false);

     if(!success){
      setAlert({message: 'Ya existe un profesional con tus datos', type: 'error'});
      setTimeout(() => setAlert({}), 5000);
      setErrorField(errors);
    }else{
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
      setErrorField({});
    }
   }else{

      if (currentStep < STEPS.COMPLETED) {
        const nextStep = currentStep + 1;
        setCurrentStep(nextStep);
        setErrorField({});
      }
   }
}

  const canContinue = useCallback((step) => {
    const stepValidation = {
      [STEPS.INFORMATION]: () => {
        const validStep = formData.firstName &&
               formData.lastName &&
               formData.email &&
               emailValidator(formData.email) &&
               formData.phoneNumber &&
               phoneNumberValidator(formData.phoneNumber) &&
               formData.identificationType &&
               formData.identificationNumber &&
               formData.idCard &&
               formData.residenceCountry &&
               formData.residenceCity &&
               formData.birthdate &&
               formData.gender &&
               formData.profession &&
               formData.professionGraduationYear &&
               formData.availableTime.length &&
               formData.service1 &&
               formData.rateService1

        return formData.service2 ? validStep && formData.service2 && formData.rateService2 : validStep
      },
      [STEPS.DOCUMENTS]: () => {
        return formData.profilePicture &&
               formData.certificateOfDegree &&
               formData.professionalCard &&
               formData.identificationCard
      },
      [STEPS.ADDITIONAL]: () => {
        return formData.acceptedTermsAndConditions
      }
    }
      return validStep && stepValidation[step]();
  }, [formData, validStep]);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setSaving(true);
    const { success, errors } = await CreateApplicant(formData);
    setSaving(false);

    if(success){
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
      setErrorField({});
    }else{
      setErrorField(errors);
      setCurrentStep(STEPS.INFORMATION);
    }
  }

  return (
    <React.Fragment>
      <header className={styles.header}>
        <Alert type={alert.type} message={alert.message} display={!!alert.type}/>
        <div className={styles.headerWrapper}>
          <h1 className={styles.headerTitle}>
            <span>Se parte de nuestros profesionales Blue, ¡Registrate!</span>
            <LogoIcon />
          </h1>
          <nav className={styles.headerNavigationBar}>
            <a href='/features'>Features</a>
            <a href='/ads'>Ads</a>
            <a href='/contact'>Contact</a>
          </nav>
          <div className={styles.headerMobileStoreBar}>
            <a href='/apple_store'><AppleStoreIcon /></a>
            <a href='/play_store'><PlayStoreIcon /></a>
          </div>
        </div>
      </header>
      <main className={styles.main}>
        <div className={styles.mainWrapper}>
          {
            STEPS.COMPLETED !== currentStep
            &&
            <React.Fragment>
              <div className={styles.pageTitleWrapper}>
                <h2 className={styles.pageTitle}>¡Le damos la bienvenida a blue!</h2>
                <p className={styles.pageTitleMessage}>Complete el siguiente formulario para ingresar a nuestra plataforma.</p>
              </div>
              <div className={styles.stepList}>
                <div
                  className={
                    clsx(styles.stepListItem, {
                      [styles.stepListItemActive]: currentStep === STEPS.INFORMATION,
                      [styles.stepListItemCompleted]: currentStep > STEPS.INFORMATION
                    })
                  }>
                  <p>
                    <UserIcon className={'stepListItemIcon'}/>
                    Información
                  </p>
                </div>
                <div
                  className={
                    clsx(styles.stepListItem, {
                      [styles.stepListItemActive]: currentStep === STEPS.DOCUMENTS,
                      [styles.stepListItemCompleted]: currentStep > STEPS.DOCUMENTS
                    })
                  }>
                  <p>
                    <BookIcon className={'stepListItemIcon'}/>
                    Documentos
                  </p>
                </div>
                <div
                  className={
                    clsx(styles.stepListItem, {
                      [styles.stepListItemActive]: currentStep === STEPS.ADDITIONAL,
                      [styles.stepListItemCompleted]: currentStep > STEPS.ADDITIONAL
                    })
                  }>
                  <p>
                    <IceCreamIcon className={'stepListItemIcon'}/>
                    Otros
                  </p>
                </div>
              </div>
            </React.Fragment>
          }
          <form onSubmit={handleSubmit}>
            {
              currentStep === STEPS.INFORMATION
              &&
              <InformationStep
                onChangeData={handleSetFormData}
                data={formData}
                errors={errorField}
                setErrors={setErrorField}
                onValidateStep={setValidStep}
              />
            }
            {
              currentStep === STEPS.DOCUMENTS
              &&
              <DocumentsStep onBack={setCurrentStep} onChangeData={handleSetFormData} data={formData}/>
            }
            {
              currentStep === STEPS.ADDITIONAL
              &&
              <AdditionalStep onBack={setCurrentStep} onChangeData={handleSetFormData} data={formData} />
            }
            {
              currentStep === STEPS.COMPLETED
              &&
              <CompletedStep />
            }
            {
              STEPS.COMPLETED !== currentStep
              &&
              <div className={styles.formActions}>
                <Button
                  label={currentStep < STEPS.ADDITIONAL ? 'Continuar' : 'Finalizar'}
                  onClick={currentStep < STEPS.ADDITIONAL ? handleContinue : null}
                  type={currentStep < STEPS.ADDITIONAL ? 'button' : 'submit'}
                  disabled={!canContinue(currentStep)}
                  loading={saving}
                />
              </div>
            }
          </form>
        </div>
      </main>
    </React.Fragment>
  )
}

export default withLoading(Register);
