import React from 'react';

//Import external libraries

//Import utils or services

//Import shared components, order A-Z
import Button from '../button';

//Import controllers

//Import styles
import useStyles from './__styles__';

//Import local components

//Import constants or services

const EmptyStatePage = ({ title, message, createButtonLabel, onCreate }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        <p className={styles.message}>{message}</p>
        <Button className={styles.button} label={createButtonLabel} onClick={onCreate}/>
      </div>
    </div>
  )
}

export default EmptyStatePage;
