import HttpRequest from '../../../services/http_request';
import Qs from 'qs';

export const ListAnalityCustomers = async (year=2019, id) => {
  return new Promise( async (resolve, reject) => {
    try {
      const queryParams = Qs.stringify(
        {
          q: year,
        }, {
          encode: false
        }
      );

      const { data } = await HttpRequest({
        method: 'GET',
        url: `/admin/customers/${id}/anality?${queryParams}`,
      });

      resolve({
        items: data
      });
    } catch (error) {
      reject({
        items: []
      });
    }
  });
}

export const ListYearsCustomer = async id => {
  return new Promise( async (resolve, reject) => {
    try {
      const { data } = await HttpRequest({
        method: 'GET',
        url: `/admin/customers/${id}/years`,
      });

      resolve({
        items: data
      });
    } catch (error) {
      reject({
        items: []
      });
    }
  });
}

export const CountCustomerLastMonth = async id => {
  return new Promise( async (resolve, reject) => {
    try {
      const { data } = await HttpRequest({
        method: 'GET',
        url: `/admin/customers/${id}/last_month`,
      });

      resolve({
        items: data
      });
    } catch (error) {
      reject({
        items: []
      });
    }
  });
}
