import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 1.5c.793 0 1.48.314 2.032.87l3.1 3.1c.557.557.868 1.24.868 2.03 0 .786-.309 1.6-.867 2.16L10.335 20.95c-.785.906-1.898 1.463-3.2 1.551H1.5v-1.5l.005-4.255c.095-1.183.646-2.284 1.468-3.007L14.34 2.372C14.9 1.81 15.713 1.5 16.5 1.5zM5.027 15.924c-.308.273-.499.654-.527.944v2.636l2.529.002c.402-.028.776-.215 1.112-.598l6.573-6.573-3.048-3.047-6.639 6.636zm8.761-8.757l3.047 3.047 2.65-2.649-3.048-3.047-2.649 2.649z"
        fill="#8F8F8F"
      />
    </svg>
  )
}

export default SvgComponent
