import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  progressSection: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 14,
  },
  sectionTitle: {
    color: '#929CB0',
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    margin: [[0, 0, 6]],
    textTransform: 'uppercase'
  },
  sectionSubtitle: {
    color: '#929CB0',
    fontSize: 11,
    lineHeight: '16px',
    letterSpacing: 0.4,
    textTransform: 'uppercase',
    margin: [[8, 0, 0]]
  },
  sectionValue: {
    color: '#000000',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '16px',
    letterSpacing: 0.4,
    margin: 0,
    textTransform: 'uppercase'
  },
  progressBarRoot: {
    background: '#E7EAF4',
    borderRadius: 4,
    height: 12,
    position: 'relative',
    width: '100%'
  },
  progressBarValue: {
    background: '#3CD3AE',
    borderRadius: 4,
    height: 12,
    position: 'absolute',
    top: 0,
    left: 0
  }
}));

export default styles;
