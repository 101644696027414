import React from 'react';

import clsx from 'clsx';

import useStyles from './__styles__';

const TableWrapper = ({totalItems, title, filters, table, actions=null}) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={clsx(styles.header, { [styles.headerWithoutFilters]: !!!filters })}>
        <p className={styles.title}>
          <span className={styles.titleTotalItems}>{totalItems}</span> {title}
        </p>
        {actions && <div className={styles.headerActions}>{actions}</div>}
      </div>
      {
        !!filters
        &&
        <div className={styles.filters}>
          {filters}
        </div>
      }
      <div className={styles.tableWrapper}>
        {table}
      </div>
    </div>
  )
}

export default TableWrapper;
