import React, { useState, memo } from 'react';
import PropTypes from 'prop-types'
import MUIFormGroup from '@material-ui/core/FormGroup';
import MUIInput from '@material-ui/core/Input';
import MUIInputLabel from '@material-ui/core/InputLabel';
import MUIFormHelperText from '@material-ui/core/FormHelperText';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import _omit from 'lodash/omit';
import clsx from 'clsx';

import useStyles from './__styles__';

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 0, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

const CurrencyInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })

  return <MaskedInput mask={currencyMask} {...inputProps} />
}

const CurrencyField = memo(({ label, type = 'text', value, onChange, mask, multiline = false, disabled, error, errorMessage, endAdornment = null, classes = {}, maskOptions, helperText, ...props }) => {
  const styles = useStyles();
  const secureProps = _omit(props, ['InputProps', 'helperText']);

  return (
    <MUIFormGroup>
      {
        label
        &&
        <MUIInputLabel
          classes={{
            root: styles.label
          }}
          error={error}
        >
          {label}
          {props.required && <span className={styles.required}>*</span>}
        </MUIInputLabel>
      }
      <MUIInput
        classes={{
          root: clsx(styles.baseRoot, classes.root),
          focused: styles.focused,
          input: styles.input,
        }}
        disableUnderline={true}
        value={value}
        fullWidth={true}
        onChange={onChange}
        type="text"
        inputComponent={CurrencyInput}
        startAdornment={
          <InputAdornment
            position="start"
          >
            <AttachMoneyIcon fontSize="small" className={styles.attachMoneyIcon} />
          </InputAdornment>
        }
        disabled={disabled}
        error={Boolean(error)}
        {...secureProps}
      />
      {errorMessage && <MUIFormHelperText error>{errorMessage}</MUIFormHelperText>}
      {helperText && <MUIFormHelperText>{helperText}</MUIFormHelperText>}
    </MUIFormGroup>
  );
});

CurrencyField.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {},
}

CurrencyField.propTypes = {
  inputmode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.boolean,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.boolean,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.boolean,
    allowNegative: PropTypes.boolean,
    allowLeadingZeroes: PropTypes.boolean,
    integerLimit: PropTypes.number,
  }),
}

export default CurrencyField;
