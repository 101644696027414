import React, { useState, useCallback, useEffect } from 'react';

import _debounce from 'lodash.debounce';
import _snakeCase from 'lodash.snakecase';

import { history } from '../../config/history';

import Alert from '../shared/alert';
import Loading from '../shared/loading';
import TextField from '../shared/fields/text';
import Table from '../shared/table';
import TableWrapper from '../shared/table_wrapper';

import { ListProfessionals } from '../../controllers/professionals';

import { columns } from './data_table_schema';

const Professionals = () => {
  const [paginationInfo, setPaginationInfo] = useState();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({});
  const [order] = useState('desc');
  const [orderBy] = useState('firstName');
  const [errors] = useState({});

  const fetchData = useCallback (async (page = 1) => {
    setLoading(true);
    const { items, pagination } = await ListProfessionals(page, {...search}, `${_snakeCase(orderBy)} ${order}`);
    setItems(items)
    setPaginationInfo(pagination);
    setLoading(false);
  },[search, order, orderBy]);

  useEffect(() => {
    fetchData();
  }, [search, order, orderBy, fetchData]);

  const handleEditProfessional = async rowInfo => {
    history.push(`/professionals/${rowInfo.id}`);
  }

  const handleSearchSpeciality = _debounce(value => {
    setSearch({
      ...search,
      professional_additional_info_specialty_cont: value
    });
  }, 250);

  const handleSearchProfessional = _debounce(value => {
    setSearch({
      ...search,
      professional_additional_info_profession_cont: value
    });
  }, 250);

  return (
    <div>
      <Alert type={errors.type} message={errors.applicant} display={errors.applicant}/>
      <TableWrapper
        totalItems={paginationInfo ? `+${paginationInfo.count}` : '+0'}
        title='Nuevos inscritos'
        filters={
          <React.Fragment>
            <TextField placeholder='Profesión' onChange={({target: { value }}) => handleSearchProfessional(value)}/>
            <TextField placeholder='Especialidad' onChange={({target: { value }}) => handleSearchSpeciality(value)}/>
          </React.Fragment>
        }
        table={
          loading
          ?
          <Loading/>
          :
          <Table
            columns={columns}
            data={items}
            loading={false}
            fetchData={fetchData}
            paginationInfo={paginationInfo}
            onEdit={(rowInfo) => handleEditProfessional(rowInfo)}
          />
        }
      />
    </div>
  )
}

export default Professionals;
