import React from 'react';
import { STATUS_LABEL } from '../../config/mappings';

export const columns = [{
  Header: 'Nombres',
  accessor: 'firstName'
}, {
  Header: 'Apellidos',
  accessor: 'lastName',
}, {
  Header: 'Teléfono',
  accessor: 'phoneNumber',
}, {
  Header: 'Suscripción',
  accessor: '',
  Cell: ({ row: { original: { activeAgreement, initializedAgreement } } }) => {
    return (activeAgreement || initializedAgreement).agreementtable.name;
  }
},
{
  Header: 'Departamento',
  accessor: '',
  Cell: ({ row: { original: { customerAdditionalInfo: { info } } } }) => {
    return info.addressState;
  }
},
{
  Header: 'Ciudad',
  accessor: 'residenceCity',
},
{
  Header: 'Estado',
  accessor: '',
  Cell: ({ row: { original: { initializedAgreement, activeAgreement } } }) => {
    const status = (activeAgreement || initializedAgreement)?.status;
    return (
      <span className={`suscription-paid suscription-paid--${status}`}>
        {STATUS_LABEL[status]}
      </span>
    )
  }
}];
