import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  root: {

  },
  title: {
    color: '#929CB0',
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    margin: [[0, 0, 6]],
    textTransform: 'uppercase'
  },
  assignedItems: {
    display: 'flex'
  },
  assignedItem: {
    background: '#4772FA12',
    border: 0,
    borderRadius: 16,
    color: '#4772FA',
    fontFamily: 'Roboto',
    fontSize: 14,
    letterSpacing: '0.25px',
    height: 32,
    lineHeight: '32px',
    margin: [[0, 6, 0, 0]],
    padding: [[0, 12]],
    textAlign: 'center',
    '&:last-child': {
      margin: 0
    }
  },
  assignedItemButton: {
    cursor: 'pointer',
    outline: 'none'
  },
  additionalItemsMenuList: {
    padding: 6,
    '& > li': {
      display: 'block',
      marginBottom: 6
    }
  }
}));

export default styles;
