import creditCardType from 'credit-card-type';
import luhn from 'luhn';
import supportedCards from '../../enums/supported_credit_cards';
import moment from 'moment';

const FRANCHISES = {
  [creditCardType.types.VISA]: 'VISA',
  [creditCardType.types.DINERS_CLUB]: 'DINERS',
  [creditCardType.types.AMERICAN_EXPRESS]: 'AMEX',
  [creditCardType.types.MASTERCARD]: 'MASTERCARD',
  [creditCardType.types.MAESTRO]: 'MASTERCARD'
};

const lengthMatches = (number, lengths) => {
  const match = lengths.find(length => number.length === length);
  return Boolean(match);
};

export const expirationDateValidator = expirationDate => {
  const [month, year] = expirationDate.split('/');
  let isValid = true;
  if (parseInt(month, 10) > 12 || parseInt(month, 10) <= 0) {
    isValid = false;
  }
  if (parseInt(year, 10) < moment().year()) {
    isValid = false;
  }
  if (parseInt(year, 10) === moment().year() && parseInt(month, 10) <= moment().month()) {
    isValid = false;
  }

  return isValid;
}

export const cvvValidator = (number, cvv) => {
  if (!number) {
    return true;
  }
  const card = creditCardType(number);
  if (!card[0] || card[0].code.size !== cvv.length) {
    return false
  }
  return true;
}

export const isCardSupported = number => {
  const card = creditCardType(number);
  if (!card || card.length === 0) {
    return false;
  }

  if (!lengthMatches(number.replace(/-/g, ''), card[0].lengths)) {
    return false;
  }

  if (!luhn.validate(number.replace(/-/g, ''))) {
    return false;
  }

  return supportedCards.includes(card[0].type);
};

export const getCardFranchise = number => {
  if (!number) {
    return null;
  }

  const card = creditCardType(number);

  if (!card.length) {
    return null;
  }
  return {
    code: FRANCHISES[card[0].type],
    cvvLength: card[0].code.size
  };
};
