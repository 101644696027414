import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {},
  selectRoot: {
    borderRadius: 8,
    '&:hover': {
      borderColor: 'rgba(38,38,38,1)'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(38,38,38,0.15)'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(38,38,38)',
      borderWidth: 1
    }
  },
  baseRoot: {
    borderRadius: 8,
    height: 48,
    lineHeight: '48px',
    padding: [[0, 10]],
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  input: {
    borderRadius: 4,
    fontFamily: 'Poppins',
    fontSize: 14,
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    },
  },
  label: {
    color: '#262626',
    fontSize: 16,
    fontWeight: 500,
    margin: [[0, 0, 4]]
  },
  focused: {
    border: [[1, 'solid', 'rgb(38,38,38)']],
  },
  required: {
    color: '#EB5757',
    marginLeft: 4
  },
  icon: {
    color: '#3CD3AE'
  },
  displayEmptyOption: {
    minHeight: 24
  }
}));
