import React, { useState, useCallback, useEffect, useMemo } from 'react';

//Import external libraries

//Import utils or services
import { pluralizeHelper, currencyFormatter } from '../../lib/utils';

//Import shared components, order A-Z
import Button from '../shared/button';
import EmptyStatePage from '../shared/empty_state_page';
import Loading from '../shared/loading';
import ItemCard from '../shared/item_card';

//Import controllers
import { ListPlans, DeactivatePlan, ActivatePlan } from '../../controllers/plans';

//Import styles
import useStyles from './__styles__';

//Import local components
import Form from './form';

//Import constants or services
import { PLAN_TYPES } from '../../lib/enums';

const MarketingPlans = () => {
  const [items, setItems] = useState([]);
  const [label, setlabel] =useState();
  const [loading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [plan, setPlan] = useState({});
  const styles = useStyles();

  const fetchData = useCallback( async () => {
    setLoading(true);
    const { items } = await ListPlans();
    setItems(items);
    setLoading(false);
  }, [setItems]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const createPlan = () => {
    setPlan({});
    setlabel('Crear Plan');
    setOpenForm(true);
  }

  const editPlan = (rowInfo) => {
    setPlan({...rowInfo});
    setlabel('Guardar');
    setOpenForm(true);
  }

  const deactivatePlan = async (rowInfo) => {
     await DeactivatePlan(rowInfo.id);
     fetchData();
  }

  const activatePlan = async (rowInfo) => {
     await ActivatePlan(rowInfo.id);
     fetchData();
  }

  const activePlans = useCallback(() => {
    return items.filter(value => value.active === true);
  }, [items]);

  const planName = useCallback((planType) => {
    return PLAN_TYPES.find(plan => plan.value === planType)?.label;
  }, []);

  return (
    <div>
      {
        loading
        &&
        <Loading/>
      }
      {
        !loading
        &&
        Object.keys(items).length > 0
        &&
        <React.Fragment>
          <div className={styles.header}>
            <div className={styles.pageInformation}>
              <p className={styles.pageTitle}>Planes de Network Marketing</p>
              <p className={styles.pageSubtitle}>{`${activePlans().length} ${pluralizeHelper(activePlans(), 'Plan', 'Planes')} ${pluralizeHelper(activePlans(), 'activo', 'activos')}`}</p>
            </div>
            <div className={styles.headerActions}>
              <Button
                label='Nuevo Plan'
                size='small'
                onClick={createPlan}
              />
            </div>
          </div>
          <div className={styles.content}>
            {items.map((row) => {
              return <ItemCard
                name= {row.name}
                row={row}
                attributes={[
                  {label: 'Valor', value: currencyFormatter(row.rate, 'cop')},
                  {label: 'Tiempo', value: `${row.time} Meses`},
                  {label: 'Tipo ', value: planName(row.planType)}
                ]}
                active={row.active}
                onEdit={editPlan}
                twoColumnsContent={true}
                {
                  ...row.active ? { onDeactivate: deactivatePlan } : { onActivate: activatePlan }
                }
              />
            })}
          </div>
        </React.Fragment>
      }
      {
        !loading
        &&
        Object.keys(items).length === 0
        &&
        <div>
          <EmptyStatePage
            title='No tienes Planes de Network Marketing'
            message='Crea tu primera Plan'
            createButtonLabel='Nuevo Plan'
            onCreate={createPlan}
          />
        </div>
      }
      {
        openForm
        &&
        <Form
          onClose={() => setOpenForm(false)}
          fetchData={fetchData}
          plan={plan}
          labelButton={label}
          plans={items}
        />
      }
    </div>
  )
}

export default MarketingPlans;
