import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import Grow from '@material-ui/core/Grow';
import cslx from 'clsx';

import useStyles from './__styles__';

const Alert = ({message, type, display=false, icon=null}) => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <Grow
        in={display}
        style={{ transformOrigin: '0 0 0' }}
        {...(display ? { timeout: 1000 } : {})}
      >
        <MuiAlert
          severity={type}
          classes={{
            root: styles.root,
            icon: cslx({[styles.noIcon]: !icon}),
            message: styles.message,
            standardError: styles.error,
            standardSuccess: styles.success
          }}
          icon={icon}
        >
          {message}
        </MuiAlert>
      </Grow>
    </div>
  )
}

export default Alert;
