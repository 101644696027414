import React, { useState, useCallback, useEffect } from 'react';

//Import external libraries

//Import utils or services

//Import shared components, order A-Z
import Button from '../shared/button';
import EmptyStatePage from '../shared/empty_state_page';
import Loading from '../shared/loading';
import ItemCard from '../shared/item_card';

//Import controllers
import { ListMarketingLevels, DeleteMarketingLevel } from '../../controllers/marketing_levels';

//Import styles
import useStyles from './__styles__';

//Import local components
import Form from './form';

//Import constants or services

const MarketingLevels = () => {
  const [items, setItems] = useState([]);
  const [label, setlabel] =useState();
  const [openForm, setOpenForm] = useState(false);
  const [marketingLevels, setMarketingLevels] = useState({});
  const [loading, setLoading] = useState(true);
  const styles = useStyles();

  const fetchData = useCallback( async () => {
    setLoading(true);
    const { items } = await ListMarketingLevels();
    setItems(items);
    setLoading(false);
  }, [setItems]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const createMarketingLevel = () => {
    setMarketingLevels({});
    setlabel('Crear');
    setOpenForm(true);
  }

  const editMarketingLevel = (rowInfo) => {
    setMarketingLevels({...rowInfo});
    setlabel('Guardar');
    setOpenForm(true);
  }

  const deleteMarketingLevel = async (rowInfo) => {
    await DeleteMarketingLevel(rowInfo.id);
    fetchData();
  }

  return (
    <div>
      {
        loading
        &&
        <Loading/>
      }
      {
        !loading
        &&
        Object.keys(items).length > 0
        &&
        <React.Fragment>
          <div className={styles.header}>
            <div className={styles.pageInformation}>
              <p className={styles.pageTitle}>Niveles</p>
              <p className={styles.pageSubtitle}>{`${Object.keys(items).length} Niveles`}</p>
            </div>
            <div className={styles.headerActions}>
              <Button
                label='Nuevo Nivel'
                size='small'
                onClick={createMarketingLevel}
              />
            </div>
          </div>
          <div className={styles.content}>
            {items.map((row) => {
              return <ItemCard
                name= {row.name}
                row={row}
                attributes={[
                  {label: 'Rango de Puntos', value: `${row.startPoints} - ${row.endPoints} Puntos`},
                  {label: 'Pto por Suscripción', value: `${row.pointsPerSuscription} Puntos`}
                ]}
                onEdit={editMarketingLevel}
                onDelete={deleteMarketingLevel}
              />
            })}
          </div>
        </React.Fragment>
      }
      {
        !loading
        &&
        Object.keys(items).length === 0
        &&
        <div>
          <EmptyStatePage
            title='No tienes niveles aún'
            createButtonLabel='Crear Nivel'
            onCreate={createMarketingLevel}
          />
        </div>
      }
      {
        openForm
        &&
        <Form
          onClose={() => setOpenForm(false)}
          fetchData={fetchData}
          marketingLevels={marketingLevels}
          labelButton={label}
        />
      }
    </div>
  )
}

export default MarketingLevels;
