import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  headerTitle: {
    display: 'block',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10
  },
  headerPrice: {
    display: 'block',
    fontSize: [22, '!important'],
    fontWeight: ['bold', '!important'],
    letterSpacing: [0.4, '!important'],
  },
  headerPriceCurrency: {
    fontSize: 14,
    fontWeight: ['normal', '!important'],
    letterSpacing: [0.4, '!important']
  },
  step1: {
    marginTop: 24
  },
  step: {
    color: '#929CB0',
    fontSize: 11,
    fontWeight: 'bold',
    letterSpacing: 0.4,
    margin: 0,
    textTransform: 'uppercase'
  },
  stepTitle: {
    color: '#000000',
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 0.4,
    margin: [[0, 0, 16]]
  },
  paymentType: {
    alignItems: 'center',
    border: [[1, 'solid', '#26262615']],
    borderRadius: 8,
    cursor: 'pointer',
    display: 'flex',
    marginBottom: 8,
    padding: [[16, 24]],
    '&:last-child': {
      marginBottom: 0
    }
  },
  paymentTypeActive: {
    border: [[2, 'solid', theme.palette.primary.main]],
    '& $paymentTypeName': {
      color: '#262626',
    }
  },
  paymentTypeIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: 38
  },
  paymentTypeName: {
    color: '#26262650',
    fontSize: 16,
    margin: [[0, 0, 0, 16]]
  }
}));
