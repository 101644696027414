import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={18} height={18} viewBox="0 0 24 24" fill="none" {...props}>
      <path fill="#fff" fillOpacity={0.01} d="M0 0h24v24H0z" />
      <path
        clipRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
        stroke="#FEC703"
        strokeWidth={2}
      />
      <path d="M12 6v6h5" stroke="#FEC703" strokeWidth={2} />
    </svg>
  )
}

export default SvgComponent
