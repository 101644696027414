import React, { useState, useEffect, useCallback } from 'react';

import { useParams } from 'react-router-dom';
import { history } from '../../config/history';

import Alert from '../shared/alert';
import Button from '../shared/button';
import ProfessionalDetailInformation from '../professional/detail_information';
import { ShowApplicant, AcceptApplicant, RejectApplicant } from '../../controllers/applicants';

import useStyles from './__styles__';

const ApplicantDetail = () => {
  const styles = useStyles();
  const [data, setData] = useState({});
  const [id] = useState(useParams().id);
  const [errors, setErrors] = useState({});

  const fetchOne = useCallback( async () => {
    if (id){
      const { item } = await ShowApplicant(id);
      setData(item);
    }
  }, [setData, id]);

  useEffect(() => {
    fetchOne();
  }, [fetchOne]);

  const handleAcceptApplicant = useCallback( async () => {
    const { success, resp } = await AcceptApplicant(id);

    if(!success){
      setErrors({applicant: 'El candidato ya ha sido aceptado'});
      setTimeout(() => setErrors({}), 5000);
    }else{
      history.push({pathname:`/professionals/${resp.id}`, alert: { message: 'El profesional fue aceptado exitosamente', type: 'success' }});
    }
  },[id]);

  const handleRejectApplicant = useCallback( async () => {
    const { success } = await RejectApplicant(id);

    if(!success){
       setErrors({applicant: 'El candidato ya ha sido rechazado'});
       setTimeout(() => setErrors({}), 5000);
    }
  }, [id]);

  return (
    Object.keys(data).length
    ?
    <React.Fragment>
      <div className={styles.header}>
        <Alert type={'error'} message={errors.applicant} display={errors.applicant}/>
        <div className={styles.professionalInformation}>
          <p className={styles.professionalName}>{ `${data.firstName} ${data.lastName}` }</p>
          <p className={styles.professionalMainActivity}>{data.specialty}</p>
        </div>
        <div className={styles.headerActions}>
          <Button
            label='Rechazar'
            variant='outlined'
            size='small'
            onClick={handleRejectApplicant}
          />
          <Button
            label='Aceptar'
            size='small'
            onClick={handleAcceptApplicant}
          />
        </div>
      </div>
      <ProfessionalDetailInformation data={data}/>
    </React.Fragment>
    :
    <p>Loading</p>
  )
}

export default ApplicantDetail;
