import React, { useCallback, useEffect, useState } from 'react';

//Import external libraries
import _snakeCase from 'lodash.snakecase';

//Import utils or services

//Import shared components, order A-Z
import Button from '../shared/button';
import EmptyStatePage from '../shared/empty_state_page';
import Loading from '../shared/loading';
import Table from '../shared/table';
import TableWrapper from '../shared/table_wrapper';


//Import controllers
import { ListMarketingAgents, ShowMarketingAgent } from '../../controllers/marketing_agents';

//Import styles
import useStyles from './__styles__';

//Import local components
import Form from './form';
import { columns } from './data_table_schema';

//Import constants or services
import AddIcon from '@material-ui/icons/Add';
import { history } from '../../config/history';

const MarketingAgents = () => {

  const [paginationInfo, setPaginationInfo] = useState();
  const [items, setItems] = useState([]);
  const [label, setLabel] = useState();
  const [search] = useState({});
  const [order] = useState('desc');
  const [orderBy] = useState('firstName');
  const [openForm, setOpenForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [marketingAgent, setMarketingAgent] = useState({});
  const [plans, setPlans] = useState([]);
  const styles = useStyles();

  const fetchData = useCallback( async (page = 1) => {
    setLoading(true);
    const { items, pagination } = await ListMarketingAgents(page, {...search}, `${_snakeCase(orderBy)} ${order}`);
    setItems(items)
    setPaginationInfo(pagination);
    setLoading(false);

  }, [search, order, orderBy]);

  useEffect(() => {
    fetchData();
  }, [fetchData])

  const clickOnRow = rowInfo => {
    console.log('Click row');
    history.push(`/marketing_agents/${rowInfo.id}`);
  }

  const createMarketingAgent = () => {
    setMarketingAgent({});
    setPlans([]);
    setLabel('Crear');
    setOpenForm(true);
  }

  const handleEditMarketingAgent = async rowInfo => {
    console.log('Click EDITAR***');
    const resp = await ShowMarketingAgent(rowInfo.id)
    setPlans([]);

    if (resp.item.plans){
      let ids = resp.item.plans.map( element=> element.id);
      setPlans(ids);
    }
    setMarketingAgent(rowInfo);
    setLabel('Guardar');
    setOpenForm(true);
  }

  return (
    <React.Fragment>
        <div className={styles.wrapperBody}>
          {
            loading
            &&
            <Loading/>
          }
          {
            !loading
            &&
            Object.keys(items).length > 0
            &&
            <TableWrapper
              totalItems={paginationInfo ? `+${paginationInfo.count}` : '+0'}
              title='Promotores'
              actions={
                <Button
                  label='Nuevo(a) Promotor'
                  size='small'
                  onClick={createMarketingAgent}
                  startIcon={<AddIcon />}
                />
              }
              table={
                <Table
                  columns={columns}
                  data={items}
                  loading={false}
                  fetchData={fetchData}
                  paginationInfo={paginationInfo}
                  onEdit={rowInfo => handleEditMarketingAgent(rowInfo)}
                  onRowClick={clickOnRow}
                />
              }
            />
          }
          {
            !loading
            &&
            Object.keys(items).length === 0
            &&
            <EmptyStatePage
              title='No tienes Promotores'
              createButtonLabel='Nuevo(a) Promotor'
              onCreate={createMarketingAgent}
            />
          }
          {
            openForm
            &&
            <Form
              onClose={() => setOpenForm(false)}
              fetchData={fetchData}
              marketingAgent={marketingAgent}
              plans={plans}
              labelButton={label}
            />
          }
        </div>
    </React.Fragment>
  )
}

export default MarketingAgents;
