//Import external libraries
import React, { useState, useContext, useCallback, useMemo } from 'react';

//Import utils or services

//Import shared components, order A-Z
import Grid from '@material-ui/core/Grid';
import Modal from '../shared/modal';
import TextOutlinedField from '../shared/outlined_fields/text';
import SelectOutlinedField from '../shared/outlined_fields/select';

//Import controllers
import { CreatePlan, UpdatePlan } from '../../controllers/plans';
import { GlobalDataContext, FetchGlobalData } from '../../controllers/global_data';
//Import styles

//Import constants or services
import { PLAN_TYPES } from '../../lib/enums';

const Form = ({ onClose, fetchData, plan={ }, labelButton, plans }) => {
  const { dispatchGlobalData } = useContext(GlobalDataContext);
  const [errorField, setErrorField] = useState({});
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(
    {
      ...plan
    }
  );

  const handleSetFormData = useCallback(({target: { name, value }}) => {
    setFormData({
      ...formData,
      [name]: value
    });
  }, [formData, setFormData]);

  const canContinue = useMemo(() => {
    return formData.name &&
           formData.rate &&
           formData.time &&
           formData.planType
  }, [formData]);

  const handleSubmit = useCallback(async (evt) => {
    evt.preventDefault();
    setLoading(true);
    let success = true;
    let errors = {};
    let resp = null;

    const params = {
      ...formData,
      rate: formData.rate.trim().replace(/\./g, '').replace('$', '')
    }

    if (!!plan.id){
      resp = await UpdatePlan(plan.id, params);
    } else {
      resp = await CreatePlan(params);
    }

    success = resp.success;
    errors = resp.errors;

    setLoading(false);

    if (!success){
      return setErrorField(errors);
    }

    fetchData();
    FetchGlobalData(dispatchGlobalData);
    onClose();
  }, [formData]);

  const availablePlanTypes = useMemo(() => {
    // TODO currently the mobile app only have the option for one Basic plan, so, the admin is unable to create more than
    // one basic plan, it must be removed when the user in mobile app can have several plan options to select
    const BASIC_PLAN_TYPE = 'basic';
    if (plan.planType !== BASIC_PLAN_TYPE && plans.find(plan => plan.planType === BASIC_PLAN_TYPE)) {
      return PLAN_TYPES.filter(plan => plan.value !== BASIC_PLAN_TYPE);
    }
    return PLAN_TYPES;
  }, [plans, plan])

  const rate = useMemo(() => {
    if (!formData.rate) {
      return "";
    }

    return formData.rate.indexOf('$') >= 0 ? parseInt(formData.rate.replace(/\.|\$/g, '').trim()) : parseInt(formData.rate);
  }, [formData.rate])

  return (
    <Modal
      title='Nuevo Plan'
      buttonSaveLabel={labelButton}
      disabled= {!canContinue}
      onSubmit={handleSubmit}
      isSubmitting={loading}
      onClose={onClose}
      content={(
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextOutlinedField
              error={Boolean(errorField.name)}
              errorMessage={errorField.name}
              label='Nombre del plan'
              name='name'
              onChange={handleSetFormData}
              value={formData.name}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextOutlinedField
              error={Boolean(errorField.rate)}
              errorMessage={errorField.rate}
              label='Valor del plan'
              name='rate'
              onChange={handleSetFormData}
              placeholder='$'
              mask='money'
              value={rate}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextOutlinedField
              error={Boolean(errorField.time)}
              errorMessage={errorField.time}
              label='Tiempo'
              name='time'
              onChange={handleSetFormData}
              type='number'
              value={formData.time}
              required={true}
              endAdornment={'Meses'}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectOutlinedField
              error={Boolean(errorField.planType)}
              errorMessage={errorField.planType}
              label='Tipo de plan'
              name='planType'
              onChange={handleSetFormData}
              options={availablePlanTypes}
              value={formData.planType}
              required={true}
            />
          </Grid>
        </Grid>
      )}
    />
  )
}

export default Form;
