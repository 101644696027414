import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.222 19.778c-.924-.924-1.19-2.334-.9-4.184C1.81 14.491 1 13.307 1 12s.81-2.491 2.322-3.594c-.29-1.85-.024-3.26.9-4.184.924-.924 2.334-1.19 4.184-.9C9.509 1.81 10.693 1 12 1s2.491.81 3.594 2.322c1.85-.29 3.26-.024 4.184.9.924.924 1.19 2.334.9 4.184C22.19 9.509 23 10.693 23 12s-.81 2.491-2.322 3.594c.29 1.85.024 3.26-.9 4.184-.924.924-2.334 1.19-4.184.9C14.491 22.19 13.307 23 12 23s-2.491-.81-3.594-2.322c-1.85.29-3.26.024-4.184-.9zm4.43-1.18a1 1 0 011.05.434C10.582 20.393 11.367 21 12 21c.632 0 1.418-.607 2.299-1.968a1 1 0 011.048-.434c1.586.34 2.57.213 3.017-.234.447-.447.573-1.431.234-3.017a1 1 0 01.434-1.048C20.393 13.418 21 12.632 21 12c0-.632-.607-1.418-1.968-2.299a1 1 0 01-.434-1.048c.34-1.585.213-2.57-.234-3.017-.447-.447-1.431-.573-3.017-.234a1 1 0 01-1.048-.434C13.418 3.607 12.632 3 12 3c-.632 0-1.418.607-2.299 1.968a1 1 0 01-1.048.434c-1.585-.34-2.57-.213-3.017.234-.447.447-.573 1.432-.234 3.017A1 1 0 014.968 9.7C3.607 10.582 3 11.368 3 12c0 .632.607 1.418 1.968 2.299a1 1 0 01.434 1.048c-.34 1.586-.213 2.57.234 3.017.447.447 1.432.573 3.017.234zm6.64-10.305L11 12.586l-2.293-2.293-1.414 1.414L11 15.414l5.707-5.707-1.414-1.414z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

export default SvgComponent;
