import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {},
  label: {
    color: '#4F4F4F',
    fontSize: 14,
    margin: [[0, 0, 4]]
  },
  icon: {
    position: 'relative',
    top: -2
  }
}));
