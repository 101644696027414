import React from 'react';
import PropTypes from 'prop-types';
import lscache from 'lscache';
import { Route, Redirect } from 'react-router-dom';
import { AUTH_TOKEN } from '../controllers/users/constants';

const AuthRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = lscache.get(AUTH_TOKEN);

  return (
    <Route
      {...rest}
      render={() =>
        isLoggedIn ? (
          <Redirect to="/" />
        ) : (
          <Component />
        )
      }
    />
  )
}

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default AuthRoute;
