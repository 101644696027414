import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
  },
  label: {
    color: ['#262626', '!important'],
    fontSize: 16,
    fontWeight: 500,
    margin: [[0, 0, 4]]
  },
  required: {
    color: '#EB5757',
    marginLeft: 4
  },
  radioGroup: {
    flexDirection: 'row',
    height: 48,
    '& > label': {
      fontSize: 16,
      color: '#262626',
      marginRight: 50
    },
    '& > label:last-child': {
      marginRight: 0
    }
  }
}));
