import React, { Fragment, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import LoadingScreen from '../loading_screen';

const withLoading = Component => props => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, parseInt(process.env.REACT_APP_DEFAULT_LOADING_SCREEN_TIME) * 1000)
    }
  }, [loading]);

  return (
    <Fragment>
      {
        loading && (
          <LoadingScreen />
        )
      }
      <CSSTransition
        in={!loading}
        timeout={parseInt(process.env.REACT_APP_DEFAULT_LOADING_SCREEN_TIME) * 1000}
        mountOnEnter
        unmountOnExit
        classNames="content"
      >
        <div>
          <Component {...props} />
        </div>
      </CSSTransition>
    </Fragment>
  )
}

export default withLoading;
