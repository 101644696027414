export const columns = [{
   Header: 'id', //Label de la columna de la tabla
   accessor: 'id', //Attributo que se va a mostrar como el valor de la celda
 },{
  Header: 'Nombres', //Label de la columna de la tabla
  accessor: 'firstName' //Attributo que se va a mostrar como el valor de la celda
}, {
  Header: 'Apellidos', //Label de la columna de la tabla
  accessor: 'lastName', //Attributo que se va a mostrar como el valor de la celda
}, {
  Header: 'Profesional', //Label de la columna de la tabla
  accessor: 'profession', //Attributo que se va a mostrar como el valor de la celda
}, {
  Header: 'Especialidad', //Label de la columna de la tabla
  accessor: 'specialty', //Attributo que se va a mostrar como el valor de la celda
},
{
  Header: 'Ciudad', //Label de la columna de la tabla
  accessor: 'residenceCity', //Attributo que se va a mostrar como el valor de la celda
}, {
  Header: 'País', //Label de la columna de la tabla
  accessor: 'residenceCountry', //Attributo que se va a mostrar como el valor de la celda
}];
