import React, { useState, useContext, useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import Alert from '../shared/alert';
import Button from '../shared/button';
import LogoIcon from '../shared/icons/logo';
import TextOutlinedField from '../shared/outlined_fields/text';
import { emailValidator, passwordValidator } from '../../lib/validators';

import useStyles from './__styles__';

import { CurrentUserContext, Login } from '../../controllers/users/current';

const SignIn = () => {
  const styles = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  const { dispatchCurrentUser } = useContext(CurrentUserContext);

  const canContinue = useMemo(() => {
    return emailValidator(email) && passwordValidator(password);
  }, [email, password])

  const handleSubmit = useCallback(async (evt) => {
    evt.preventDefault();
    if (!canContinue) {
      return false;
    }

    setSaving(true);
    const { success, errors } = await Login(dispatchCurrentUser, { email, password });
    setSaving(false);
    if (!success) {
      setErrors({user: errors});
    }
  }, [canContinue, email, password, dispatchCurrentUser]);

  return (
    <div className={styles.root}>
      <Alert type='error' message={errors.user} display={errors.user}/>
      <div className={styles.formSection}>
        <div className={styles.formWrapperSection}>
          <h1 className={styles.logo}>
            <LogoIcon height={29} width={80}/>
            <span>Blue App</span>
          </h1>
          <h2 className={styles.pageTitle}>Ingresar</h2>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formFields}>
              <div className={styles.fieldItems}>
                <TextOutlinedField
                  label='Tu email'
                  name='email'
                  type='email'
                  value={email}
                  onChange={({ target: { value } }) => setEmail(value)}
                  error={!!errors.user}
                />
              </div>
              <div className={styles.fieldItems}>
                <TextOutlinedField
                  label='Contraseña'
                  name='password'
                  type='password'
                  value={password}
                  onChange={({ target: { value } }) => setPassword(value)}
                  error={!!errors.user}
                  endAdornment
                />
              </div>
            </div>
            <NavLink to='/forgot_password' className={styles.forgotPasswordLink}>¿Has olvidado tu contraseña?</NavLink>
            <div className={styles.formActions}>
              <Button
                label='Siguiente'
                type='submit'
                classes={{
                  root: styles.actionButton,
                  label: styles.actionButtonLabel
                }}
                disabled={!canContinue}
                loading={saving}
              />
            </div>
          </form>
          <div className={styles.appMetaData}>
            <p className={styles.copyright}>©2020 All Rights Reserved Blue.</p>
            <div className={styles.policies}>
              <p className={styles.policyLink}>Cookie Preferences</p>
              <NavLink to='#' className={styles.policyLink}>Privacy and Terms</NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.heroSection}>
        <img src={require('../../assets/figures/banner_sign_in.png')} alt='Paramedic' width='79.7101%'/>
      </div>
    </div>
  )
}

export default SignIn;
