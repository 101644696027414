import React,{ useState } from 'react';

//Import external libraries
import clsx from 'clsx';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

//Import utils or services

//Import shared components, order A-Z

//Import styles
import useStyles from './__styles__/assigned_plans';

//Import local components

//Import constants or services

const AssignedPlans = ({ plans=[] }) => {
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className={styles.root}>
      <p className={styles.title}>Planes Asignados</p>
      {
        !!plans.length
        &&
        <div className={styles.assignedItems}>
          <p className={styles.assignedItem}>{plans[0].label}</p>
          {plans[1] && <p className={styles.assignedItem}>{plans[1].label}</p> }
          {
            plans.length > 2
            &&
            <React.Fragment>
              <button
                className={clsx(styles.assignedItem, styles.assignedItemButton)}
                onClick={evt => {
                  setAnchorEl(anchorEl ? null : evt.currentTarget);
                }}
                aria-controls={!!anchorEl ? 'menu-list-grow' : undefined}
              >
                +2
              </button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                classes={{
                  list: styles.additionalItemsMenuList
                }}
              >
                {
                  plans.slice(2).map(plan => (
                    <MenuItem classes={{root: styles.assignedItem}} key={plan.label}>
                      {plan.label}
                    </MenuItem>
                  ))
                }
              </Menu>
            </React.Fragment>
          }
        </div>
      }
    </div>
  )
}

export default AssignedPlans;
