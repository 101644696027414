import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  tableWrapper: {
    position: 'relative',
    minHeight: 40
  },
  loadingState: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF99'
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0
  },
  th: {
    borderRight: [[3, 'solid', '#FAFAFA']],
    borderLeft: [[3, 'solid', '#FAFAFA']],
    '&:first-child': {
      paddingLeft: 15,
      borderRight: [[3, 'solid', '#FAFAFA']],
      borderLeft: 0
    },
    '&:last-child': {
      paddingRight: 15,
      borderLeft: [[3, 'solid', '#FAFAFA']],
      borderRight: 0
    },
    '&:first-child > $thContent': {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    '&:last-child > $thContent': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    }
  },
  thContent: {
    backgroundColor: '#F1F3F9',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 11,
    color: '#929CB0',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    padding: [[0, 8]]
  },
  tr: {
    '&:hover': {
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)'
    },
    '&:hover $trActions': {
      display: 'flex'
    }
  },
  trActions: {
    position: 'absolute',
    top: '50%',
    right: 18,
    transform: 'translateY(-50%)',
    width: 94,
    background: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 1.8%, #FFFFFF 21.63%)',
    display: 'none',
    justifyContent: 'flex-end',
    '& > button': {
      width: 28,
      height: 28,
      borderRadius: '50%',
      border: 0,
      padding: 0,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      marginRight: 10,
      outline: 'none',
      '&:last-child': {
        marginRight: 0
      }
    },
    '& > button:hover': {
      backgroundColor: '#F4F6FA'
    }
  },
  td: {
    position: 'relative',
    '&:first-child': {
      paddingLeft: 15
    },
    '&:last-child': {
      paddingRight: 15
    },
  },
  tdContent: {
    fontSize: 14,
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.15px',
    padding: [[0, 8]],
    height: 38,
    borderBottom: [[1, 'solid', '#6E91FF20']],
    display: 'flex',
    alignItems: 'center',
  },
  pagination: {
    marginTop: 28,
    display: 'flex',
    justifyContent: 'center',
    '& > button': {
      cursor: 'pointer',
      fontFamily: 'Roboto',
      minHeight: 32,
      minWidth: 32,
      borderRadius: 16,
      border: [[1, 'solid', '#F4F6FA']],
      background: '#F4F6FA',
      fontSize: 14,
      textAlign: 'center',
      letterSpacing: '0.25px',
      color: '#000000',
      marginRight: 4,
      outline: 'none',
      '&:last-child': {
        marginRight: 0
      },
      '&:hover': {
        border: [[1, 'solid', '#1F2C5660']]
      }
    },
    '& > button.active': {
      fontWeight: 'bold',
      border: [[1, 'solid', '#1F2C5660']]
    },
    '& > button.nav-button': {
      padding: [[0, 12]]
    },
    '& > button.nav-button.can-not-navigate': {
      color: '#00000040',
      cursor: 'not-allowed',
      '&:hover': {
        border: [[1, 'solid', '#F4F6FA']],
      }
    }
  },
  triggerEvent: {
    cursor: 'pointer'
  }
}));
