import React from "react"

function SvgComponent(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 21.083C5.431 21.083.917 16.57.917 11S5.43.917 11 .917 21.083 5.43 21.083 11 16.57 21.083 11 21.083zm6.783-5.385a8.25 8.25 0 10-13.566 0C5.25 14.335 7.673 13.75 11 13.75c3.327 0 5.751.585 6.783 1.947zm-1.315 1.48c-.269-.965-2.196-1.595-5.468-1.595s-5.2.63-5.468 1.595A8.219 8.219 0 0011 19.25a8.219 8.219 0 005.468-2.072zM11 5.5c-2.22 0-3.667 1.61-3.667 3.667 0 3.141 1.614 4.583 3.667 4.583 2.033 0 3.667-1.394 3.667-4.4 0-2.089-1.454-3.85-3.667-3.85zM9.167 9.167c0 2.08.75 2.75 1.833 2.75 1.08 0 1.833-.643 1.833-2.567 0-1.146-.719-2.017-1.833-2.017-1.16 0-1.833.748-1.833 1.834z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

export default SvgComponent
