import React, { useState, useContext, useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import queryString from 'query-string';
import Alert from '../shared/alert';
import Button from '../shared/button';
import LogoIcon from '../shared/icons/logo';
import TextOutlinedField from '../shared/outlined_fields/text';
import { passwordValidator } from '../../lib/validators';

import useStyles from './__styles__';

import { CurrentUserContext, ResetPassword, InvitationPassword } from '../../controllers/users/current';

const SetPassword = () => {
  const styles = useStyles();
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  const { dispatchCurrentUser } = useContext(CurrentUserContext);

  const canContinue = useMemo(() => {
    return passwordValidator(password);
  }, [password])

  const handleSubmit = useCallback(async (evt)=>{
    evt.preventDefault();
    if (!canContinue) {
      return false;
    }

    const { reset_password_token: resetPasswordToken } = queryString.parse(window.location.search);

    if (resetPasswordToken) {
      setSaving(true);
      const { success } = await ResetPassword(dispatchCurrentUser, { resetPasswordToken, password });
      setSaving(false);

      if (!success) {
        setErrors(
          {reset_password_token: "Token invalido o este a expirado "});
        setTimeout(() => setErrors({}), 5000);
      }
    } else {
      const { invitation_token: invitationToken } = queryString.parse(window.location.search);
      const { success } = await InvitationPassword(dispatchCurrentUser, { invitationToken, password });

      if (!success) {
        setErrors(
          {reset_password_token: "Token invalido o este a expirado "});
        setTimeout(() => setErrors({}), 5000);
      }
    }
  }, [canContinue, password, dispatchCurrentUser])

  return (
    <div className={styles.root}>
      <Alert type='error' message={errors.reset_password_token} display={errors.reset_password_token}/>
      <div className={styles.formSection}>
        <div className={styles.formWrapperSection}>
          <h1 className={styles.logo}>
            <LogoIcon height={29} width={80}/>
            <span>Blue App</span>
          </h1>
          <h2 className={styles.pageTitle}>Crea una nueva <br/> contraseña</h2>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formFields}>
              <div className={styles.fieldItems}>
                <TextOutlinedField
                  label='Contraseña'
                  name='password'
                  type='password'
                  value={password}
                  onChange={({ target: { value } }) => setPassword(value)}
                  error={!!errors.user}
                  endAdornment
                />
              </div>
            </div>
            <div className={styles.formActions}>
              <Button
                label='Siguiente'
                type='submit'
                classes={{
                  root: styles.actionButton,
                  label: styles.actionButtonLabel
                }}
                disabled={!canContinue}
                loading={saving}
              />
            </div>
          </form>
          <div className={styles.appMetaData}>
            <p className={styles.copyright}>©2020 All Rights Reserved Blue.</p>
            <div className={styles.policies}>
              <p className={styles.policyLink}>Cookie Preferences</p>
              <NavLink to='#' className={styles.policyLink}>Privacy and Terms</NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.heroSection}>
        <img src={require('../../assets/figures/banner_set_password.png')} alt='Forgot Password' width='79.7101%'/>
      </div>
    </div>
  )
}

export default SetPassword;
