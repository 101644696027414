import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M7 10V7a5 5 0 1110 0v3h1c1.068 0 2 .776 2 1.833v8.334C20 21.224 19.068 22 18 22H6c-1.068 0-2-.776-2-1.833v-8.334C4 10.776 4.932 10 6 10h1zm2 0h6V7a3 3 0 00-6 0v3zm-3 2v8h12v-8H6zm6 5a1 1 0 110-2 1 1 0 010 2z"
      />
    </svg>
  )
}

export default SvgComponent
