import React, { useState, useEffect } from 'react';

import useStyles from '../__styles__';

const Documents = ({ onBack }) => {
  const styles = useStyles();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      window.scroll({top: 0, left: 0, behavior: 'smooth' });
      setLoaded(true);
    }
  }, [loaded, setLoaded]);

  return (
    <div className={styles.completedStepWrapper}>
      <img
        src={require('../../../assets/figures/search_doctors.png')}
        alt='Tu registro ha sido exitoso'
        width='100%'
      />
      <p className={styles.completedStepTitle}>Su formulario ha sido enviado con éxito</p>
      <p className={styles.completedStepMessage}>Gracias por su solicitud de ingreso.</p>
    </div>
  )
}

export default Documents;
