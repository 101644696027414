import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M7 11V6a5 5 0 1110 0v2h-2V6a3 3 0 00-6 0v5h9a2 2 0 012 2v8a2 2 0 01-2 2H6a2 2 0 01-2-2v-8a2 2 0 012-2h1zm-1 2v8h12v-8H6zm6 5a1 1 0 110-2 1 1 0 010 2z"
      />
    </svg>
  )
}

export default SvgComponent
