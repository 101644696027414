const currencyFormat = require('currency-formatter');

export const currencyFormatter = (value, currency) => {
  return currencyFormat.format(value, {
    code: currency.toUpperCase(),
    thousandsSeparator: '.',
    decimalDigits: currency === 'cop' ? 0 : 2,
    precision: currency === 'cop' ? 0 : 2,
    format: '%s%v'
  });
}

export const fileSize = size => {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' '+['B', 'KB', 'MB', 'GB', 'TB'][i];
}

export const pluralizeHelper = (items, singularText, pluralText) => {
  return items.length > 1 ? pluralText : singularText
}

export const secondsToString = seconds => {
  var hour = Math.floor(seconds / 3600);
  hour = (hour < 10)? '0' + hour : hour;
  var minute = Math.floor((seconds / 60) % 60);
  minute = (minute < 10)? '0' + minute : minute;
  var second = seconds % 60;
  second = (second < 10)? '0' + second : second;
  return hour + ':' + minute + ':' + second;
}
