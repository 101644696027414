import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={38} height={28} viewBox="0 0 38 28" fill="none" {...props}>
      <path
        d="M34.549 0H3.45C1.536 0 0 1.61 0 3.617v20.766C0 26.39 1.536 28 3.451 28H34.55C36.464 28 38 26.39 38 24.383V3.617C38 1.61 36.464 0 34.549 0z"
        fill="#FEDB41"
      />
      <path
        d="M38 3.617v20.766C38 26.39 36.464 28 34.549 28H19V0h15.549C36.464 0 38 1.61 38 3.617z"
        fill="#FC3"
      />
      <path
        d="M29.094 14a4.458 4.458 0 01-4.453-4.453 4.458 4.458 0 014.453-4.453 4.458 4.458 0 014.453 4.453A4.458 4.458 0 0129.094 14z"
        fill="#FE9923"
      />
      <path
        d="M14.547 14h-8.98a1.113 1.113 0 01-1.114-1.113v-6.68c0-.615.498-1.113 1.113-1.113h8.98c.616 0 1.114.498 1.114 1.113v6.68c0 .615-.498 1.113-1.113 1.113z"
        fill="#994C0F"
      />
      <path
        d="M32.434 22.906h-3.34a1.113 1.113 0 110-2.226h3.34a1.113 1.113 0 110 2.226z"
        fill="#0059AA"
      />
      <path
        d="M8.906 22.906h-3.34a1.113 1.113 0 110-2.226h3.34a1.113 1.113 0 110 2.226z"
        fill="#0D70B2"
      />
      <path
        d="M24.64 22.906h-3.413a1.113 1.113 0 110-2.226h3.414a1.113 1.113 0 110 2.226z"
        fill="#0059AA"
      />
      <path
        d="M16.773 22.906h-3.34a1.113 1.113 0 110-2.226h3.34a1.113 1.113 0 110 2.226z"
        fill="#0D70B2"
      />
      <path
        d="M22.34 5.094A4.399 4.399 0 0019 6.63a4.286 4.286 0 00-1.113 2.917c0 1.113.4 2.137 1.113 2.917A4.399 4.399 0 0022.34 14c2.449 0 4.527-2.004 4.527-4.453 0-2.45-2.078-4.453-4.527-4.453z"
        fill="#FD003A"
      />
      <path
        d="M26.867 9.547c0 2.449-2.078 4.453-4.527 4.453A4.399 4.399 0 0119 12.464V6.63a4.399 4.399 0 013.34-1.536c2.449 0 4.527 2.004 4.527 4.453z"
        fill="#E50027"
      />
    </svg>
  )
}

export default SvgComponent
