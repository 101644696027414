import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh'
  },
  mainHeader: {
    alignItems: 'center',
    background: '#1F2C56',
    display: 'flex',
    height: 50,
    justifyContent: 'space-between',
    padding: [[0, 20]],
    position: 'absolute',
    top: 0,
    width: '100%'
  },
  mainContent: {
    minHeight: '100vh',
    overflow: 'auto',
    paddingBottom: 50,
    paddingTop: 50,
    width: '100vw'
  }
}));
