import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  wrapper: {
    position: 'fixed',
    width: '100vw',
    height: 40,
    top: 34,
    left: 0,
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center'
  },
  root: {
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
    height: 40,
    top: 34,
    width: '83.3333vw'
  },
  noIcon: {
    display: 'none'
  },
  message: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    letterSpacing: '0.4px',
    color: '#FFFFFF'
  },
  error: {
    backgroundColor: '#343740'
  },
  success: {
    backgroundColor: theme.palette.secondary.main
  }
}));
