import HttpRequest from '../../services/http_request';
import Qs from 'qs';
import normalize from 'json-api-normalize';
import { PAGINATION } from  '../../lib/enums';

export const ListAdvertisements = async (page=1, search={}, sort) => {
  return new Promise( async (resolve, reject) => {
    try {
      const queryParams = Qs.stringify(
        {
          items: PAGINATION.ROWS_PER_PAGE,
          page,
          q: search,
          sort
        }, {
          encode: false
        }
      );

      const { data } = await HttpRequest({
        method: 'GET',
        url: `/admin/advertisings?${queryParams}`,
      });

      resolve({
        items: normalizer(data.advertisings),
        pagination: data.metadata
      });
    } catch (error) {
      reject({
        items: [],
        pagination: null
      });
    }
  });
}

export const CreateAdvertisements = async (params) =>{
  try{
      const formData = new FormData();
      formData.append('advertising[name]', params.name);
      formData.append('advertising[description]', params.description);
      formData.append('advertising[start_at]', params.startAt);
      formData.append('advertising[end_at]', params.endAt);
      formData.append('advertising[link]', params.link);
      formData.append('advertising[advertising_category_id]', params.advertisingCategoryId);
      formData.append('advertising[image]', params.image);

      const resp = await HttpRequest({
        method: 'POST',
        url: '/admin/advertisings',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      return {
        success: true,
        resp: resp
      }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};

export const UpdateAdvertisements = async (id, params) =>{
  try{
      const formData = new FormData();
      formData.append('advertising[name]', params.name);
      formData.append('advertising[description]', params.description);
      formData.append('advertising[start_at]', params.startAt);
      formData.append('advertising[end_at]', params.endAt);
      formData.append('advertising[link]', params.link);
      formData.append('advertising[advertising_category_id]', params.advertisingCategoryId);

      if (params.image instanceof File) {
        formData.append('advertising[image]', params.image);
      }

      const resp = await HttpRequest({
        method: 'PUT',
        url: `/admin/advertisings/${id}`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      return {
        success: true,
        resp: resp
      }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};

export const DestroyAdvertisement = async id => {
  try {
    await HttpRequest({
      method: 'DELETE',
      url: `/admin/advertisings/${id}`
    });

    return {
      success: true
    };
  } catch (error) {
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};


const normalizer = data => normalize(data).get([
  'id',
  'name',
  'description',
  'startAt',
  'endAt',
  'link',
  'image',
  'fileName',
  'fileSize',
  'advertisingCategory.id',
  'advertisingCategory.name'
]);
