import React from 'react';

//Import external libraries
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

//Import utils or services

//Import shared components, order A-Z
import LineChart from '../shared/line_chart';

//Import styles
import useStyles from './__styles__/graph_report';

//Import local components

//Import constants or services

const GraphReport = ({ data=[], currentYear, months = [], onPreviousYear, onNextYear }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <p className={styles.title}>Número de Clientes registrados por mes</p>
        <div className={styles.graphNavigation}>
          <IconButton size='small' onClick={onPreviousYear}>
            <KeyboardArrowLeftIcon color='action' classes={{colorAction: styles.iconNavigationIcon}} />
          </IconButton>
          <p className={styles.year}>{currentYear}</p>
          <IconButton size='small' onClick={onNextYear}>
            <KeyboardArrowRightIcon color='action' classes={{colorAction: styles.iconNavigationIcon}}/>
          </IconButton>
        </div>
      </div>
      <LineChart
        data={data}
        labels={months}
        backgroundColor={'#DFE7FF'}
        borderColor={'#4772FA'}
        pointBackgroundColor={'#4772FA'}
        pointHoverBackgroundColor={'#4772FA'}
        pointHoverBorderColor={'#335EE6'}
      />
    </div>
  )
}

export default GraphReport;
