import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import lscache from 'lscache';
import AdminLayout from '../components/layouts/application';
import MarketingAgentLayout from '../components/layouts/marketing_agent';
import { AUTH_TOKEN } from '../controllers/users/constants';

const Layouts = {
  admin: AdminLayout,
  marketingAgent: MarketingAgentLayout
}

const PrivateRoute = ({ component: Component, layout, ...rest }) => {
  const isLoggedIn = lscache.get(AUTH_TOKEN);

  const Layout = Layouts[layout];

  return (
    <Route
      {...rest}
      render={() =>
        isLoggedIn ? (
          <Layout component={Component} />
        ) : (
          <Redirect to="/sign_in" />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
};

export default PrivateRoute;
