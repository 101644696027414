import React, { useContext, useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import lscache from 'lscache';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import AdminLogoIcon from '../shared/icons/admin_logo';
import CaseIcon from '../shared/icons/case';
import ClipboardTextIcon from '../shared/icons/clipboard_text';
import CouponIcon from '../shared/icons/coupon';
import MapPin from '../shared/icons/map_pin';
import UserIcon from '../shared/icons/circle_user';
import VerifiedIcon from '../shared/icons/verified';

import { CurrentUserContext, VerifyAuthentication, Logout } from '../../controllers/users/current';

import useStyles from './__styles__';

const ApplicationLayout = ({component: Component}) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { currentUser, dispatchCurrentUser } = useContext(CurrentUserContext);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (!currentUser.loggedIn && lscache.get('AUTH_TOKEN')) {
      VerifyAuthentication(dispatchCurrentUser);
    }
  }, [currentUser.loggedIn, dispatchCurrentUser]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = async () => {
    await Logout(dispatchCurrentUser);
  };

  return (
    <div className={styles.root}>
      <div className={styles.sidebar}>
        <AdminLogoIcon className={styles.sidebarLogo}/>
        <nav className={styles.sidebarNavigation}>
          <NavLink
            to='/'
            className={styles.sidebarNavigationItem}
            isActive={(match, location) => {
              if (!match) {
                return false;
              } else {
                return match.url === location.pathname
              }
            }}
          >
            <ClipboardTextIcon width={18} color='#FFFFFF' className={styles.sidebarNavigationItemIcon}/>
            <span>Nuevos Registros</span>
          </NavLink>
          <NavLink to='/appointments' className={styles.sidebarNavigationItem}>
            <ClipboardTextIcon width={18} color='#FFFFFF' className={styles.sidebarNavigationItemIcon}/>
            <span>Consultas</span>
          </NavLink>
          <NavLink to='/professionals' className={styles.sidebarNavigationItem}>
            <VerifiedIcon width={18} color='#FFFFFF' className={styles.sidebarNavigationItemIcon}/>
            <span>Profesionales Inscritos</span>
          </NavLink>
          <NavLink to='/advertisements' className={styles.sidebarNavigationItem}>
            <CouponIcon width={18} color='#FFFFFF' className={styles.sidebarNavigationItemIcon}/>
            <span>Publicidad</span>
          </NavLink>
          <NavLink to='/customers' className={styles.sidebarNavigationItem}>
            <UserIcon width={18} color='#FFFFFF' className={styles.sidebarNavigationItemIcon}/>
            <span>Usuarios</span>
          </NavLink>
          <NavLink to='/marketing_plans' className={styles.sidebarNavigationItem}>
            <CaseIcon width={18} color='#FFFFFF' className={styles.sidebarNavigationItemIcon}/>
            <span>Network Marketing</span>
          </NavLink>
          <NavLink to='/marketing_levels' className={styles.sidebarNavigationItem}>
            <CaseIcon width={18} color='#FFFFFF' className={styles.sidebarNavigationItemIcon}/>
            <span>Niveles de Network Marketing</span>
          </NavLink>
          <NavLink to='/marketing_agents' className={styles.sidebarNavigationItem}>
            <MapPin width={18} color='#FFFFFF' className={styles.sidebarNavigationItemIcon}/>
            <span>Promotores</span>
          </NavLink>
        </nav>
      </div>
      <div className={styles.main}>
        <div className={styles.mainHeader}>
          <p
            className={styles.mainHeaderCurrentUser}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <span>{`${currentUser.firstName} ${currentUser.lastName[0]}.`}</span><UserIcon />
          </p>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 10}}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow">
                    <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        </div>
        <div className={styles.mainContent}>
          <Component />
        </div>
      </div>
    </div>
  )
}

export default ApplicationLayout;
