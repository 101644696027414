import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  paper: {
    minWidth: 480,
    borderRadius: 8
  },
  dialogTitle: {
    alignItems: 'center',
    backgroundColor: '#1F2C56',
    display: 'flex',
    minHeight: 38,
    padding: [[0, 18]],
    '& > h2 > span': {
      fontWeight: 'bold',
      fontSize: 16,
      letterSpacing: '0.4px',
      color: '#FFFFFF',
      lineHeight: 1,
      padding: [[12, 0]]
    },
    '& > h2': {
      display: 'flex',
      flex: 1
    }
  },
  dialogTitleSpacer: {
    flex: 1
  },
  dialogTitleCloseButton: {
    alignSelf: 'flex-start',
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    display: 'flex',
    margin: 0,
    outline: 'none',
    padding: [[12, 0]]
  },
  dialogContent: {
    padding: [[8, 18]],
    overflowY: 'hidden'
  },
  dialogActions: {
    padding: [[8, 20, 20]]
  },
  actionsLeft: {
    justifyContent: 'flex-start'
  }
}));
