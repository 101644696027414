import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import DragAndDropFile from '../../shared/fields/drag_and_drop_file';

import useStyles from '../__styles__';

import { STEPS } from '../constants';

const Documents = ({ onBack, data, onChangeData}) => {
  const styles = useStyles();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      window.scroll({top: 0, left: 0, behavior: 'smooth' });
      setLoaded(true);
    }
  }, [loaded, setLoaded]);

  const handleBack = useCallback(() => {
    onBack(STEPS.INFORMATION);
  }, [onBack]);

  return (
    <div className={styles.stepContent}>
      <Button className={styles.backButton} onClick={handleBack}><ArrowBackIosIcon fontSize="small" />Atras</Button>
      <div className={styles.documentsSection}>
        <Grid container spacing={3} className={styles.formGroupContent}>
          <Grid item xs={12}>
            <DragAndDropFile label='Acta de Grado' data={data.certificateOfDegree}
              onChange={value => onChangeData({target: {name: 'certificateOfDegree', value}})}
              typeAccept={'image/jpeg, image/png, application/pdf'}
            />
          </Grid>
          <Grid item xs={12}>
            <DragAndDropFile label='Tarjeta Profesional' data={data.professionalCard}
              onChange={value => onChangeData({target: {name: 'professionalCard', value}})}
              typeAccept={'image/jpeg, image/png, application/pdf'}
            />
          </Grid>
          <Grid item xs={12}>
            <DragAndDropFile label='Documento de Identidad' data={data.identificationCard}
              onChange={value => onChangeData({target: {name: 'identificationCard', value}})}
              typeAccept={'image/jpeg, image/png, application/pdf'}
            />
          </Grid>
          <Grid item xs={12}>
            <DragAndDropFile label='Foto para Perfil Profesional' data={data.profilePicture}
              onChange={value => onChangeData({target: {name: 'profilePicture', value}})}
              typeAccept={'image/jpeg, image/png'}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Documents;
