import React from 'react';

//Import external libraries
import clsx from 'clsx';

//Import utils or services

//Import shared components, order A-Z

//Import styles
import useStyles from './__styles__/current_level_info';

//Import local components

//Import constants or services

const CurrentLevelInfo = ({ name, pointsForNextLevel=0, totalPoints=0, currentPoints=0 }) => {
  const styles = useStyles();

  const progressPercentage =
    currentPoints > 0 ? (currentPoints / totalPoints * 100).toFixed(4) : 0;

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <p className={styles.sectionTitle}>Nivel actual</p>
        <p className={styles.sectionValue}>{name}</p>
      </div>
      <div className={clsx(styles.section, styles.progressSection)}>
        <p className={styles.sectionTitle}>{`${currentPoints} ${currentPoints > 1 ? 'puntos' : 'punto'} de ${totalPoints}`}</p>
        <div className={styles.progressBarRoot}>
          <div className={styles.progressBarValue} style={{ width: `${progressPercentage}%` }}></div>
        </div>
        <p className={styles.sectionSubtitle}>{`${pointsForNextLevel} ${pointsForNextLevel > 1 ? 'puntos' : 'punto'} para el siguiente nivel`}</p>
      </div>
    </div>
  )
}

export default CurrentLevelInfo;
