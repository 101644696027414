import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.518 15.021a2.998 2.998 0 01-2.12-5.12l4.241-4.24a1 1 0 011.414 1.413l-4.242 4.242a1 1 0 001.414 1.414l5.656-5.656a2.999 2.999 0 000-4.242 3.07 3.07 0 00-4.242 0L3.397 7.074A1 1 0 111.983 5.66l4.242-4.242a5.116 5.116 0 017.07 0 4.998 4.998 0 010 7.07L7.64 14.144a2.993 2.993 0 01-2.12.877z"
        fill="#4772FA"
      />
    </svg>
  )
}

export default SvgComponent
