import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 8.925v5.325h-1.5V8.925A3.751 3.751 0 019 1.5a3.75 3.75 0 01.75 7.425zm-3 1.693v1.52C4.55 12.413 3 13.04 3 13.5c0 .605 2.684 1.5 6 1.5s6-.895 6-1.5c0-.46-1.55-1.087-3.75-1.362v-1.52c3.042.333 5.25 1.358 5.25 2.882 0 1.88-3.36 3-7.5 3-4.14 0-7.5-1.12-7.5-3 0-1.524 2.208-2.549 5.25-2.882zm4.5-5.368a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

export default SvgComponent
