import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <rect
        x={0.5}
        y={0.5}
        width={15}
        height={15}
        rx={7.5}
        stroke="#262626"
        opacity={0.5}
      />
    </svg>
  )
}

export default SvgComponent
