import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={props.size || 20} height={props.size || 20} viewBox="0 0 20 20" fill="none" {...props}>
      <rect width={20} height={20} rx={10} fill={props.color || "#3CD3AE"} />
      <path d="M5 10.75l4 3 6-7.5" stroke="#fff" strokeWidth={2.5} />
    </svg>
  )
}

export default SvgComponent
