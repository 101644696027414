import axios from 'axios';
import { AUTH_TOKEN } from '../controllers/users/constants';
import { history } from '../config/history';
import humps from 'lodash-humps';
import lscache from 'lscache';
import _snakecase from 'lodash.snakecase';
import createHumps from '../../node_modules/lodash-humps/lib/createHumps';

const snakes = createHumps(_snakecase);

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000,
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => humps(data)
  ],
  transformRequest: [
    data => snakes(data),
    ...axios.defaults.transformRequest
  ]
});

instance.interceptors.request.use(
  async config => {
    const authToken = lscache.get(AUTH_TOKEN)
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    console.log(error)
    const {response: {status, config}} = error;
    if (status === 401 && config.headers.Authorization){
      lscache.set(AUTH_TOKEN, '');
      history.go('/sign_in')
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
