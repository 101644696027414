import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  displayEmptyOption: {
    color: '#20202060'
  },
  chip: {
    background: '#4772FA12',
    marginRight: 12
  },
  chipLabel: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto',
    fontSize: 14,
    letterSpacing: 0.25
  },
  chipIconColor: {
    color: theme.palette.primary.main,
  },
  chipIconFontSize: {
    height: 18,
    width: 18
  }
}));
