import React, { useCallback, useContext,useEffect, useState } from 'react';

//Import external libraries
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

//Import utils or services
import _snakeCase from 'lodash.snakecase';

//Import shared components, order A-Z
import Alert from '../shared/alert';
import Button from '../shared/button';
import Table from '../shared/table';
import TableWrapper from '../shared/table_wrapper';

//Import controllers
import { CurrentUserContext } from '../../controllers/users/current';
import { ListCustomers } from '../../controllers/customers';

//Import styles
import useStyles from './__styles__';

//Import local components
import Form from '../marketing_dashboard/form'

//Import constants or services
import { columns } from './data_table_schema';

const MarketingCustomers = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const [data, setData] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [order] = useState('desc');
  const [orderBy] = useState('firstName');
  const [paginationInfo, setPaginationInfo] = useState();
  const styles = useStyles();
  const [alert, setAlert] = useState({});

  const fetchCustomers = useCallback (async (id, page = 1) =>{
    const { items, pagination} = await ListCustomers(page, {added_by_eq: id}, `${_snakeCase(orderBy)} ${order}`);
    setData(items);
    setPaginationInfo(pagination);
  }, [order, orderBy]);

  useEffect(() => {
    fetchCustomers(currentUser.id);
  }, [fetchCustomers, currentUser]);

  const handleCreateCustomer = () => {
    setOpenForm(true);
  }

  return (
    <React.Fragment>
      <div className={styles.header}>
        <Alert type={alert.type} message={alert.message} display={!!alert.type}/>
        <div className={styles.wrapperContent}>
          <div className={styles.pageInformation}>
            <p className={clsx(styles.pageSubtitle, styles.breadcrumb)}>
              <NavLink to='/'><NavigateBeforeIcon />{`${currentUser.firstName} ${currentUser.lastName}`}</NavLink> / <span>Usuarios registrados</span>
            </p>
          </div>
          <div className={styles.headerActions}>
            <Button
              label='Nuevo cliente'
              size='small'
              onClick={handleCreateCustomer}
            />
          </div>
        </div>
      </div>
      <div className={clsx(styles.wrapperContent, styles.wrapperBody)}>
        <TableWrapper
          totalItems={paginationInfo ? `+${paginationInfo.count}` : '+0'}
          title='Usuarios registrados'
          table={
            <Table
              columns={columns}
              data={data}
              loading={false}
              fetchData={fetchCustomers}
              paginationInfo={paginationInfo}
            />
          }
        />
      </div>
      { openForm
        &&
        <Form
          onClose={() => setOpenForm(false)}
          labelButton={'Crear'}
          setAlert={setAlert}
        />}
      }
    </React.Fragment>
  )
}

export default MarketingCustomers;
