import HttpRequest from '../../services/http_request';
import Qs from 'qs';
import normalize from 'json-api-normalize';
import { PAGINATION } from  '../../lib/enums';

export const ListAppointments = async (page=1, search={}, sort) => {
  return new Promise( async (resolve, reject) => {
    try {
      const queryParams = Qs.stringify(
        {
          items: PAGINATION.ROWS_PER_PAGE,
          page,
          q: search,
          sort
        }, {
          encode: false
        }
      );
      const { data } = await HttpRequest({
        method: 'GET',
        url: `/admin/appointments?${queryParams}`,
      });

      resolve({
        items: normalizer(data.appointments),
        pagination: data.metadata
      });
    } catch (error) {
      reject({
        items: [],
        pagination: null
      });
    }
  });
}

const normalizer = data => normalize(data).get([
  'id',
  'startedAt',
  'duration',
  'finishedAt',
  'value',
  'paymentStatus',
  'professionalService.name',
  'customer.firstName',
  'customer.lastName',
  'professional.firstName',
  'professional.lastName'
]);
