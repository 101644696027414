import HttpRequest from '../../services/http_request';
import normalize from 'json-api-normalize';

export const listAvailableServices = async () => {
  try{
    const resp = await HttpRequest({
      method: 'GET',
      url: '/available_services'
    });

    return {
      success: true,
      data: normalizer(resp.data)
    }
  }catch(error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
}

const normalizer = data => normalize(data).get([
  'id',
  'name',
  'minRate',
  'maxRate'
]);
