export const emailValidator = email => {
  const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return regex.test(email);
};

export const passwordValidator = password => {
  return password.length >= 6;
}

export const phoneNumberValidator = phoneNumber => {
  return /\(\d{3}\)\s\d{3}\-\d{4}/.test(phoneNumber);
}
