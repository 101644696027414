import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  header: {
    background: '#FFFFFF',
    boxShadow: [[0, 1, 1, 'rgba(71, 114, 250, 0.11)']],
    height: 62,
    padding: [[0, 26]],
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2
  },
  headerWrapper: {
    alignItems: 'center',
    display: 'flex',
    maxWidth: 1440,
    margin: [[0, 'auto']],
    height: '100%'
  },
  headerTitle: {
    flex: 1,
    margin: 0,
    '& > span': {
      left: -9999999,
      position: 'absolute',
      opacity: 0
    }
  },
  headerNavigationBar: {
    '& > a': {
      color: theme.palette.primary.main,
      marginRight: 55,
      fontWeight: 500,
      textDecoration: 'none'
    }
  },
  headerMobileStoreBar: {
    display: 'flex',
    '& > a': {
      display: 'block',
      marginRight: 12
    },
    '& > a:last-child': {
      marginRight: 0
    }
  },
  main: {
    background: '#F7F8FB',
    minHeight: 'calc(100vh - 62px)',
    paddingTop: 62,
    paddingBottom: 48,
  },
  mainWrapper: {
    margin: [[0, 'auto']],
    paddingTop: 54,
    width: 480
  },
  pageTitleWrapper: {
    marginBottom: 28
  },
  pageTitle: {
    color: '#262626',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '113%',
    margin: [[0, 0, 9]],
    textAlign: 'center'
  },
  pageTitleMessage: {
    color: '#4F4F4F',
    fontSize: 18,
    textAlign: 'center',
    margin: 0,
    padding: [[0, 24]]
  },
  planInfoTitle: {
    color: '#929CB0',
    fontSize: 11,
    fontWeight: 'bold',
    letterSpacing: 0.4,
    margin: [[0, 0, 14]],
    textTransform: 'uppercase',
  },
  planInfoAttribute: {
    border: [[1, 'solid', '#26262615']],
    borderRadius: 8,
    marginBottom: 14,
    padding: [[8, 24]],
    '&:last-child': {
      marginBottom: 0
    }
  },
  planInfoAttributeLabel: {
    color: '#262626',
    fontSize: 14,
    margin: 0
  },
  planInfoAttributeValue: {
    color: '#262626',
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: 0.4,
    margin: 0,
    textTransform: 'uppercase'
  },
  paymentInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 28,
    paddingLeft: 24
  },
  paymentInfoAttribute: {
    position: 'relative',
    top: 4
  },
  paymentInfoAttributeLabel: {
    color: '#929CB0',
    fontSize: 14,
    margin: 0
  },
  paymentInfoAttributeValue: {
    color: '#929CB0',
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0
  }
}));
