import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {},
  baseRoot: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: [[0, 4, 4, 'rgba(0, 0, 0, 0.08)', '!important']],
    color: '#FFFFFF',
    flexShrink: 0,
    height: 48,
    width: 206,
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  disabled: {
    backgroundColor: ['#E7E7E7', '!important'],
    boxShadow: ['none', '!important']
  },
  outlined: {
    backgroundColor: '#FFFFFF',
    border: [[1, 'solid', theme.palette.primary.main]],
    boxShadow: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#e9f8fe',
    },
  },
  text: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#e9f8fe60',
    },
    '& $baseLabel': {
      fontSize: 16,
      fontWeight: 'bold',
      letterSpacing: '0.75px'
    }
  },
  primary: {
    backgroundColor: '#41BFF3',
    '&:hover': {
      backgroundColor: '#41BFF3',
    }
  },
  progressColor: {
    color: '#FFFFFF'
  },
  progress: {
    position: 'absolute'
  },
  hideLabel: {
    opacity: 0
  },
  disabledProgressButton: {
    boxShadow: ['0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)', '!important'],
    backgroundColor: ['#2196F3', '!important']
  },
  disabledLightStyle: {
    backgroundColor: ['#FFFFFF40', '!important'],
    color: ['#FFFFFF60', '!important'],
  },
  baseLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
  label: {},
  small: {
    height: 36,
    padding: [[0, 32]],
    width: 'auto'
  }
}));
