import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={props.size || 63} height={props.size || 23} viewBox="0 0 63 23" fill="none" {...props}>
      <path
        d="M5.003 8.71c.468-.757 1.141-1.37 2.019-1.836.878-.466 1.882-.7 3.013-.7 1.346 0 2.565.34 3.658 1.02 1.092.681 1.95 1.653 2.574 2.916.644 1.264.966 2.731.966 4.402 0 1.672-.322 3.15-.966 4.432-.624 1.263-1.482 2.245-2.574 2.945-1.093.68-2.312 1.02-3.658 1.02-1.15 0-2.155-.223-3.013-.67-.858-.467-1.531-1.08-2.019-1.837v2.274H0V1.1h5.003v7.61zm7.139 5.802c0-1.243-.351-2.215-1.053-2.915-.683-.72-1.531-1.079-2.546-1.079-.995 0-1.843.36-2.545 1.08-.683.718-1.024 1.7-1.024 2.944s.341 2.225 1.024 2.944c.702.72 1.55 1.079 2.545 1.079.995 0 1.844-.36 2.546-1.079.702-.738 1.053-1.73 1.053-2.974zM23.69 1.101v21.575h-5.003V1.1h5.003zM42.267 6.408v16.268h-5.003V20.46c-.508.72-1.2 1.302-2.078 1.75-.858.427-1.814.64-2.867.64-1.248 0-2.35-.271-3.306-.816-.956-.563-1.697-1.37-2.224-2.42-.526-1.049-.79-2.283-.79-3.702V6.408h4.974v8.833c0 1.089.283 1.934.849 2.537.565.602 1.326.904 2.282.904.975 0 1.745-.302 2.311-.904.566-.603.849-1.448.849-2.537V6.408h5.003zM60.08 14.28c0 .466-.03.952-.088 1.457H48.669c.078 1.01.4 1.788.966 2.332.585.525 1.297.788 2.135.788 1.249 0 2.117-.525 2.604-1.575H59.7a7.177 7.177 0 01-1.492 2.887c-.702.855-1.59 1.525-2.662 2.011-1.073.486-2.273.73-3.6.73-1.599 0-3.022-.341-4.27-1.021a7.271 7.271 0 01-2.926-2.916c-.703-1.263-1.054-2.74-1.054-4.431 0-1.691.342-3.168 1.024-4.432a7.27 7.27 0 012.926-2.915c1.248-.68 2.682-1.02 4.3-1.02 1.58 0 2.985.33 4.214.99a7.036 7.036 0 012.867 2.829c.702 1.224 1.054 2.653 1.054 4.285zm-5.12-1.313c0-.855-.293-1.535-.878-2.04-.585-.506-1.317-.758-2.195-.758-.838 0-1.55.242-2.135.728-.566.486-.917 1.176-1.054 2.07h6.261z"
        fill="#4772FA"
      />
      <path
        d="M57.108 2.43A2.434 2.434 0 0159.546 0h1.016A2.434 2.434 0 0163 2.43v1.012a2.434 2.434 0 01-2.438 2.43h-1.016a2.434 2.434 0 01-2.438-2.43V2.43z"
        fill="#3CD3AE"
      />
    </svg>
  )
}

export default SvgComponent
