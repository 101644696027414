export const DAYS_OF_WEEK = [
  {value: 'mon', label: 'Lunes'},
  {value: 'tue', label: 'Martes'},
  {value: 'wed', label: 'Miércoles'},
  {value: 'thu', label: 'Jueves'},
  {value: 'fry', label: 'Viernes'},
  {value: 'sat', label: 'Sábado'},
  {value: 'sun', label: 'Domingo'},
];

//TODO establecer los tipos de identificacion
export const IDENTIFICATION_TYPES = [
  {label: 'C.C', value: 'CC'},
  {label: 'C.E', value: 'CE'},
  {label: 'P.P', value: 'PP'}
];

//TODO establecer los generos
export const GENDERS = [
  {label: 'Masculino', value: 'man'},
  {label: 'Femenino', value: 'woman'},
  {label: 'Otro', value: 'other'}
];

//TODO establecer cantidad de elementos por pagina
export const PAGINATION = {
  ROWS_PER_PAGE: 10
};

//TODO establecer tipos de planes
export const PLAN_TYPES = [
  {label: 'Básico', value: 'basic'},
  {label: 'Empresarial', value: 'business'},
  {label: 'Premium', value: 'premium'},
  {label: 'Otro', value: 'other'}
];
