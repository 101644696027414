import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import _get from 'lodash.get';
import _groupBy from 'lodash.groupby';
import _map from 'lodash.map';

import CollapseWrapper from '../../shared/collapse_wrapper';
import TextOutlinedField from '../../shared/outlined_fields/text';
import SelectOutlinedField from '../../shared/outlined_fields/select';
import { IDENTIFICATION_TYPES, DAYS_OF_WEEK, GENDERS } from '../../../lib/enums';
import { currencyFormatter } from '../../../lib/utils';
import DocumentIcon from '../../shared/icons/file';

import useStyles from './__styles__';

const DetailInformation = ({ data, onChange=null }) => {
  const styles = useStyles();

  const prettyServiceRate = useCallback(rate => {
    return currencyFormatter(parseInt(rate), 'cop')
  }, [])

  return (
      Object.keys(data).length
      ?
      <React.Fragment>
        <div className={styles.root}>
          <div className={styles.mainInformation}>
            <CollapseWrapper
              header='Información personal'
              defaultExpanded={true}
              body={
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextOutlinedField
                      label='Nombres'
                      name='firstName'
                      value={data.firstName}
                      onChange={onChange}
                      error={false}
                      readOnly={!!!onChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextOutlinedField
                      label='Apellidos'
                      name='lastName'
                      value={data.lastName}
                      onChange={onChange}
                      error={false}
                      readOnly={!!!onChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextOutlinedField
                      label='Correo Electrónico'
                      name='email'
                      value={data.email}
                      onChange={onChange}
                      error={false}
                      readOnly={!!!onChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextOutlinedField
                      label='No. Teléfonico'
                      name='phone'
                      value={data.phoneNumber}
                      mask='phone'
                      onChange={onChange}
                      error={false}
                      readOnly={!!!onChange}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <SelectOutlinedField
                      label='Tipo'
                      name='identificationType'
                      value={data.identificationType}
                      onChange={onChange}
                      options={IDENTIFICATION_TYPES}
                      required={!!!onChange}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextOutlinedField
                      label='No. Documento'
                      name='identificationNumber'
                      value={data.identificationNumber}
                      onChange={onChange}
                      error={false}
                      readOnly={!!!onChange}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextOutlinedField
                      label='Tarjeta Profesional'
                      name='idCard'
                      value={data.idCard}
                      onChange={onChange}
                      error={false}
                      readOnly={!!!onChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextOutlinedField
                      label='País'
                      name='residenceCountry'
                      value={data.residenceCountry}
                      onChange={onChange}
                      error={false}
                      readOnly={!!!onChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextOutlinedField
                      label='Ciudad'
                      name='residenceCity'
                      value={data.residenceCity}
                      onChange={onChange}
                      error={false}
                      readOnly={!!!onChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextOutlinedField
                      label='Fecha de Nacimiento'
                      name='birthdate'
                      value={data.birthdate}
                      onChange={onChange}
                      error={false}
                      readOnly={!!!onChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextOutlinedField
                      label='Sexo'
                      name='gender'
                      value={_get(GENDERS.find(gender => gender.value === data.gender), 'label')}
                      onChange={onChange}
                      error={false}
                      readOnly={!!!onChange}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CollapseWrapper
              header='Información académica'
              classes={{root: styles.section}}
              body={
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextOutlinedField
                      label='Título Profesional'
                      name='professión'
                      value={data.profession}
                      onChange={onChange}
                      error={false}
                      readOnly={!!!onChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextOutlinedField
                      label='Año de Graduación'
                      name='professionGraduationYear'
                      value={data.professionGraduationYear}
                      onChange={onChange}
                      error={false}
                      readOnly={!!!onChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextOutlinedField
                      label='Especialidad'
                      name='specialty'
                      value={data.specialty}
                      onChange={onChange}
                      error={false}
                      readOnly={!!!onChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextOutlinedField
                      label='Año de Graduación'
                      name='specialtyGraduationYear'
                      value={data.specialtyGraduationYear}
                      onChange={onChange}
                      error={false}
                      readOnly={!!!onChange}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CollapseWrapper
              defaultExpanded
              header='Servicios'
              classes={{root: styles.section}}
              body={
                data.services.map(service => (
                  service.name && (
                    <Grid container spacing={3} key={service.name}>
                      <Grid item xs={2}>
                        <p className={styles.serviceName}>{service.name}</p>
                      </Grid>
                      <Grid item xs={10}>
                        <p className={styles.serviceRate}>{prettyServiceRate(service.rate)}</p>
                      </Grid>
                    </Grid>
                  )
                ))
              }
            />
            <CollapseWrapper
              header='Disponibilidad'
              classes={{
                root: styles.section,
                body: styles.sectionBody
              }}
              body={(
                <React.Fragment>
                  {
                    _map(_groupBy(data.availableTime, 'day'), (availableTime, key) => (
                      <div className={styles.abilityWrapper} key={key}>
                        <p className={styles.abilityDay}>{_get(DAYS_OF_WEEK.find(gender => gender.value === key), 'label')}</p>
                        <div className={styles.abilityHourFrameWrapper}>
                        {
                          _map(availableTime, hour => (
                            <p className={styles.abilityHourFrame} key={`${key}-${hour.from}-${hour.to}`}>
                              <span>{moment(hour.from,'HH:mm').format('hh:mm A')}</span> a <span>{moment(hour.to,'HH:mm').format('hh:mm A')}</span>
                            </p>
                          ))
                        }
                        </div>
                      </div>
                    ))
                  }
                </React.Fragment>
              )}
            />
          </div>
          <div className={styles.additionalInformation}>
            <CollapseWrapper
              header='Documentos'
              defaultExpanded={true}
              classes={{
                body: styles.sectionBody
              }}
              body={(
                <React.Fragment>
                  <div className={styles.documentWrapper}>
                    <DocumentIcon color='#99A2B5' width={18}/>
                    <div className={styles.documentDetailWrapper}>
                      <a className={styles.documentName} target='noopener' href={data.certificateOfDegree}>Acta de Grado</a>
                      <p className={styles.documentWeight}>{`${data.certificateOfDegreeFileSize} Kb`}</p>
                    </div>
                  </div>
                  <div className={styles.documentWrapper}>
                    <DocumentIcon color='#99A2B5' width={18}/>
                    <div className={styles.documentDetailWrapper}>
                      <a className={styles.documentName} target='noopener' href={data.professionalCard}>Tarjeta Profesional</a>
                      <p className={styles.documentWeight}>{`${data.professionalCardFileSize} Kb`}</p>
                    </div>
                  </div>
                  <div className={styles.documentWrapper}>
                    <DocumentIcon color='#99A2B5' width={18}/>
                    <div className={styles.documentDetailWrapper}>
                      <a className={styles.documentName} target='noopener' href={data.identificationCard}>Documento de Identidad</a>
                      <p className={styles.documentWeight}>{`${data.identificationCardFileSize} Kb`}</p>
                    </div>
                  </div>
                </React.Fragment>
              )}
            />
          </div>
        </div>
      </React.Fragment>
    :
    <p>Loading</p>
  )
}

export default DetailInformation;
