import React, { useState, useCallback, useEffect } from 'react';

import _snakeCase from 'lodash.snakecase';

import Alert from '../shared/alert';
import Button from '../shared/button';
import EmptyStatePage from '../shared/empty_state_page';
import Loading from '../shared/loading';
import Table from '../shared/table';
import TableWrapper from '../shared/table_wrapper';

import { ListAdvertisements, DestroyAdvertisement } from '../../controllers/advertisements';

import Form from './form';

import { columns } from './data_table_schema';

import AddIcon from '@material-ui/icons/Add';

const Advertisements = () => {
  const [paginationInfo, setPaginationInfo] = useState();
  const [items, setItems] = useState([]);
  const [search] = useState({});
  const [order] = useState('desc');
  const [orderBy] = useState('firstName');
  const [alert, setAlert] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const [advertisement, setAdvertisement] = useState({});
  const [label, setLabel] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback( async (page = 1) => {
    setLoading(true);
    const { items, pagination } = await ListAdvertisements(page, {...search}, `${_snakeCase(orderBy)} ${order}`);
    setItems(items)
    setPaginationInfo(pagination);
    setLoading(false);
  }, [search, order, orderBy]);

  useEffect(() => {
    fetchData();
  }, [search, order, orderBy, fetchData]);

  const handleEditAdvertisements = async rowInfo => {
    const advertisement = items.find(item => item.id === rowInfo.id);
    setAdvertisement({...advertisement, imageInfo: {name: advertisement.fileName, size: advertisement.fileSize} });
    setLabel('Guardar');
    setOpenForm(true);
  }

  const handleDeleteAdvertisements = async rowInfo => {
    const { success } = await DestroyAdvertisement(rowInfo.id);
    if(!success){
      setAlert( {message:'Esta publicidad no pudo ser borrada', type:'errors'} );
    }else{
      setAlert( {message:'Esta publicidad fue borrrada exitosamente', type:'success'} );
      fetchData();
    }
    setTimeout(() => setAlert({}), 5000);
  }

  const createAdvertisement = () => {
    setLabel('Crear');
    setAdvertisement();
    setOpenForm(true);
  }

  return (
    <div>
      {
        <div>
          <Alert type={alert.type} message={alert.message} display={!!alert.type}/>
          {
            loading
            &&
            <Loading/>
          }
          {
            !loading
            &&
            Object.keys(items).length > 0
            &&
            <TableWrapper
              totalItems={paginationInfo ? `+${paginationInfo.count}` : '+0'}
              title='Publicidades activas'
              actions={
                <Button
                  label='publicidad'
                  size='small'
                  onClick={createAdvertisement}
                  startIcon={<AddIcon />}
                />
              }
              table={
                <Table
                  columns={columns}
                  data={items}
                  loading={false}
                  fetchData={fetchData}
                  paginationInfo={paginationInfo}
                  onEdit={rowInfo => handleEditAdvertisements(rowInfo)}
                  onDelete={(rowInfo) => handleDeleteAdvertisements(rowInfo)}
                />
              }
            />
          }
          {
            !loading
            &&
            Object.keys(items).length === 0
            &&
            <EmptyStatePage
              title='No tienes Publicidades'
              message='Crea tu primera Publicidad'
              createButtonLabel='Publicidad'
              onCreate={createAdvertisement}
            />
          }
        </div>
      }
      { openForm
        &&
        <Form
          onClose={() => setOpenForm(false)}
          fetchData={fetchData}
          advertisement={advertisement}
          labelButton={label}
        />}
    </div>
  )
}

export default Advertisements;
