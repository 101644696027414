import HttpRequest from '../../services/http_request';
import normalize from 'json-api-normalize';

export const ListMarketingLevels = async () => {
  return new Promise( async (resolve, reject) => {
    try {
      const { data } = await HttpRequest({
        method: 'GET',
        url: `/admin/marketing_levels`,
      });

      resolve({
        items: normalizer(data.marketingLevels),
      });
    } catch (error) {
      reject({
        items: [],
      });
    }
  });
}

export const CreateMarketingLevel = async (params) =>{
  try{
    const resp = await HttpRequest({
      method: 'POST',
      url: '/admin/marketing_levels',
      data: params
    });

    return {
      success: true,
      resp: resp
    }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};

export const UpdateMarketingLevel = async (id, params) =>{
  try{
    const resp = await HttpRequest({
      method: 'PUT',
      url: `/admin/marketing_levels/${id}`,
      data: params
    });

    return {
      success: true,
      resp: resp
    }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};

export const DeleteMarketingLevel = async (id) =>{
  try{
    const resp = await HttpRequest({
      method: 'DELETE',
      url: `/admin/marketing_levels/${id}`,
    });

    return {
      success: true,
      resp: resp
    }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};

export const ShowMarketingLevel= async id => {
  return new Promise( async (resolve, reject) => {
    try {
      const { data } = await HttpRequest({
        method: 'GET',
        url: `/admin/marketing_levels/${id}`,
      });

      resolve({
        level: normalizer(data.marketingLevel)
      });
    } catch (error) {
      reject({
        level: null
      });
    }
  });
}

const normalizer = data => normalize(data).get([
  'id',
  'name',
  'pointsPerSuscription',
  'startPoints',
  'endPoints'
]);
