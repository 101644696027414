import React, { useState } from 'react';

//Import external libraries
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

//Import utils or services

//Import shared components, order A-Z

//Import styles
import useStyles from './__styles__';

//Import local components

//Import constants or services

const ItemCard = ({
  name,
  row,
  active=true,
  attributes=[],
  onEdit,
  onDelete,
  onActivate,
  onDeactivate,
  twoColumnsContent=false
}) => {
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className={styles.root}>
      <div className={clsx(styles.header, {[styles.inactive]: !active})}>
        <p className={styles.name}>{name}</p>
        {
          (!!onEdit || !!onDelete || !!onActivate || !!onDeactivate)
          &&
          <React.Fragment>
            <IconButton
              size='small'
              aria-haspopup="true"
              onClick={evt => {
                setAnchorEl(anchorEl ? null : evt.currentTarget);
              }}
              aria-controls={!!anchorEl ? 'menu-list-grow' : undefined}
            >
              <MoreHorizIcon color='action' classes={{
                colorAction: styles.menuIconColor
              }}/>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              {
                !!onEdit
                &&
                <MenuItem classes={{root: styles.menuItem}} onClick={rowInfo => { setAnchorEl(null); onEdit(row); }}>
                  Editar
                </MenuItem>
              }
              {
                !!onDelete
                &&
                <MenuItem classes={{root: styles.menuItem}} onClick={rowInfo => { setAnchorEl(null); onDelete(row); }}>
                  Eliminar
                </MenuItem>
              }
              {
                !!onActivate
                &&
                <MenuItem classes={{root: styles.menuItem}} onClick={rowInfo => { setAnchorEl(null); onActivate(row); }}>
                  Activar
                </MenuItem>
              }
              {
                !!onDeactivate
                &&
                <MenuItem classes={{root: styles.menuItem}} onClick={rowInfo => { setAnchorEl(null); onDeactivate(row); }}>
                  Desactivar
                </MenuItem>
              }
            </Menu>
          </React.Fragment>
        }
      </div>
      <div className={clsx(styles.content, {[styles.wrapped]: twoColumnsContent})}>
        {attributes.map(attribute => (
          <div className={clsx(styles.attribute, {[styles.oneColumn]: twoColumnsContent})} key={`${name}-${attribute.label}-${attribute.value}`}>
            <p className={styles.attributeLabel}>{attribute.label}</p>
            <p className={styles.attributeValue}>{attribute.value}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ItemCard;
