import React, { useState, useCallback, useContext }from 'react';

//Import external libraries

//Import shared components, order A-Z
import Grid from '@material-ui/core/Grid';
import Modal from '../shared/modal';
import SelectOutlinedField from '../shared/outlined_fields/select';
import TextOutlinedField from '../shared/outlined_fields/text';

import { CreateMarketingAgent, UpdateMarketingAgent } from '../../controllers/marketing_agents';

import { GlobalDataContext } from '../../controllers/global_data';

const Form = ({ onClose, fetchData, marketingAgent={ }, plans=[ ], labelButton }) => {
  const { globalData } = useContext(GlobalDataContext);
  const [errorField, setErrorField] = useState({});
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(
    {
      ...marketingAgent,
      marketingAgentToPlanIds: plans
    }
  );

  const handleSetFormData = useCallback(({target: { name, value }}) => {
    setFormData({
      ...formData,
      [name]: value
    });
  }, [formData, setFormData]);

  const canContinue = useCallback(() => {
      return formData.firstName &&
             formData.lastName &&
             formData.email &&
             !!formData.marketingAgentToPlanIds && formData.marketingAgentToPlanIds.length
  }, [formData]);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    let success = true;
    let errors = {};

    if (!!marketingAgent.id){
      const resp = await UpdateMarketingAgent(marketingAgent.id, formData);
      success = resp.success;
      errors = resp.errors;
    }else{
      const resp = await CreateMarketingAgent(formData);
      success = resp.success;
      errors = resp.errors;
    }
    setLoading(false);

    if (!success){
      return setErrorField(errors);
    }

    fetchData();
    onClose();
  }

  return (
    <Modal
      title='Nuevo Promotor'
      buttonSaveLabel={labelButton}
      disabled= {!canContinue()}
      onSubmit={handleSubmit}
      isSubmitting={loading}
      onClose={onClose}
      content={(
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextOutlinedField
              error={Boolean(errorField.firstName)}
              errorMessage={errorField.firstName}
              label='Nombres'
              name='firstName'
              value={formData.firstName}
              onChange={handleSetFormData}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextOutlinedField
              error={Boolean(errorField.lastName)}
              errorMessage={errorField.lastName}
              label='Apellidos'
              name='lastName'
              value={formData.lastName}
              onChange={handleSetFormData}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextOutlinedField
              error={Boolean(errorField.email)}
              errorMessage={errorField.email}
              label='Correo'
              name='email'
              value={formData.email}
              onChange={handleSetFormData}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextOutlinedField
              error={Boolean(errorField.phoneNumber)}
              errorMessage={errorField.phoneNumber}
              label='Teléfono'
              name='phoneNumber'
              value={formData.phoneNumber}
              onChange={handleSetFormData}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectOutlinedField
              error={Boolean(errorField.marketingAgentToPlanIds)}
              errorMessage={errorField.marketingAgentToPlanIds}
              label='Asignar Plan'
              name='marketingAgentToPlanIds'
              options={
                globalData.plans.map(plans => (
                {
                  label: plans.name, value: plans.id
                }
              ))}
              value={formData.marketingAgentToPlanIds}
              onChange={handleSetFormData}
              required={true}
              multiple
              chipped={true}
            />
          </Grid>
        </Grid>
      )}
    />
  )
}

export default Form;
