//Import external libraries
import React, { useCallback, useContext, useEffect, useState } from 'react';

//Import utils or services

//Import shared components, order A-Z
import Grid from '@material-ui/core/Grid';
import Modal from '../shared/modal';
import TextOutlinedField from '../shared/outlined_fields/text';
import SelectOutlinedField from '../shared/outlined_fields/select';

//Import controllers
import { CurrentUserContext } from '../../controllers/users/current';
import { CreateCustomer } from '../../controllers/customers';
import { ShowMarketingAgent } from '../../controllers/marketing_agents';

//Import styles

//Import constants or services
import { IDENTIFICATION_TYPES } from '../../lib/enums';

const Form = ({ onClose, onComplete, labelButton, setAlert}) => {
  const { currentUser } = useContext(CurrentUserContext);
  const [errorField, setErrorField] = useState({});
  const [formData, setFormData] = useState({});
  const [marketingAgent, setMarketingAgent] = useState({
    plans: []
  });
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState();

  const canContinue = useCallback(() => {
      return formData.firstName &&
             formData.lastName &&
             formData.phoneNumber &&
             formData.email &&
             formData.planId &&
             formData.identificationType &&
             formData.identificationNumber &&
             formData.addressState &&
             formData.residenceCity
  }, [formData]);

  const fetchOne = useCallback( async () => {
    if(userId){
      const { item } = await ShowMarketingAgent(userId);
      setMarketingAgent({...item, plans: item.plans || []});
    }
  }, [userId]);

  useEffect(() => {
    setUserId(currentUser.id);
    fetchOne();
  }, [currentUser, fetchOne]);

  const handleSetFormData = useCallback(({ target: { name, value } }) => {
    setFormData({
      ...formData,
      [name]: value
    });
  }, [formData, setFormData]);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    const { success, errors } = await CreateCustomer(formData);
    setLoading(false);

    if(!success){
      setErrorField(errors);
    }else{

      if(onComplete){
        onComplete();
      }
      setAlert({message:'El cliente fue creado exitosamente', type: 'success'});
      setTimeout(() => setAlert({}), 5000);
      onClose();
    }
  }

  return(
    <Modal
      title='Nuevo Cliente'
      buttonSaveLabel={labelButton}
      disabled= {!canContinue()}
      onSubmit={handleSubmit}
      isSubmitting={loading}
      onClose={onClose}
      content={(
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextOutlinedField
              error={Boolean(errorField.firstName)}
              errorMessage={errorField.firstName}
              label='Nombres'
              name='firstName'
              value={formData.firstName}
              onChange={handleSetFormData}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextOutlinedField
              error={Boolean(errorField.lastName)}
              errorMessage={errorField.lastName}
              label='Apellidos'
              name='lastName'
              value={formData.lastName}
              onChange={handleSetFormData}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectOutlinedField
              error={Boolean(errorField.identificationType)}
              errorMessage={errorField.identificationType}
              label='Tipo de identificación'
              name='identificationType'
              options={IDENTIFICATION_TYPES}
              value={formData.identificationType}
              onChange={handleSetFormData}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextOutlinedField
              error={Boolean(errorField.identificationNumber)}
              errorMessage={errorField.identificationNumber}
              label='Número de identificación'
              name='identificationNumber'
              value={formData.identificationNumber}
              onChange={handleSetFormData}
              required={true}
            />
          </Grid>
          <Grid item xs={4}>
            <TextOutlinedField
              error={Boolean(errorField.phoneNumber)}
              errorMessage={errorField.phoneNumber}
              label='Teléfono'
              name='phoneNumber'
              mask='cellphone'
              value={formData.phoneNumber}
              onChange={handleSetFormData}
              required={true}
            />
          </Grid>
          <Grid item xs={8}>
            <TextOutlinedField
              error={Boolean(errorField.email)}
              errorMessage={errorField.email}
              label='Correo electrónico'
              name='email'
              value={formData.email}
              onChange={handleSetFormData}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextOutlinedField
              error={Boolean(errorField.addressState)}
              errorMessage={errorField.addressState}
              label='Departamento'
              name='addressState'
              value={formData.addressState}
              onChange={handleSetFormData}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextOutlinedField
              error={Boolean(errorField.residenceCity)}
              errorMessage={errorField.residenceCity}
              label='Ciudad'
              name='residenceCity'
              value={formData.residenceCity}
              onChange={handleSetFormData}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectOutlinedField
              error={Boolean(errorField.planId)}
              errorMessage={errorField.planId}
              label='Asignar Plan'
              name='planId'
              options={
                marketingAgent.plans.map(plans => (
                {
                  label: plans.name, value: plans.id
                }
              ))}
              value={formData.planId}
              onChange={handleSetFormData}
              required={true}
            />
          </Grid>
        </Grid>
      )}
    />
  )
}

export default Form;
