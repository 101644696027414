import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  baseRoot: {
    background: '#FAFAFA',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    borderRadius: 2
  },
  header: {
    position: 'relative',
    height: 53,
    padding: [[20, 20, 16]],
  },
  headerDefaultTitle: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: 12,
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    color: '#000000'
  },
  bodyRoot: {
    borderTop: [[1.01519, 'solid', '#6E91FF20']],
    padding: [[24, 20]]
  },
  body: {},
  expandButton: {
    color: 'rgba(12,31,44,0.87)',
    position: 'absolute',
    top: 8,
    right: 10,
    padding: 8,
    margin: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}));

export default styles;
