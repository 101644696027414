import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const DefaultRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => <Component /> }
    />
  )
}

DefaultRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default DefaultRoute;
