import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.09 1.333h13.12l7.037 7.448V28c0 1.473-1.128 2.667-2.52 2.667H7.091c-1.392 0-2.52-1.194-2.52-2.667V4c0-1.473 1.128-2.667 2.52-2.667zM17.17 4H7.09v24h17.636V12h-5.039c-1.391 0-2.52-1.194-2.52-2.667V4zm2.52.552v4.781h4.517l-4.518-4.78z"
        fill={props.color || "#4772FA"}
      />
    </svg>
  )
}

export default SvgComponent
