import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#FFFFFF'
  },
  formSection: {
    borderRight: [[1, 'solid', '#6E91FF20']],
    padding: [[0, 72, 0, 110]],
    width: '42.5vw',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  formWrapperSection: {
    flex: 1,
    top: -20,
    backgroundColor: '#FFFFFF'
  },
  logo: {
    lineHeight: 1,
    margin: [[0, 0, 80]],
    '& > span': {
      opacity: 0,
      left: -9999
    }
  },
  pageTitle: {
    color: '#333333',
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: '132%',
    margin: [[0, 0, 30]]
  },
  fieldItems: {
    marginBottom: 32,
    '&:last-child': {
      marginBottom: 0
    }
  },
  forgotPasswordLink: {
    color: theme.palette.primary.main,
    display: 'block',
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 0.4,
    margin: [[4, 0, 38, 16]]
  },
  formActions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionButton: {
    height: 36,
    width: 141
  },
  actionButtonLabel: {
    fontSize: 14,
    letterSpacing: '0.75px',
    textTransform: 'uppercase'
  },
  heroSection: {
    alignItems: 'center',
    backgroundColor: '#F7F8FB',
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  },
  appMetaData: {
    position: 'absolute',
    bottom: 40
  },
  copyright: {
    fontSize: 12,
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.87)',
    opacity: 0.38,
    margin: [[0, 0, 6]]
  },
  policies: {
    display: 'flex'
  },
  policyLink: {
    color: theme.palette.primary.main,
    fontSize: 12,
    letterSpacing: '0.4px',
    textDecoration: 'none',
    margin: 0,
    '&:first-child': {
      marginRight: 20
    }
  }
}));
