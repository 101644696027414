import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles from './__styles__';

const Button = ({ classes, label, loading, light, ...props }) => {
  const styles = useStyles();
  return (
    <MuiButton
      classes={{
        root: clsx(styles.baseRoot, classes.root, {
          [styles.outlined]: props.variant === 'outlined',
          [styles.text]: props.variant === 'text',
          [styles.disabled]: props.disabled,
          [styles.small]: props.size === 'small'
        }),
        disabled: clsx({
          [styles.disabledProgressButton]: loading,
          [styles.disabledLightStyle]: props.disabled && light,
        }),
        label: clsx(styles.baseLabel, classes.label)
      }}
      disabled={loading}
      {...props}
    >
      <span className={clsx({ [styles.hideLabel]: loading })}>
        {label}
      </span>
      {
        loading
        &&
        <CircularProgress
          color="primary"
          size={24}
          classes={{
            root: styles.progress,
            colorPrimary: styles.progressColor
          }}
        />
      }
    </MuiButton>
  )
}

Button.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  light: PropTypes.bool
}

Button.defaultProps = {
  classes: {},
  loading: false,
  light: false
}

export default Button;
