import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

export class LineChart extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    borderColor: PropTypes.string.isRequired,
    pointBackgroundColor: PropTypes.string.isRequired,
    pointHoverBackgroundColor: PropTypes.string.isRequired,
    pointHoverBorderColor: PropTypes.string.isRequired
  };
  state = {
    config: {
      labels: this.props.labels,
      datasets: [
        {
          fill: 'origin',
          lineTension: 0,
          backgroundColor: this.props.backgroundColor,
          borderColor: this.props.borderColor,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBackgroundColor: this.props.pointBackgroundColor,
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: this.props.pointHoverBackgroundColor,
          pointHoverBorderColor: this.props.pointHoverBorderColor,
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 10,
          showLines: true,
          data: this.props.data
        }
      ]
    },
    redraw: false,
    options: {
      legend: {
        display: false
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              min: 0,
              precision: 0
            }
          }
        ]
      }
    },
    loading: false
  };
  render() {
    const { config, options, redraw } = this.state;
    const {
      data,
      labels,
      backgroundColor,
      borderColor,
      pointBackgroundColor,
      pointHoverBackgroundColor,
      pointHoverBorderColor
    } = this.props;
    config.datasets[0].data = data;
    config.datasets[0].backgroundColor = backgroundColor;
    config.datasets[0].borderColor = borderColor;
    config.datasets[0].pointBackgroundColor = pointBackgroundColor;
    config.datasets[0].pointHoverBackgroundColor = pointHoverBackgroundColor;
    config.datasets[0].pointHoverBorderColor = pointHoverBorderColor;
    config.labels = labels;
    return (
      <Line
        data={config}
        redraw={redraw}
        options={options}
        height={100}
        width={250}
      />
    );
  }
}

export default LineChart;
