import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh'
  },
  sidebar: {
    backgroundColor: '#1F2C56',
    paddingTop: 28,
    width: '15.8333%'
  },
  sidebarLogo: {
    marginBottom: 32,
    marginLeft: 10
  },
  sidebarNavigation: {
    display: 'flex',
    flexDirection: 'column'
  },
  sidebarNavigationItem: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '14px',
    color: '#FFFFFF',
    opacity: 0.6,
    textDecoration: 'none',
    height: 37,
    marginBottom: 12,
    '&:last-child': {
      marginBottom: 0
    },
    display: 'flex',
    alignItems: 'center',
    padding: [[0, 10]],
    '&.active': {
      backgroundColor: '#3E4D7D',
      opacity: 1
    }
  },
  sidebarNavigationItemIcon: {
    marginRight: 14,
    flexShrink: 0
  },
  main: {
    flex: 1,
    position: 'relative'
  },
  mainHeader: {
    background: '#FFFFFF',
    borderBottom: [[1.01519, 'solid', '#6E91FF20']],
    display: 'flex',
    height: 46,
    position: 'absolute',
    top: 0,
    width: '100%',
    padding: [[0, 12]],
    justifyContent: 'flex-end'
  },
  mainHeaderCurrentUser: {
    fontSize: 14,
    letterSpacing: '0.25px',
    color: '#929CB0',
    cursor: 'pointer',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      marginRight: 9
    }
  },
  mainContent: {
    minHeight: '100vh',
    overflow: 'auto',
    padding: [[72, 10, 0, 26]]
  }
}));
