import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={100} height={15} viewBox="0 0 100 15" fill="none" {...props}>
      <path
        d="M3.264 5.703c.305-.496.744-.897 1.317-1.203.572-.305 1.228-.458 1.966-.458a4.41 4.41 0 012.386.668c.712.446 1.272 1.082 1.68 1.909.42.827.63 1.788.63 2.882s-.21 2.061-.63 2.901c-.408.827-.968 1.47-1.68 1.928a4.41 4.41 0 01-2.386.668c-.751 0-1.406-.146-1.966-.439a3.544 3.544 0 01-1.317-1.202v1.488H0V.721h3.264v4.982zM7.92 9.5c0-.814-.229-1.45-.687-1.909a2.193 2.193 0 00-1.66-.706c-.65 0-1.203.236-1.661.706-.446.471-.668 1.114-.668 1.928 0 .815.222 1.457.668 1.928.458.47 1.011.706 1.66.706.65 0 1.203-.235 1.66-.706.459-.484.688-1.133.688-1.947zM15.455.721v14.124H12.19V.721h3.264zM27.573 4.195v10.65H24.31v-1.45c-.331.47-.783.852-1.355 1.145-.56.28-1.184.42-1.871.42-.814 0-1.533-.178-2.157-.535a3.73 3.73 0 01-1.45-1.584c-.344-.687-.516-1.495-.516-2.424V4.195h3.245v5.783c0 .713.184 1.266.553 1.66.37.395.866.592 1.49.592.636 0 1.138-.197 1.507-.591.37-.395.554-.948.554-1.66V4.194h3.264zM39.194 9.348c0 .306-.019.624-.057.955H31.75c.051.661.261 1.17.63 1.527.382.343.846.515 1.393.515.815 0 1.381-.344 1.7-1.03h3.473c-.178.7-.503 1.329-.974 1.889a4.737 4.737 0 01-1.736 1.317c-.7.318-1.483.477-2.348.477-1.044 0-1.972-.223-2.787-.668a4.751 4.751 0 01-1.908-1.909c-.459-.827-.688-1.794-.688-2.9 0-1.108.223-2.075.669-2.902a4.752 4.752 0 011.908-1.909c.815-.445 1.75-.668 2.806-.668 1.03 0 1.947.217 2.748.65a4.598 4.598 0 011.871 1.85c.458.802.687 1.737.687 2.806zm-3.34-.859c0-.56-.191-1.005-.573-1.336-.381-.33-.859-.496-1.431-.496-.547 0-1.012.16-1.394.477-.369.318-.598.77-.687 1.355h4.085z"
        fill="#fff"
      />
      <rect x={37.255} width={3.844} height={3.844} rx={1.541} fill="#3CD3AE" />
      <g opacity={0.5} fill="#fff">
        <path d="M51.18 11.948h-5.354l-.987 2.73h-1.69l4.438-12.206h1.85l4.42 12.206h-1.69l-.987-2.73zm-.458-1.303l-2.219-6.2-2.22 6.2h4.44zM55.487 9.817c0-.987.2-1.85.599-2.59a4.35 4.35 0 011.638-1.743c.704-.411 1.491-.617 2.36-.617.752 0 1.45.176 2.096.529.646.34 1.14.792 1.48 1.356V1.644h1.62v13.034h-1.62v-1.814c-.317.575-.787 1.051-1.41 1.427-.622.364-1.35.546-2.184.546a4.467 4.467 0 01-2.342-.634c-.693-.423-1.239-1.016-1.638-1.78-.4-.763-.6-1.632-.6-2.606zm8.173.017c0-.728-.147-1.362-.44-1.902A3.032 3.032 0 0062.021 6.7a3.143 3.143 0 00-1.638-.44c-.6 0-1.145.14-1.639.423a3.067 3.067 0 00-1.18 1.233c-.293.54-.44 1.174-.44 1.902 0 .74.147 1.386.44 1.937.294.54.687.957 1.18 1.251.494.282 1.04.423 1.639.423.598 0 1.145-.141 1.638-.423.505-.294.904-.71 1.197-1.25.294-.553.44-1.193.44-1.92zM79.89 4.85c.752 0 1.421.158 2.008.475.587.306 1.051.77 1.392 1.392.34.622.51 1.38.51 2.272v5.69h-1.585V9.217c0-.963-.24-1.697-.722-2.202-.47-.516-1.11-.775-1.92-.775-.834 0-1.497.27-1.99.81-.493.529-.74 1.298-.74 2.308v5.32h-1.585V9.217c0-.963-.241-1.697-.722-2.202-.47-.516-1.11-.775-1.92-.775-.834 0-1.498.27-1.99.81-.494.529-.74 1.298-.74 2.308v5.32h-1.603V5.025h1.602v1.391c.317-.505.74-.892 1.269-1.162a3.922 3.922 0 011.779-.405c.81 0 1.526.182 2.148.546.623.364 1.087.898 1.392 1.603.27-.681.716-1.21 1.339-1.586a3.943 3.943 0 012.078-.563zM87.551 3.458c-.305 0-.564-.106-.775-.317a1.054 1.054 0 01-.317-.775c0-.305.106-.564.317-.775.212-.211.47-.317.775-.317.294 0 .54.106.74.317.211.211.317.47.317.775 0 .305-.106.564-.317.775a.977.977 0 01-.74.317zm.775 1.568v9.652h-1.603V5.026h1.603zM96.055 4.85c1.174 0 2.125.358 2.853 1.074C99.636 6.63 100 7.65 100 8.99v5.69h-1.585V9.217c0-.963-.241-1.697-.722-2.202-.482-.516-1.14-.775-1.973-.775-.846 0-1.52.264-2.026.793-.493.528-.74 1.297-.74 2.307v5.337h-1.602V5.026h1.602V6.4a3.196 3.196 0 011.286-1.145 4.071 4.071 0 011.815-.405z" />
      </g>
    </svg>
  )
}

export default SvgComponent;
