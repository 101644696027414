import React from 'react';

import useStyles from './__styles__';

const DeviceWarning = () => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <img
          src={require('../../assets/figures/device_not_allowed.png')}
          alt='device-not-allowed'
          className={styles.image}
        />
        <p className={styles.title}>Dispositivo no soportado</p>
        <p className={styles.message}>Por favor visite <span className={styles.url}>app.portafolioblue.com</span> desde una tablet o computador</p>
      </div>
    </div>
  )
}

export default DeviceWarning;
