import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    padding: [[0, 40]]
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  image: {
    marginBottom: 42,
    width: 325
  },
  title: {
    color: '#262626',
    fontSize: 28,
    fontWeight: 'bold',
    lineHeight: '112%',
    margin: [[0, 0, 24]],
    textAlign: 'center'
  },
  message: {
    color: '#4F4F4F',
    fontSize: 16,
    lineHeight: '160.5%',
    margin: 0,
    textAlign: 'center'
  },
  url: {
    color: '#4772FA'
  }
}));
