//Import external libraries
import React, { useState, useCallback } from 'react';

//Import utils or services

//Import shared components, order A-Z
import Grid from '@material-ui/core/Grid';
import Modal from '../shared/modal';
import TextOutlinedField from '../shared/outlined_fields/text';

//Import controllers
import { CreateMarketingLevel, UpdateMarketingLevel } from '../../controllers/marketing_levels';

//Import styles

//Import constants or services

const Form = ({ onClose, fetchData, marketingLevels={ }, labelButton }) => {
  const [errorField, setErrorField] = useState({});
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(
    {
      ...marketingLevels
    }
  );

  const handleSetFormData = useCallback(({target: { name, value }}) => {
    switch (name) {

      case 'endPoints':
        value <=  formData.startPoints
        ?
        setErrorField({endPoints: 'Value must be greater'})
        :
        setErrorField({});
        break;

      case 'startPoints':
        value >=  formData.endPoints
        ?
        setErrorField({endPoints: 'Value must be greater'})
        :
        setErrorField({});
        break;
      default:
        break;
    }
    
    setFormData({
      ...formData,
      [name]: value
    });
  }, [formData, setFormData]);

  const canContinue = useCallback(() => {
    return formData.name &&
           formData.pointsPerSuscription &&
           formData.startPoints &&
           formData.endPoints
  }, [formData]);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    let success = true;
    let errors = {};

    if (!!marketingLevels.id){
      const resp = await UpdateMarketingLevel(marketingLevels.id, formData);
      success = resp.success;
      errors = resp.errors;
    }else{
      const resp = await CreateMarketingLevel(formData);
      success = resp.success;
      errors = resp.errors;
    }
    setLoading(false);

    if (!success){
      return setErrorField(errors);
    }

    fetchData();
    onClose();
  }

  return (
    <Modal
      title='Nuevo Nivel'
      buttonSaveLabel={labelButton}
      disabled= {!canContinue()}
      onSubmit={handleSubmit}
      isSubmitting={loading}
      onClose={onClose}
      content={(
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextOutlinedField
              error={Boolean(errorField.name)}
              errorMessage={errorField.name}
              label='Nombre del nivel'
              name='name'
              onChange={handleSetFormData}
              value={formData.name}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextOutlinedField
              error={Boolean(errorField.startPoints)}
              errorMessage={errorField.startPoints}
              label='Rango Desde'
              name='startPoints'
              onChange={handleSetFormData}
              type='number'
              value={formData.startPoints}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextOutlinedField
              error={Boolean(errorField.endPoints)}
              errorMessage={errorField.endPoints}
              label='Rango Hasta'
              name='endPoints'
              onChange={handleSetFormData}
              type='number'
              value={formData.endPoints}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextOutlinedField
              error={Boolean(errorField.pointsPerSuscription)}
              errorMessage={errorField.pointsPerSuscription}
              label='Punto por suscripción'
              name='pointsPerSuscription'
              onChange={handleSetFormData}
              type='number'
              value={formData.pointsPerSuscription}
              required={true}
            />
          </Grid>
        </Grid>
      )}
    />
  )
}

export default Form;
