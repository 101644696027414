export const STATUS_LABEL = {
  initialized: 'Pendiente por pagar',
  activated: 'Activo',
  cancelled: 'Cancelado',
  finished: 'Finalizado'
}

export const PAYMENT_STATUS_LABEL = {
  pending: 'Pendiente',
  paid: 'Pagado',
  declined: 'Declinado'
}
