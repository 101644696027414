export const columns = [{
   Header: 'id', //Label de la columna de la tabla
   accessor: 'id', //Attributo que se va a mostrar como el valor de la celda
 },
 {
  Header: 'Nombres', //Label de la columna de la tabla
  accessor: 'name' //Attributo que se va a mostrar como el valor de la celda
},
{
  Header: 'Inicia', //Label de la columna de la tabla
  accessor: 'startAt', //Attributo que se va a mostrar como el valor de la celda
},
{
  Header: 'Finaliza', //Label de la columna de la tabla
  accessor: 'endAt', //Attributo que se va a mostrar como el valor de la celda
},
{
  Header: 'Url', //Label de la columna de la tabla
  accessor: 'link', //Attributo que se va a mostrar como el valor de la celda
}];
