import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14.121l-6.44 6.44-2.12-2.122L9.878 12l-6.44-6.44 2.122-2.12L12 9.878l6.438-6.44 2.122 2.122L14.12 12l6.44 6.44-2.122 2.12L12 14.122z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgComponent
