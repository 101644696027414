import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={13} height={16} viewBox="0 0 13 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.929 0H12.07c.513 0 .929.448.929 1v14c0 .552-.416 1-.929 1H.93C.416 16 0 15.552 0 15V1c0-.552.416-1 .929-1zm.928 14h9.286V2H1.857v12z"
        fill="#262626"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.643 6h3.714c.513 0 .929-.448.929-1s-.416-1-.929-1H4.643c-.513 0-.929.448-.929 1s.416 1 .929 1zM4.643 10h3.714c.513 0 .929-.448.929-1s-.416-1-.929-1H4.643c-.513 0-.929.448-.929 1s.416 1 .929 1z"
        fill="#262626"
      />
    </svg>
  )
}

export default SvgComponent
