import HttpRequest from '../../services/http_request';
import normalize from 'json-api-normalize';

export const ListPlans = async () => {
  return new Promise( async (resolve, reject) => {
    try {
      const { data } = await HttpRequest({
        method: 'GET',
        url: `/admin/plans`,
      });

      resolve({
        items: normalizer(data.plans),
      });
    } catch (error) {
      reject({
        items: [],
      });
    }
  });
}

export const CreatePlan = async (params) =>{
  try{
    const resp = await HttpRequest({
      method: 'POST',
      url: '/admin/plans',
      data: params
    });

    return {
      success: true,
      resp: resp
    }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};

export const UpdatePlan = async (id, params) =>{
  try{
      const resp = await HttpRequest({
        method: 'PUT',
        url: `/admin/plans/${id}`,
        data: params
      });

      return {
        success: true,
        resp: resp
      }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};

export const DeactivatePlan = async (id) =>{
  try{
      const resp = await HttpRequest({
        method: 'DELETE',
        url: `/admin/plans/${id}/deactivate`,
      });

      return {
        success: true,
        resp: resp
      }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};

export const ActivatePlan = async (id) =>{
  try{

      const resp = await HttpRequest({
        method: 'POST',
        url: `/admin/plans/${id}/activate`,
      });

      return {
        success: true,
        resp: resp
      }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};

const normalizer = data => normalize(data).get([
  'id',
  'name',
  'rate',
  'time',
  'planType',
  'active'
]);
