import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  mainInformation: {
    flex: 1,
    marginRight: 10
  },
  additionalInformation: {
    flexShrink: 0,
    marginLeft: 10,
    width: '27.7227%'
  },
  section: {
    marginTop: 18
  },
  sectionBody: {
    padding: [[0, 0, 14]]
  },
  abilityWrapper: {
    display: 'flex',
    borderBottom: [[1, 'solid', '#6E91FF20']],
    padding: [[10, 20]],
    '&:last-child': {
      borderBottom: 0
    }
  },
  abilityDay: {
    margin: 0,
    width: 120,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
    color: '#4F4F4F'
  },
  abilityHourFrame: {
    alignItems: 'center',
    display: 'flex',
    margin: [[0, 0, 10]],
    '&:last-child': {
      margin: 0
    },
    '& > span': {
      display: 'block',
      height: 32,
      lineHeight: '32px',
      width: 89,
      backgroundColor: '#4772FA12',
      borderRadius: 16,
      color: '#4772FA',
      textAlign: 'center',
      '&:first-child': {
        marginRight: 12
      },
      '&:last-child': {
        marginLeft: 12
      }
    }
  },
  documentWrapper: {
    display: 'flex',
    padding: [[10, 20]],
    borderBottom: [[1, 'solid', '#6E91FF20']],
    '&:last-child': {
      borderBottom: 0,
      paddingBottom: 0
    }
  },
  documentDetailWrapper: {
    marginLeft: 18,
  },
  documentName: {
    margin: 0,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  documentWeight: {
    margin: 0,
    fontSize: 14,
    color: '#26262650'
  },
  serviceName: {
    fontSize: 16,
    fontWeight: 300,
    margin: 0
  },
  serviceRate: {
    fontSize: 16,
    fontWeight: 700,
    color: '#4772FA',
    margin: 0
  }
}));

export default styles;
