import { createContext } from 'react';
import { SET_CURRENT_USER, CLEAR_CURRENT_USER, AUTH_TOKEN } from './constants';
import normalize from 'json-api-normalize';
import HttpRequest from '../../services/http_request';
import lscache from 'lscache';
import { history } from '../../config/history';

export const CurrentUserContext = createContext(null);

export function setCurrentUser(currentUser) {
  return {
    type: SET_CURRENT_USER,
    data: currentUser
  };
}

export function clearCurrentUser() {
  return {
    type: CLEAR_CURRENT_USER
  };
}

export const Login = async (dispatch, credentials) => {
  try {
    const resp = await HttpRequest({
      method: 'POST',
      url: '/admin/sign_in',
      data: {
        administrator: {
          ...credentials
        }
      }
    });

    lscache.set(AUTH_TOKEN, resp.headers.authorization.split(' ')[1]);
    dispatch(setCurrentUser(normalizer(resp.data)));
    return {
      success: true
    }
  } catch (error) {
    return {
      success: false,
      errors: 'Ingrese un correo y contraseña validos por favor.'
    }
  }
};

export const Register = async (dispatch, params) => {
  try {
    const resp = await HttpRequest({
      method: 'POST',
      url: '/sign_up',
      data: {
        administrator: {
          ...params
        }
      }
    });

    lscache.set(AUTH_TOKEN, resp.headers.authorization.split(' ')[1])
    dispatch(setCurrentUser(normalizer(resp.data)));
    return {
      success: true
    }
  } catch (error) {
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};

export const Logout = async dispatch => {
  lscache.set(AUTH_TOKEN, '');
  dispatch(clearCurrentUser());
}

export const SendResetPasswordInstructions = async email => {
  try {
    await HttpRequest({
      method: 'POST',
      url: '/admin/password',
      data: {
        administrator: {
          email
        }
      }
    });
    return {
      success: true
    }
  } catch (error) {
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
}

export const VerifyAuthentication = async dispatch => {
  const authToken = lscache.get(AUTH_TOKEN);
  if (!!authToken){
    const { data } = await HttpRequest({
      method: 'GET',
      url: '/admin/user'
    });
    dispatch(setCurrentUser(normalizer(data)));
  } else {
    history.go('/sign_in');
  }
}

export const ResetPassword = async (dispatch, params) => {
  try{
    const resp = await HttpRequest({
      method: 'PUT',
      url: 'admin/password',
      data: {
        administrator: {
          ...params
        },
      }
    });
    lscache.set(AUTH_TOKEN, resp.headers.authorization.split(' ')[1])
    dispatch(setCurrentUser(normalizer(resp.data)));
    return {
      success: true
    };
  } catch (error) {
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
}

export const InvitationPassword = async (dispatch, params) => {
  try{
    const resp = await HttpRequest({
      method: 'PUT',
      url: 'admin/invitation',
      data: {
        administrator: {
          ...params
        },
      }
    });
    lscache.set(AUTH_TOKEN, resp.headers.authorization.split(' ')[1])
    dispatch(setCurrentUser(normalizer(resp.data)));
    return {
      success: true
    };
  } catch (error) {
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
}

export const initialCurrentUser = {
  loggedIn: false,
}

export default function CurrentUser(state, action){
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        loggedIn: true,
        ...action.data
      }
    case CLEAR_CURRENT_USER:
      return {
        loggedIn: false,
      }
    default:
      throw new Error();
  }
}

const normalizer = data => normalize(data).get([
  'email',
  'id',
  'firstName',
  'lastName',
  'role'
]);
