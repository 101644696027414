import React from 'react';

//Import external libraries

//Import utils or services

//Import shared components, order A-Z

//Import styles
import useStyles from './__styles__/new_customer_report';

//Import local components

//Import constants or services

const NewCustomerReport = ({ newCustomersInCurrentMonth=0 }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <p className={styles.total}>{newCustomersInCurrentMonth}</p>
      <p className={styles.label}>Clientes nuevos en <br/> el último mes</p>
    </div>
  )
}

export default NewCustomerReport;
