import React, { useState, useEffect, useReducer, useCallback, useRef } from 'react'; //useEffect
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import ReactResizeDetector from 'react-resize-detector';
import { AUTH_TOKEN } from './controllers/users/constants.js'
import lscache from 'lscache';

import theme from './config/theme';
import Routes from './config/routes';
import DeviceWarning from './components/device_warning';

import CurrentUser, { CurrentUserContext, initialCurrentUser, VerifyAuthentication } from './controllers/users/current'; //VerifyAuthentication
import GlobalData, { GlobalDataContext, initialGlobalData, FetchGlobalData } from './controllers/global_data';

const MIN_VIEPORT_WIDTH_ALLOWED = 1024;

function App() {
  const [currentUser, dispatchCurrentUser] = useReducer(CurrentUser, initialCurrentUser);
  const userStore = { currentUser: currentUser, dispatchCurrentUser: dispatchCurrentUser};

  const [globalData, dispatchGlobalData] = useReducer(GlobalData, initialGlobalData);
  const globalDataStore = { globalData, dispatchGlobalData };

  const [isMobile, setIsMobile] = useState(window.innerWidth < MIN_VIEPORT_WIDTH_ALLOWED);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleOnResize = useCallback(width => (
    setIsMobile(width < MIN_VIEPORT_WIDTH_ALLOWED)
  ), [setIsMobile]);

  useEffect(() => {
    if (!isLoaded) {
      setTimeout(() => {
        setIsLoaded(true);
      }, process.env.REACT_APP_DEFAULT_LOADING_SCREEN_TIME);
    }
    if (!currentUser.loggedIn && lscache.get(AUTH_TOKEN)) {
      VerifyAuthentication(dispatchCurrentUser);
    }

    if (currentUser.loggedIn && lscache.get(AUTH_TOKEN)){
      FetchGlobalData(dispatchGlobalData);
    }
  }, [isLoaded, setIsLoaded, currentUser]);

  return (
    <React.Fragment>
      <ReactResizeDetector handleWidth handleHeight onResize={handleOnResize}>
        {
          !isMobile
          &&
          <CurrentUserContext.Provider value={userStore} key='CurrentUserContext.Provider'>
            <GlobalDataContext.Provider value={globalDataStore}>
              <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Routes />
              </MuiThemeProvider>
            </GlobalDataContext.Provider>
          </CurrentUserContext.Provider>
        }
        {
          isMobile
          &&
          <DeviceWarning key='DeviceWarning' />
        }
      </ReactResizeDetector>
    </React.Fragment>
  );
}

export default App;
