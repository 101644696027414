import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    background: '#FFFFFF',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    borderRadius: 2,
    padding: [[30, 0]],
    width: '100%'
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    margin: [[0, 20]],
  },
  headerWithoutFilters: {
    marginBottom: 20
  },
  headerActions: {
    '& > button': {
      marginRight: 12,
      '&:last-child': {
        marginRight: 0
      }
    }
  },
  title: {
    flex: 1,
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
    opacity: '0.9',
    margin: 0
  },
  titleTotalItems: {
    color: theme.palette.primary.main
  },
  filters: {
    margin: [[28, 20, 20]],
    display: 'flex',
    '& > .MuiFormGroup-root': {
      marginRight: 24,
      maxWidth: 218,
      borderRadius: 4,
      width: '18.5714%',
      '&:last-child': {
        marginRight: 0
      }
    }
  }
}));
