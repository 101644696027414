import React from 'react';
import MUIRadio from '@material-ui/core/Radio';
import MUIRRadioGroup from '@material-ui/core/RadioGroup';
import MUIRFormControlLabel from '@material-ui/core/FormControlLabel';
import MUIRFormControl from '@material-ui/core/FormControl';
import MUIRFormLabel from '@material-ui/core/FormLabel';

import CheckedRadioIcon from '../../icons/checked_radio';
import UncheckedRadioIcon from '../../icons/unchecked_radio';

import useStyles from './__styles__';

const RadioGroup = ({options, name, label, error, value, onChange, ...props}) => {
  const styles = useStyles();

  return (
    <MUIRFormControl component="fieldset">
      {
        label
        &&
        <MUIRFormLabel
          classes={{
            root: styles.label,
            focused: styles.labelFocused
          }}
          error={error}
        >
          {label}
          {props.required && <span className={styles.required}>*</span>}
        </MUIRFormLabel>
      }
      <MUIRRadioGroup aria-label="gender" name={name} value={value} onChange={onChange} className={styles.radioGroup}>
        {
          options.map(option => (
            <MUIRFormControlLabel
              key={`${name}-${option.value}`}
              value={option.value}
              control={<MUIRadio checkedIcon={<CheckedRadioIcon />} icon={<UncheckedRadioIcon />} color='secondary'/>}
              label={option.label}
            />
          ))
        }
      </MUIRRadioGroup>
    </MUIRFormControl>
  )
}

export default RadioGroup;
