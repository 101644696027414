import React, { useState } from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import MUITextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const CellphoneMask = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    />
  );
}

const YearMask = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/]}
    />
  );
}

const LicensePlate = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[a-z]/, /[a-z]/, /[a-z]/, '-', /\d/, /\d/, /\d/]}
    />
  );
}

const BonusCode = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    />
  );
}

const PostalCode = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
    />
  );
}

const CreditCard = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      guide={false}
      placeholder='4111-1111-1111-1111'
    />
  );
}

const DefaultCVV = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[/\d/, /\d/, /\d/]}
    />
  );
}

const FourDigitsCVV = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[/\d/, /\d/, /\d/, /\d/]}
    />
  );
}

const CreditCardExpirationDate = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholder='MM/YYYY'
      guide={false}
    />
  );
}

const Money = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={createNumberMask({
        allowDecimal: false,
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        prefix: '$ ',
        suffix: ''
      })}
    />
  );
}

const AvailableMasks = {
  cellphone: CellphoneMask,
  year: YearMask,
  licensePlate: LicensePlate,
  bonusCode: BonusCode,
  postalCode: PostalCode,
  creditCard: CreditCard,
  defaultCVV: DefaultCVV,
  fourDigitsCVV: FourDigitsCVV,
  creditCardExpirationDate: CreditCardExpirationDate,
  money: Money
}

const TextField = ({
    label,
    type = 'text',
    value,
    onChange,
    mask,
    multiline=false,
    disabled,
    error,
    errorMessage,
    endAdornment=null,
    size='small',
    ...props
  }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <MUITextField
      value={value}
      label={label}
      variant="outlined"
      fullWidth={true}
      onChange={onChange}
      type={type === 'password' ? ( showPassword ? 'text' : 'password' ) : type}
      size={size}
      InputProps={{
        inputComponent: mask ? AvailableMasks[mask] : 'input',
        endAdornment: (
          endAdornment
          &&
          <InputAdornment position="end">
            {
              type === 'password'
              ?
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                { showPassword ? <VisibilityIcon /> : <VisibilityOffIcon /> }
              </IconButton>
              :
              endAdornment
            }
          </InputAdornment>
        )
      }}
      multiline={multiline}
      rows={multiline ? 2 : 1}
      disabled={disabled}
      error={Boolean(error)}
      helperText={errorMessage}
      {...props}
    />
  );
}

export default TextField;
