import React, { useState, useEffect, useCallback } from 'react';

//Import external libraries
import { useParams } from 'react-router-dom';
import _snakeCase from 'lodash.snakecase';

//Import utils or services

//Import shared components, order A-Z
import AssignedPlans from '../marketing_dashboard/assigned_plans';
import CurrentLevelInfo from '../marketing_dashboard/current_level_info';
import Loading from '../shared/loading';
import Table from '../shared/table';
import TableWrapper from '../shared/table_wrapper';

//Import controllers
import { ShowMarketingAgent } from '../../controllers/marketing_agents';
import { ListCustomers } from '../../controllers/customers';

//Import styles
import useStyles from './__styles__';

//Import local components

//Import constants or services
import { columns } from './data_table_schema';

const MarketingAgentDetail = () => {
  const [currentScore, setCurrentScore] = useState(0);
  const [id] = useState(useParams().id);
  const [marketingAgent, setMarketingAgent] = useState({
    plans: []
  });
  const [level, setLevel] = useState();
  const [loading, setLoading] = useState(true);
  const [paginationInfo, setPaginationInfo] = useState();
  const [data, setData] = useState([]);
  const [search] = useState({added_by_eq: id});
  const [order] = useState('desc');
  const [orderBy] = useState('firstName');
  const styles = useStyles();

  const fetchCustomers = useCallback (async (page = 1) =>{
    setLoading(true);
    const { items, pagination} = await ListCustomers(page, { ...search }, `${_snakeCase(orderBy)} ${order}`);
    setData(items);
    setPaginationInfo(pagination);
    setLoading(false);
  }, [search, order, orderBy]);

  const fetchOne = useCallback( async () => {
    if (id){
      const { item } = await ShowMarketingAgent(id);
      setMarketingAgent(item);

      if (item.currentMarketingScore){
        setCurrentScore(item.currentMarketingScore.score);
        setLevel(item.currentMarketingScore.marketingLevel);
      }

      fetchCustomers();
    }
  }, [id, fetchCustomers, setMarketingAgent]);

  useEffect(() => {
    fetchOne();
  }, [fetchOne]);

  return (
    <React.Fragment>
      <div className={styles.header}>
        <div className={styles.wrapperContent}>
          <div className={styles.pageInformation}>
            <p className={styles.pageTitle}>{`${marketingAgent.firstName} ${marketingAgent.lastName}`}</p>
            <p className={styles.pageSubtitle}>{marketingAgent.email}</p>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.wrapperContent}>
          <div className={styles.contentHeader}>
            <AssignedPlans
              plans={marketingAgent.plans ? marketingAgent.plans.map( plans => ({ label: plans.name })) : [ ]}
            />
            <CurrentLevelInfo
              name={level ? level.name : ''}
              pointsForNextLevel={level ? level.endPoints - currentScore : 0}
              totalPoints={level ? level.endPoints : 0}
              currentPoints={currentScore}
            />
          </div>
        </div>
        <div className={styles.wrapperBody}>
          {
            loading
            &&
            <Loading/>
          }
          {
            !loading
            &&
            <TableWrapper
              totalItems={paginationInfo ? `+${paginationInfo.count}` : '+0'}
              title='Usuarios registrados'
              table={
                <Table
                  columns={columns}
                  data={data}
                  loading={false}
                  fetchData={fetchCustomers}
                  paginationInfo={paginationInfo}
                />
              }
            />
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default MarketingAgentDetail;
