import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  header: {
    background: '#FFFFFF',
    boxShadow: [[0, 1, 1, 'rgba(71, 114, 250, 0.11)']],
    height: 62,
    padding: [[0, 26]],
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2
  },
  headerWrapper: {
    alignItems: 'center',
    display: 'flex',
    maxWidth: 1440,
    margin: [[0, 'auto']],
    height: '100%'
  },
  headerTitle: {
    flex: 1,
    margin: 0,
    '& > span': {
      left: -9999999,
      position: 'absolute',
      opacity: 0
    }
  },
  headerMobileStoreBar: {
    display: 'flex',
    '& > a': {
      display: 'block',
      marginRight: 12
    },
    '& > a:last-child': {
      marginRight: 0
    }
  },
  root: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#FFFFFF'
  },
  formSection: {
    borderRight: [[1, 'solid', '#6E91FF20']],
    padding: [[0, 72, 0, 110]],
    width: '42.5vw',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  formWrapperSection: {
    flex: 1,
    top: -20,
    backgroundColor: '#FFFFFF'
  },
  logo: {
    lineHeight: 1,
    margin: [[0, 0, 80]],
    '& > span': {
      opacity: 0,
      left: -9999
    }
  },
  pageTitle: {
    color: '#333333',
    fontSize: 20,
    fontWeight: 'bold',
    margin: [[0, 0, -5]],
    display: 'flex',
    alignItems: 'center'
  },
  fieldItems: {
    marginBottom: 28
  },
  formActions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionButton: {
    height: 36,
    width: 141
  },
  backButton: {
    width: 100
  },
  actionButtonLabel: {
    fontSize: 14,
    letterSpacing: '0.75px',
    textTransform: 'uppercase'
  },
  heroSection: {
    alignItems: 'center',
    backgroundColor: '#F7F8FB',
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  },
  appMetaData: {
    position: 'absolute',
    bottom: 40
  },
  copyright: {
    fontSize: 12,
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.87)',
    opacity: 0.38,
    margin: [[0, 0, 6]]
  },
  policies: {
    display: 'flex'
  },
  policyLink: {
    color: theme.palette.primary.main,
    fontSize: 12,
    letterSpacing: '0.4px',
    textDecoration: 'none',
    margin: 0,
    '&:first-child': {
      marginRight: 20
    }
  },
  icon: {
    position: 'relative',
    top: -2,
    marginRight: 10
  },
  pageTitleMessage: {
    fontFamily: 'Poppins',
    fontSize: 16,
    color: '#4F4F4F',
    marginBottom: 52
  },
  completedStepWrapper: {
    display: 'block',
    margin: [[48, 'auto', 0]],
    width: '51.2328%'
  },
  completedStepTitle: {
    color: '#262626',
    fontWeight: 'bold',
    fontSize: 36,
    lineHeight: '40px',
    textAlign: 'center',
    margin: [[0, 'auto', 24]],
    width: '63.4246%'
  },
  completedStepMessage: {
    fontSize: 18,
    textAlign: 'center',
    color: '#4F4F4F',
    margin: 0
  }
}));
