import React from 'react';

export const columns = [{
  Header: 'Nombres',
  accessor: 'firstName'
}, {
  Header: 'Apellidos',
  accessor: 'lastName',
}, {
  Header: 'Teléfono',
  accessor: 'phoneNumber',
}, {
  Header: 'Suscripción',
  accessor: () => '[HC] suscription',
}, {
  Header: 'Ciudad',
  accessor: 'residenceCity',
}, {
  Header: 'País',
  accessor: 'residenceCountry',
}, {
  Header: 'Pagó',
  Cell: () =>{
    return(
      <React.Fragment>
        <span className='suscription-paid'>Pagó</span>
      </React.Fragment>
    )
  }
}];
