import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';

//Import external libraries
import { NavLink } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import qs from 'qs';

//Import utils or services
import { passwordValidator } from '../../lib/validators';

import Alert from '../shared/alert';
import AppleStoreIcon from '../shared/icons/apple_store';
import Button from '../shared/button';
import CheckboxIcon from '../shared/icons/checkbox';
import LogoIcon from '../shared/icons/logo';
import PlayStoreIcon from '../shared/icons/play_store';
import TextOutlinedField from '../shared/outlined_fields/text';
import LoadingScreen from '../loading_screen';

import useStyles from './__styles__';

import { InvitationPasswordCustomer } from '../../controllers/customers';

const SetPassword = () => {
  const styles = useStyles();
  const [invitationToken, setInvitationToken] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();


  useEffect(() => {
    const { token } = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (token) {
      setLoading(false);
      setInvitationToken(token);
    } else {
      window.location = 'https://www.portafolioblue.com/';
    }
  }, [location.search]);

  const validForm = useMemo(() => {
    return passwordValidator(password) && passwordValidator(confirmPassword) && password === confirmPassword;
  }, [password, confirmPassword])

  const handleSubmit = useCallback(async evt => {
    evt.preventDefault();

    if (!validForm) {
      return false;
    }

    const params = {
      invitationToken: invitationToken,
      password: password,
      confirmPassword: confirmPassword
    }

    setIsSaving(true);
    const { success } = await InvitationPasswordCustomer(params);
    setIsSaving(false);

    if (!success) {
      setErrors({ invitation_token: 'Token invalido o este a expirado' });
      setTimeout(() => setErrors({}), 5000);
    }else{
      setCompleted(true);
    }
  }, [validForm, invitationToken, password, confirmPassword]);

  return (
    loading ? (
      <LoadingScreen />
    ) : (
      <React.Fragment>
        <header className={styles.header}>
          <Alert type={alert.type} message={alert.message} display={!!alert.type}/>
          <div className={styles.headerWrapper}>
            <h1 className={styles.headerTitle}>
              <LogoIcon />
            </h1>
            <div className={styles.headerMobileStoreBar}>
              <a href='/apple_store'><AppleStoreIcon /></a>
              <a href='/play_store'><PlayStoreIcon /></a>
            </div>
          </div>
        </header>
        <div className={styles.root}>
          {completed ? (
            <div className={styles.completedStepWrapper}>
              <img
                src={require('../../assets/figures/search_doctors.png')}
                alt='Tu registro ha sido exitoso'
                width='100%'
              />
              <p className={styles.completedStepTitle}>Su suscripción se ha completado exitosamente</p>
              <p className={styles.completedStepMessage}>Ahora puede descargar Blue App desde su tienda favorita e iniciar sesión con su correo y el password establecido.</p>
            </div>
          ) : (
            <React.Fragment>
              <Alert type='error' message={errors.invitation_token} display={errors.invitation_token}/>
              <div className={styles.formSection}>
                <div className={styles.formWrapperSection}>
                  <h3 className={styles.pageTitle}>
                    <CheckboxIcon className={styles.icon} size={32}/>
                    Te damos la bienvenida a Blue App.
                  </h3>
                  <p className={styles.pageTitleMessage}>Por favor, crea tu contraseña de acceso para Blue App.</p>
                  <form className={styles.form} onSubmit={handleSubmit}>
                    <div className={styles.formFields}>
                      <div className={styles.fieldItems}>
                        <TextOutlinedField
                          label='Contraseña'
                          name='password'
                          type='password'
                          value={password}
                          onChange={({ target: { value } }) => setPassword(value)}
                          error={!!errors.user}
                          endAdornment
                        />
                      </div>
                      <div className={styles.fieldItems}>
                        <TextOutlinedField
                          label='Confirmar contraseña'
                          name='confirmPassword'
                          type='password'
                          value={confirmPassword}
                          onChange={({ target: { value } }) => setConfirmPassword(value)}
                          error={!!errors.user}
                          endAdornment
                        />
                      </div>
                    </div>
                    <div className={styles.formActions}>
                      <Button
                        label='Siguiente'
                        type='submit'
                        classes={{
                          root: styles.actionButton,
                          label: styles.actionButtonLabel
                        }}
                        disabled={!validForm}
                        loading={isSaving}
                      />
                    </div>
                  </form>
                  <div className={styles.appMetaData}>
                    <p className={styles.copyright}>©2020 All Rights Reserved Blue.</p>
                    <div className={styles.policies}>
                      <p className={styles.policyLink}>Cookie Preferences</p>
                      <NavLink to='#' className={styles.policyLink}>Privacy and Terms</NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.heroSection}>
                <img src={require('../../assets/figures/banner_set_password.png')} alt='Forgot Password' width='79.7101%' />
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    )
  )
}

export default memo(SetPassword);
