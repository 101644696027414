import React, { useState, useEffect, useCallback } from 'react';

import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";

import Alert from '../shared/alert';
import Button from '../shared/button';
import ProfessionalDetailInformation from '../professional/detail_information';
import { ShowProfessional, HighlightProfessional, DownplayProfessional } from '../../controllers/professionals';

import CheckboxIcon from '../shared/icons/checkbox';

import useStyles from './__styles__';

const ProfessionalDetail = () => {
  const styles = useStyles();
  const [data, setData] = useState({});
  const [id] = useState(useParams().id);
  const [alert, setAlert] = useState({});
  const location = useLocation();

  const fetchOne = useCallback( async () => {

    if (id){
      const { item } = await ShowProfessional(id);
      setData(item);
    }
  }, [setData, id]);

  useEffect(() => {
    if (location.alert){
      setAlert(location.alert);
      setTimeout(() => setAlert({}), 5000);
    }
    fetchOne();
  }, [fetchOne, location]);

  const onChange = useCallback(({target: { name, value }}) => {
    setData({
      ...data,
      [name]: value
    });
  },[setData, data]);

  const updateProfessional = useCallback(async () => {
    let success = true;

    if(data.highlighted){
      success = await DownplayProfessional(id);

      if(success){
        setAlert({message:'El profesional fue destacado existosamente', type: 'success'});
        setTimeout(() => setAlert({}), 5000);
      }
    }else{
      success = await HighlightProfessional(id);

      if(success){
        setAlert({message:'El profesional fue destacado existosamente', type: 'success'});
        setTimeout(() => setAlert({}), 5000);
      }
    }
    fetchOne();
  }, [id, data.highlighted]);

  return (
    Object.keys(data).length
    ?
    <React.Fragment>
      <div className={styles.header}>
        <Alert type={alert.type} message={alert.message} display={!!alert.type}/>
        <div className={styles.professionalInformation}>
          <div className={styles.status}>
            <p className={styles.professionalName}>{ `${data.firstName} ${data.lastName}` }</p>
            {
              data.highlighted
              &&
              <CheckboxIcon className={styles.icon} />
            }
          </div>
          <p className={styles.professionalMainActivity}>{data.specialty}</p>
        </div>
        <div className={styles.headerActions}>
          <Button
            label={data.highlighted ? 'Destacado': 'Destacar'}
            size='small'
            onClick={updateProfessional}
          />
        </div>
      </div>
      <ProfessionalDetailInformation data={data} onChange={onChange}/>
    </React.Fragment>
    :
    <p>Loading</p>
  )
}

export default ProfessionalDetail;
