import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {},
  baseRoot: {
    border: [[1, 'solid', 'rgba(38,38,38,0.15)']],
    '&.Mui-error':{
      border: [[1, 'solid', 'rgba(240, 52, 52, 1)']]
    },
    borderRadius: 8,
    minHeight: 48,
    padding: [[0, 10]],
    '&:hover': {
      borderColor: 'rgba(38,38,38,1)'
    },
    '& > .MuiInputAdornment-positionStart': {
      marginRight: 4
    },
    '& > .MuiInputAdornment-positionStart > .MuiButtonBase-root': {
      padding: 8
    }
  },
  input: {
    borderRadius: 4,
    fontFamily: 'Poppins',
    fontSize: 14,
    padding: [[6, 0]],
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    },
  },
  label: {
    color: '#262626',
    fontSize: 16,
    fontWeight: 500,
    margin: [[0, 0, 4]]
  },
  focused: {
    border: [[1, 'solid', 'rgb(38,38,38)']],
  },
  required: {
    color: '#EB5757',
    marginLeft: 4
  },
  inputAdornmentPositionStart: {
    marginRight: 0
  }
}));
