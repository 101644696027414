import React, { useState } from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';

import AttachmentIcon from '../../icons/attachment';
import FileIcon from '../../icons/file';

import { fileSize } from '../../../../lib/utils';

import useStyles from './__styles__';

const DragAndDropFile = ({ label, onChange, data, typeAccept }) => {
  const styles = useStyles();
  const [file, setFile] = useState(data);

  const onDrop = acceptedFiles => {
    setFile(acceptedFiles[0]);
    onChange(acceptedFiles[0]);
  };

  const onRemove = () =>{
    setFile(null);
    onChange(null);
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: typeAccept});

  return (
    <div {...getRootProps()} className={clsx(styles.root, { [styles.selectedFile]: file, [styles.dragActive]: isDragActive })}>
      { !file && <input {...getInputProps()} /> }
      {
        !file
        &&
        <p className={styles.emptyMessage}>
          <span className={styles.name}>{label}</span>
          <br/>
          <span className={styles.clickHereLabel}><AttachmentIcon className={styles.attachmentIcon}/> Click aquí</span> o arrastra tu archivo
        </p>
      }
      {
        file
        &&
        <React.Fragment>
          <FileIcon />
          <p className={styles.fileInfo}>
            <span className={styles.name}>{file.name}</span>
            <br/>
            <span className={styles.size}>{fileSize(file.size)}</span>
          </p>
          <button onClick={() => onRemove()} className={styles.removeFile}>Eliminar</button>
        </React.Fragment>
      }
     </div>
  )
}

export default DragAndDropFile;
