import React from 'react';
import MUICheckbox from '@material-ui/core/Checkbox';
import MUIFormControlLabel from '@material-ui/core/FormControlLabel';

import CheckboxIcon from '../../icons/checkbox';

import useStyles from './__styles__';

const Checkbox = ({ label, ...props }) => {
  const styles = useStyles();

  return (
    <MUIFormControlLabel
      control={
        <MUICheckbox
          icon={<CheckboxIcon className={styles.icon} color='#DDDFE8' />}
          checkedIcon={<CheckboxIcon className={styles.icon} />}
          {...props}
        />
      }
      label={label}
      classes={{
        label: styles.label
      }}
    />
  )
}

export default Checkbox;
