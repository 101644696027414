import React from 'react';

import Chip from '@material-ui/core/Chip';
import MUITextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import useStyles from './__styles__';

const SelectField = ({ label, name, value, onChange, options = [], error, errorMessage, disabled, displayEmpty = false, multiple=false, chipped=false, size='small', required }) => {
  const styles = useStyles();

  const renderChipValue = selected => {
    return (
      <div className={styles.chips}>
        {selected.map(value => (
          <Chip
            key={value}
            label={options.find(option => option.value === value).label}
            className={styles.chip}
            classes={{ label: styles.chipLabel }}
          />
        ))}
      </div>
    )
  }

  return (
    <MUITextField
      select
      name={name}
      value={value}
      label={label}
      variant="outlined"
      fullWidth={true}
      onChange={onChange}
      error={Boolean(error)}
      helperText={errorMessage}
      disabled={disabled}
      size={size}
      required={required}
      SelectProps={{
        renderValue: multiple && chipped ? renderChipValue : null,
        multiple
      }}
    >
      {
        displayEmpty
        &&
        <MenuItem value=""><span className={styles.displayEmptyOption}>Todos</span></MenuItem>
      }
      {
        options.map( option => (
          <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>
        ))
      }
    </MUITextField>
  );
}

export default SelectField;
