import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={props.size || 16} height={props.size || 16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16A8 8 0 118 0a8 8 0 010 16zm0-2A6 6 0 108 2a6 6 0 000 12zm-2.293-2.293L8 9.414l2.293 2.293 1.414-1.414L9.414 8l2.293-2.293-1.414-1.414L8 6.586 5.707 4.293 4.293 5.707 6.586 8l-2.293 2.293 1.414 1.414z"
        fill="#E96C6C"
      />
    </svg>
  )
}

export default SvgComponent
