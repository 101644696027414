import HttpRequest from '../../services/http_request';
import Qs from 'qs';
import normalize from 'json-api-normalize';
import { PAGINATION } from  '../../lib/enums';

export const AcceptApplicant = async id => {
  try{
    const resp = await HttpRequest({
      method: 'POST',
      url: `/admin/applicants/${id}/accept`
    });

    return {
      success: true,
      resp: normalizer_professional(resp.data.professional)
    }
  }catch(error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
}

export const RejectApplicant = async id => {
  try{
    await HttpRequest({
      method: 'DELETE',
      url: `/admin/applicants/${id}/reject`
    });
    return {
      success: true
    }
  }catch(error){
    return {
      success: false
    }
  }
}

export const ShowApplicant = async id => {
  return new Promise( async (resolve, reject) => {
    try {
      const { data } = await HttpRequest({
        method: 'GET',
        url: `/admin/applicants/${id}`,
      });

      resolve({
        item: normalizer(data.applicant)
      });
    } catch (error) {
      reject({
        item: null
      });
    }
  });
}

export const ListApplicants = async (page=1, search={}, sort) => {
  return new Promise( async (resolve, reject) => {
    try {
      const queryParams = Qs.stringify(
        {
          items: PAGINATION.ROWS_PER_PAGE,
          page,
          q: search,
          sort
        }, {
          encode: false
        }
      );
      const { data } = await HttpRequest({
        method: 'GET',
        url: `/admin/applicants?${queryParams}`,
      });

      resolve({
        items: normalizer(data.applicants),
        pagination: data.metadata
      });
    } catch (error) {
      reject({
        items: [],
        pagination: null
      });
    }
  });
}

export const VerifyIdentificationApplicant = async (params) =>{
  try{
    const applicant = Qs.parse({
      email: params.email,
      identification_type: params.identificationType,
      identification_number: params.identificationNumber
    });

    const resp = await HttpRequest({
      method: 'POST',
      url: '/applicants/verify_identification',
      data: {
        applicant
      }
    });
    return {
      success: true,
      resp: resp
    }
  } catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};

export const CreateApplicant = async (params) =>{
  try{
      const formData = new FormData();
      formData.append('applicant[profile_picture]', params.profilePicture);
      formData.append('applicant[certificate_of_degree]', params.certificateOfDegree);
      formData.append('applicant[professional_card]', params.professionalCard);
      formData.append('applicant[identification_card]', params.identificationCard);
      formData.append('applicant[first_name]', params.firstName);
      formData.append('applicant[last_name]', params.lastName);
      formData.append('applicant[birthdate]', params.birthdate);
      formData.append('applicant[identification_type]', params.identificationType);
      formData.append('applicant[identification_number]', params.identificationNumber);
      formData.append('applicant[id_card]', params.idCard);
      formData.append('applicant[gender]', params.gender);
      formData.append('applicant[email]', params.email);
      formData.append('applicant[phone_number]', params.phoneNumber);
      formData.append('applicant[profession_graduation_year]', params.professionGraduationYear);
      formData.append('applicant[profession]', params.profession);
      formData.append('applicant[specialty]', params.specialty);
      formData.append('applicant[residence_city]', params.residenceCity);
      formData.append('applicant[residence_country]', params.residenceCountry);
      formData.append('applicant[how_did_you_know_about_us]', params.howDidYouKnowAboutUs);
      formData.append('applicant[description]', params.description);
      formData.append('applicant[specialty_graduation_year]', params.specialtyGraduationYear);
      formData.append('applicant[rate]', params.rate.replace(/\./g, ''));
      formData.append('applicant[accepted_terms_and_conditions]', params.acceptedTermsAndConditions);
      formData.append('applicant[service_1]', params.service1);
      formData.append('applicant[rate_service_1]', params.rateService1.replace(/\./, ''));
      formData.append('applicant[service_2]', params.service2);
      formData.append('applicant[rate_service_2]', params.rateService2.replace(/\./, ''));

      params.availableTime.forEach(val => {
        formData.append('applicant[available_time][][day]', val.day);
        formData.append('applicant[available_time][][from]', val.from);
        formData.append('applicant[available_time][][to]', val.to);
      });

      const resp = await HttpRequest({
        method: 'POST',
        url: '/professionals/register',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return {
        success: true,
        resp: resp
      }
  }catch (error){
    return {
      success: false,
      errors: error.response.data.errors
    }
  }
};

const normalizer = data => normalize(data).get([
  'id',
  'email',
  'firstName',
  'lastName',
  'identificationNumber',
  'phoneNumber',
  'identificationType',
  'idCard',
  'residenceCountry',
  'residenceCity',
  'birthdate',
  'gender',
  'profession',
  'professionGraduationYear',
  'specialty',
  'specialtyGraduationYear',
  'availableTime',
  'profilePicture',
  'certificateOfDegree',
  'professionalCard',
  'identificationCard',
  'howDidYouKnowAboutUs',
  'description',
  'acceptedTermsAndConditions',
  'rate',
  'state',
  'certificateOfDegreeFileName',
  'certificateOfDegreeFileSize',
  'professionalCardFileName',
  'professionalCardFileSize',
  'identificationCardFileName',
  'identificationCardFileSize',
  'services'
]);

const normalizer_professional = data => normalize(data).get([
  'id',
  'email',
  'firstName',
  'lastName',
]);
