import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  header: {
    background: '#FFFFFF',
    boxShadow: [[0, 1, 1, 'rgba(71, 114, 250, 0.11)']],
    height: 62,
    padding: [[0, 26]],
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2
  },
  headerWrapper: {
    alignItems: 'center',
    display: 'flex',
    maxWidth: 1440,
    margin: [[0, 'auto']],
    height: '100%'
  },
  headerTitle: {
    flex: 1,
    margin: 0,
    '& > span': {
      left: -9999999,
      position: 'absolute',
      opacity: 0
    }
  },
  main: {
    background: '#F7F8FB',
    minHeight: 'calc(100vh - 62px)',
    paddingTop: 62,
    paddingBottom: 48,
  },
  headerNavigationBar: {
    '& > a': {
      color: theme.palette.primary.main,
      marginRight: 55,
      fontWeight: 500,
      textDecoration: 'none'
    }
  },
  headerMobileStoreBar: {
    display: 'flex',
    '& > a': {
      display: 'block',
      marginRight: 12
    },
    '& > a:last-child': {
      marginRight: 0
    }
  },
  mainWrapper: {
    margin: [[0, 'auto']],
    paddingTop: 54,
    width: '65.1041vw'
  },
  pageTitleWrapper: {
    marginBottom: 28
  },
  pageTitle: {
    color: '#4772FA',
    fontWeight: 'bold',
    fontSize: 36,
    lineHeight: '54px',
    margin: 0,
    textAlign: 'center'
  },
  pageTitleMessage: {
    color: '#4F4F4F',
    fontSize: 18,
    textAlign: 'center',
    margin: 0
  },
  stepList: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 36
  },
  stepListItem: {
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    border: [[1, 'solid', 'rgba(38,38,38,0.15)']],
    borderRadius: 8,
    display: 'flex',
    height: 56,
    marginRight: 8,
    padding: [[0, 14]],
    width: 160,
    '&:last-child': {
      marginRight: 0
    },
    '& > p': {
      alignItems: 'center',
      color: '#262626',
      display: 'flex',
      fontWeight: 500,
      fontSize: 16,
      margin: 0
    },
    '& > p > .stepListItemIcon': {
      marginRight: 12
    }
  },
  stepListItemActive: {
    backgroundColor: 'transparent',
    border: [[2, 'solid', theme.palette.secondary.main]],
    '& > p': {
      color: theme.palette.secondary.main
    },
    '& > p > .stepListItemIcon path': {
      fill: theme.palette.secondary.main
    }
  },
  stepListItemCompleted: {
    backgroundColor: theme.palette.secondary.main,
    border: [[1, 'solid', '#3CD3AE']],
    '& > p': {
      color: '#FFFFFF'
    },
    '& > p > .stepListItemIcon path': {
      fill: '#FFFFFF'
    }
  },
  stepContent: {
    position: 'relative',
    marginBottom: 70
  },
  backButton: {
    color: '#999FB4',
    fontSize: 14,
    textTransform: 'capitalize',
    position: 'relative',
    marginBottom: 14,
    marginLeft: -6,
  },
  formGroup: {
    marginBottom: 46
  },
  formGroupTitle: {
    color: 'rgba(38,38,38,0.3)',
    fontSize: 14,
    fontWeight: 'bold',
    margin: [[0, 0, 6]],
    textTransform: 'uppercase'
  },
  formGroupTitleMessage: {
    color: '#4F4F4F',
    fontSize: 15,
    margin: [[-6, 0, 30]],
  },
  availableTime: {
    backgroundColor: '#FFFFFF',
    border: [[1, 'dashed', '#4772FA']],
    boxSizing: 'border-box',
    borderRadius: 8,
    position: 'relative',
    marginBottom: [24, '!important']
  },
  removeAvailableTime: {
    position: 'absolute',
    right: -56,
    top: '50%',
    transform: 'translateY(-50%)'
  },
  addAvailableTime: {
    position: 'absolute',
    right: -56,
    top: '60%',
    transform: 'translateY(-50%)'
  },
  addAvailableTimeButton: {
    marginTop: 12
  },
  addAvailableTimeButtonColor: {
    backgroundColor: '#E7ECFF',
    '&:hover': {
      backgroundColor: '#DDE2F5',
    }
  },
  addAvailableTimeButtonLabel: {
    color: '#4772FA',
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
  addAvailableTimeButtonLabelDisabled: {
    color: 'rgba(0, 0, 0, 0.26)'
  },
  addAvailableTimeButtonIcon: {
    marginLeft: -4
  },
  formActions: {
    display: 'flex',
    justifyContent: 'center'
  },
  documentsSection: {
    margin: [[0, 'auto']],
    width: '79.7260%'
  },
  openTermsAndConditions: {
    color: '#4F4F4F',
    fontWeight: 'bold',
    padding: 0,
    background: 'transparent',
    border: 0,
    textDecorationLine: 'underline',
    fontSize: 14,
    cursor: 'pointer'
  },
  completedStepWrapper: {
    display: 'block',
    margin: [[0, 'auto']],
    width: '71.2328%'
  },
  completedStepTitle: {
    color: '#262626',
    fontWeight: 'bold',
    fontSize: 36,
    lineHeight: '40px',
    textAlign: 'center',
    margin: [[0, 'auto', 24]],
    width: '63.4246%'
  },
  completedStepMessage: {
    fontSize: 18,
    textAlign: 'center',
    color: '#4F4F4F',
    margin: 0
  },
  formGroupContent: {
    position: 'relative'
  }
}));
