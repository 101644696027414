import React, { useEffect, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import clsx from 'clsx';

import CircularProgress from '@material-ui/core/CircularProgress';

import EditIcon from '../icons/edit';
import DeleteIcon from '../icons/delete';

import useStyles from './__styles__';

const Table = ({
  columns,
  data,
  loading,
  fetchData=null,
  onRowClick=null,
  onEdit=null,
  onDelete=null,
  customRowAction=null,
  paginationInfo={
    count: 0,
    page: 1,
    last: 0,
    pages: 0
  }}) => {
  const styles = useStyles();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    // Get the state from the instance
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: paginationInfo.page - 1 }, // Pass our hoisted table state
      manualPagination: true,
      pageCount: paginationInfo.pages,
    },
    usePagination
  )
  const [pageScrollPosition, setPageScrollPosition] = useState(0);

  useEffect(() => {
    if (fetchData && pageIndex !== paginationInfo.page - 1) {
      fetchData(pageIndex + 1);
    }
  }, [fetchData, pageIndex, paginationInfo.page]);

  const handlePreviousPage = () => {
    if (pageScrollPosition === 0) { return false }
    setPageScrollPosition(pageScrollPosition - 1);
  }

  const handleNextPage = () => {
    if (5 + pageScrollPosition === pageCount) { return false }
    setPageScrollPosition(pageScrollPosition + 1);
  }

  return (
    <React.Fragment>
      <div className={styles.tableWrapper}>
        <table {...getTableProps()} className={styles.table} cellSpacing="0" cellPadding="0">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  headerGroup.headers.map(column => {
                    return (
                      <th {...column.getHeaderProps()} className={styles.th}>
                        <div className={styles.thContent}>
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                        </div>
                      </th>
                    )
                  })
                }
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  className={clsx(styles.tr, {[styles.triggerEvent]: onRowClick})}
                  onClick={onRowClick ? () => onRowClick(row.original) : null}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, index, cells) => {
                    return <td {...cell.getCellProps()} className={styles.td}>
                      <div className={clsx(styles.tdContent, 'table-cell')}>
                        {cell.render('Cell')}
                      </div>
                      {
                        index === (cells.length - 1) && (onEdit || onDelete || customRowAction)
                        &&
                        (
                          <div className={styles.trActions}>
                            {
                              onEdit
                              &&
                              (
                                <button
                                  onClick={evt => {
                                    evt.stopPropagation();
                                    onEdit(row.values);
                                  }}
                                ><EditIcon width={16}/></button>
                              )
                            }
                            {
                              onDelete
                              &&
                              (
                                <button
                                  onClick={evt => {
                                    evt.stopPropagation();
                                    onDelete(row.values);
                                  }}
                                ><DeleteIcon /></button>
                              )
                            }
                            {customRowAction ? customRowAction(row) : null}
                          </div>
                        )
                      }
                    </td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        {
          loading && <div className={styles.loadingState}><CircularProgress size={32} /></div>
        }
      </div>
      {
        !!pageCount
        &&
        <div className={styles.pagination}>
          {
            pageCount > 5
            &&
            (
              <button onClick={handlePreviousPage} className={clsx('nav-button', {'can-not-navigate': pageScrollPosition === 0})}>
                Atras
              </button>
            )
          }
          {
            pageCount > 1
            &&
            <React.Fragment>
              <button onClick={() => gotoPage(pageScrollPosition)} className={clsx({'active': (1 + pageScrollPosition - 1) === pageIndex})}>
                {1 + pageScrollPosition}
              </button>
              <button onClick={() => gotoPage(1 + pageScrollPosition)} className={clsx({'active': (2 + pageScrollPosition - 1) === pageIndex})}>
                {2 + pageScrollPosition}
              </button>
            </React.Fragment>
          }
          {
            pageCount >= 3
            &&
            <button onClick={() => gotoPage(2 + pageScrollPosition)} className={clsx({'active': (3 + pageScrollPosition - 1) === pageIndex})}>
              {3 + pageScrollPosition}
            </button>
          }
          {
            pageCount >= 4
            &&
            <button onClick={() => gotoPage(3 + pageScrollPosition)} className={clsx({'active': (4 + pageScrollPosition - 1) === pageIndex})}>
              {4 + pageScrollPosition}
            </button>
          }
          {
            pageCount >= 5
            &&
            <button onClick={() => gotoPage(4 + pageScrollPosition)} className={clsx({'active': (5 + pageScrollPosition - 1) === pageIndex})}>
              {5 + pageScrollPosition}
            </button>
          }
          {
            pageCount > 5
            &&
            (
              <button onClick={handleNextPage} className={clsx('nav-button', {'can-not-navigate': pageScrollPosition + 5 === pageCount})}>
                Siguiente
              </button>
            )
          }
        </div>
      }
    </React.Fragment>
  )
}

export default Table;
