import React from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import TimeIcon from '../../icons/time';

import TextField from '../text';

import useStyles from './__styles__';

const DateField = ({ name, label, autoOk=true, value = null, onChange, onClose, onOpen, disabled, minDate, maxDate, error, errorMessage, initialFocusedDate = null, required, readOnly = false, onError }) => {
  const styles = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardTimePicker
        readOnly={readOnly}
        disabled={disabled}
        autoOk={autoOk}
        variant="inline"
        ampm={true}
        name={name}
        label={label}
        value={value}
        placeholder='12:00 AM'
        keyboardIcon={<TimeIcon />}
        InputAdornmentProps={{
          position: 'start'
        }}
        classes={{
          root: styles.root
        }}
        onChange={date => {
          if (!date) { return null }
          return onChange(date.format('HH:mm'))
        }}
        required
        TextFieldComponent={TextField}
        error={Boolean(error)}
        onError={onError}
      />
    </MuiPickersUtilsProvider>
  );
}

DateField.propTypes = {
  autoOk: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func
}

export default DateField;
