import React from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MUIFormHelperText from '@material-ui/core/FormHelperText';
import CalendarIcon from '../../icons/calendar';
import moment from 'moment';

import TextField from '../text';

import useStyles from './__styles__';

const DateField = ({ label, autoOk=true, value = null, disableToolbar = true, onChange, onClose, onOpen, disabled, minDate, maxDate, error, errorMessage, required, initialFocusedDate = null }) => {
  const styles = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        autoOk={autoOk}
        disableToolbar={disableToolbar}
        variant="inline"
        format="YYYY-MM-DD"
        placeholder="YYYY-MM-DD"
        label={label}
        value={value}
        onChange={(date, value) => onChange(value)}
        onClose={onClose}
        onOpen={onOpen}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        inputVariant={'outlined'}
        classes={{
          root: styles.root
        }}
        error={Boolean(error)}
        initialFocusedDate={moment().format()}
        keyboardIcon={<CalendarIcon />}
        InputAdornmentProps={{
          position: 'start'
        }}
        required={required}
        TextFieldComponent={TextField}
      />
      {errorMessage && <MUIFormHelperText error>{errorMessage}</MUIFormHelperText>}
    </MuiPickersUtilsProvider>
  );
}

DateField.propTypes = {
  autoOk: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func
}

export default DateField;
