import React from 'react';
import { PAYMENT_STATUS_LABEL } from '../../config/mappings';
import ShieldLockIcon from '../shared/icons/shield-lock';
import moment from 'moment';
import { secondsToString, currencyFormatter } from '../../lib/utils';

export const columns = [{
   Header: 'id', //Label de la columna de la tabla
   accessor: 'id', //Attributo que se va a mostrar como el valor de la celda
 },{
  Header: 'Inicio', //Label de la columna de la tabla
  accessor: 'startedAt', //Attributo que se va a mostrar como el valor de la celda
  Cell: ({ value }) => {
    return moment(value).format('MMM DD, YYYY hh:mm A')
  }
}, {
  Header: 'Finilización', //Label de la columna de la tabla
  accessor: 'finishedAt', //Attributo que se va a mostrar como el valor de la celda
  Cell: ({ value }) => {
    return moment(value).format('MMM DD, YYYY hh:mm A')
  }
}, {
  Header: 'Duración', //Label de la columna de la tabla
  accessor: 'duration', //Attributo que se va a mostrar como el valor de la celda
  Cell: ({ value }) => secondsToString(value)
}, {
  Header: 'Valor', //Label de la columna de la tabla
  accessor: 'value',
  Cell: ({ value }) => currencyFormatter(value, 'cop')
},
{
  Header: 'Servicio',
  accessor: 'professionalService.name',
},
{
  Header: 'Cliente', //Label de la columna de la tabla
  accessor: 'customer', //Attributo que se va a mostrar como el valor de la celda
  Cell: ({ value: { firstName, lastName } }) => {
    return `${firstName} ${lastName}`
  }
},
{
  Header: 'Profesional',
  accessor: 'professional',
  Cell: ({ value: { firstName, lastName } }) => {
    return `${firstName} ${lastName}`
  }
},
{
  Header: 'Estado',
  accessor: 'paymentStatus',
  Cell: ({ value }) => {
    return (
      <span className={`payment-status payment-status--${value}`}>
        {PAYMENT_STATUS_LABEL[value]}
      </span>
    )
  }
}];
