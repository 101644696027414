import React, { useState, memo } from 'react';
import MUIFormGroup from '@material-ui/core/FormGroup';
import MUIInput from '@material-ui/core/Input';
import MUIInputLabel from '@material-ui/core/InputLabel';
import MUIFormHelperText from '@material-ui/core/FormHelperText';
import MaskedInput from 'react-text-mask';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import _omit from 'lodash/omit';
import clsx from 'clsx';

import useStyles from './__styles__';

const CellphoneMask = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    />
  );
}

const YearMask = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/]}
    />
  );
}

const LicensePlate = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[a-z]/, /[a-z]/, /[a-z]/, '-', /\d/, /\d/, /\d/]}
    />
  );
}

const BonusCode = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    />
  );
}

const PostalCode = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
    />
  );
}

const CreditCard = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      guide={false}
      placeholder='4111-1111-1111-1111'
    />
  );
}

const DefaultCVV = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[/\d/, /\d/, /\d/]}
    />
  );
}

const FourDigitsCVV = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[/\d/, /\d/, /\d/, /\d/]}
    />
  );
}

const CreditCardExpirationDate = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholder='MM/YYYY'
      guide={false}
    />
  );
}

const AvailableMasks = {
  cellphone: CellphoneMask,
  year: YearMask,
  licensePlate: LicensePlate,
  bonusCode: BonusCode,
  postalCode: PostalCode,
  creditCard: CreditCard,
  defaultCVV: DefaultCVV,
  fourDigitsCVV: FourDigitsCVV,
  creditCardExpirationDate: CreditCardExpirationDate
}


const startAdornment = props => {
  if (props.InputProps && props.InputProps.startAdornment) {
    return props.InputProps.startAdornment
  }
  if (props.startAdornment) {
    return (
      <InputAdornment
        position="start"
      >
        {
          props.startAdornment || props.InputProps.startAdornment
        }
      </InputAdornment>
    )
  }
}

const TextField = memo(({ label, type = 'text', value, onChange, mask, multiline = false, disabled, error, errorMessage, endAdornment = null, classes = {}, helperText, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const styles = useStyles();
  const secureProps = _omit(props, ['InputProps', 'helperText']);

  return (
    <MUIFormGroup>
      {
        label
        &&
        <MUIInputLabel
          classes={{
            root: styles.label
          }}
          error={error}
        >
          {label}
          {props.required && <span className={styles.required}>*</span>}
        </MUIInputLabel>
      }
      <MUIInput
        classes={{
          root: clsx(styles.baseRoot, classes.root),
          focused: styles.focused,
          input: styles.input,
        }}
        disableUnderline={true}
        value={value}
        fullWidth={true}
        onChange={onChange}
        type={type === 'password' ? ( showPassword ? 'text' : 'password' ) : type}
        inputComponent={mask ? AvailableMasks[mask] : 'input'}
        endAdornment={
          endAdornment
          &&
          <InputAdornment position="end">
            {
              type === 'password'
              ?
              <div onClick={() => setShowPassword(!showPassword)}>
                { showPassword ? <VisibilityIcon /> : <VisibilityOffIcon /> }
              </div>
              :
              endAdornment
            }
          </InputAdornment>
        }
        startAdornment={startAdornment(props)}
        multiline={multiline}
        rows={multiline ? 2 : 1}
        disabled={disabled}
        error={Boolean(error)}
        {...secureProps}
      />
      {errorMessage && <MUIFormHelperText error>{errorMessage}</MUIFormHelperText>}
      {helperText && <MUIFormHelperText>{helperText}</MUIFormHelperText>}
    </MUIFormGroup>
  );
});

export default TextField;
