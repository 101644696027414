import React, { useState, useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import Alert from '../shared/alert';
import Button from '../shared/button';
import LogoIcon from '../shared/icons/logo';
import TextOutlinedField from '../shared/outlined_fields/text';

import { emailValidator } from '../../lib/validators';

import useStyles from './__styles__';

import { SendResetPasswordInstructions } from '../../controllers/users/current';

const ForgotPassword = () => {
  const styles = useStyles();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({});
  const [saving, setSaving] = useState(false);

  const canContinue = useMemo(() => {
    return emailValidator(email);
  }, [email]);

  const handleSubmit = useCallback(async (evt) => {
    evt.preventDefault();

    if (!canContinue) {
      return false;
    }

    setSaving(true);
    const { success } = await SendResetPasswordInstructions(email);
    setSaving(false);

    if (success) {
      setAlert({ message: "Se ha enviado un correo con las instrucciones para restablecer su contraseña", type: 'success' });
    } else {
      setErrors({ email: 'Ingrese un correo valido por favor.' });
      setAlert({ message: 'Ingrese un correo valido por favor.', type: 'error' });
    }
    setTimeout(() => setAlert({}), 5000);
  }, [canContinue, email]);

  return (
    <div className={styles.root}>
      <Alert type={alert.type} message={alert.message} display={!!alert.type}/>
      <div className={styles.formSection}>
        <div className={styles.formWrapperSection}>
          <h1 className={styles.logo}>
            <LogoIcon height={29} width={80}/>
            <span>Blue App</span>
          </h1>
          <h2 className={styles.pageTitle}>¿Olvidó su contraseña?</h2>
          <p className={styles.pageMessage}>Ingresa el email asociado a tu cuenta.</p>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formFields}>
              <div className={styles.fieldItems}>
                <TextOutlinedField
                  label='Tu email'
                  name='email'
                  type='email'
                  value={email}
                  onChange={({ target: { value } }) => setEmail(value)}
                  error={!!errors.user}
                />
              </div>
            </div>
            <div className={styles.formActions}>
              <Button
                variant='text'
                label='Atras'
                component={NavLink}
                to='/sign_in'
                classes={{
                  root: clsx(styles.actionButton, styles.backButton),
                  label: styles.actionButtonLabel
                }}
              />
              <Button
                label='Siguiente'
                type='submit'
                classes={{
                  root: styles.actionButton,
                  label: styles.actionButtonLabel
                }}
                disabled={!canContinue}
                loading={saving}
              />
            </div>
          </form>
          <div className={styles.appMetaData}>
            <p className={styles.copyright}>©2020 All Rights Reserved Blue.</p>
            <div className={styles.policies}>
              <p className={styles.policyLink}>Cookie Preferences</p>
              <NavLink to='#' className={styles.policyLink}>Privacy and Terms</NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.heroSection}>
        <img src={require('../../assets/figures/banner_forgot_password.png')} alt='Forgot Password' width='79.7101%'/>
      </div>
    </div>
  )
}

export default ForgotPassword;
