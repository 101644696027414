import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 18a2 2 0 01-2 2H3a2 2 0 01-2-2v-3.112l.5-.289A2.998 2.998 0 003 12a2.998 2.998 0 00-1.5-2.6L1 9.113V6a2 2 0 012-2h18a2 2 0 012 2v3.112l-.5.289A2.998 2.998 0 0021 12c0 1.084.579 2.066 1.5 2.6l.5.288V18zm-4-6c0-1.6.759-3.07 2-4V6h-6a1 1 0 11-2 0H3v2c1.241.93 2 2.4 2 4s-.759 3.07-2 4v2h10a1 1 0 112 0h6v-2c-1.241-.93-2-2.4-2-4zm-5 4a1 1 0 100-2 1 1 0 000 2zm1-4a1 1 0 11-2 0 1 1 0 012 0zm-1-2a1 1 0 100-2 1 1 0 000 2z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

export default SvgComponent;
