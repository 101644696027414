import React from 'react';
import { STATUS_LABEL } from '../../config/mappings';
import ShieldLockIcon from '../shared/icons/shield-lock';

export const columns = [{
   Header: 'id', //Label de la columna de la tabla
   accessor: 'id', //Attributo que se va a mostrar como el valor de la celda
 },{
  Header: 'Nombres', //Label de la columna de la tabla
  accessor: 'firstName' //Attributo que se va a mostrar como el valor de la celda
}, {
  Header: 'Apellidos', //Label de la columna de la tabla
  accessor: 'lastName', //Attributo que se va a mostrar como el valor de la celda
}, {
  Header: 'Teléfono', //Label de la columna de la tabla
  accessor: 'phoneNumber', //Attributo que se va a mostrar como el valor de la celda
}, {
  Header: 'Suscripción', //Label de la columna de la tabla
  accessor: '',
  Cell: ({ row: { original: { activeAgreement, initializedAgreement } } }) => {
    return (activeAgreement || initializedAgreement)?.agreementtable?.name || '';
  }
},
{
  Header: 'Departamento',
  accessor: '',
  Cell: ({ row: { original: { customerAdditionalInfo: { info } } } }) => {
    return info?.addressState || '';
  }
},
{
  Header: 'Ciudad', //Label de la columna de la tabla
  accessor: 'residenceCity', //Attributo que se va a mostrar como el valor de la celda
},
{
  Header: 'Estado de la cuenta',
  accessor: '',
  Cell: ({ row: { original: { initializedAgreement, activeAgreement } } }) => {
    const status = (activeAgreement || initializedAgreement)?.status;
    return (
      <span className={`suscription-paid suscription-paid--${status}`}>
        {STATUS_LABEL[status]}
      </span>
    )
  }
},
{
  Header: 'Estado',
  accessor: 'active',
  Cell: ({ value: active }) => {
    return active ? '' : <ShieldLockIcon width={20} height={20} fill="#F64F4F" />
  }
}];
