import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  root: {
    background: '#FFFFFF',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    borderRadius: 2,
    padding: [[36, 24]]
  },
  total: {
    fontSize: 44,
    lineHeight: 1,
    fontWeight: 'bold',
    letterSpacing: 0.4,
    color: '#4772FA',
    margin: [[0, 0, 12]]
  },
  label: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '18px',
    letterSpacing: 0.15,
    margin: 0
  }
}));

export default styles;
