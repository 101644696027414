import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  wrapperContent: {
    display: 'flex',
    margin: [[0, 'auto']],
    width: '81.6666%'
  },
  wrapperBody: {
    flexDirection: 'column',
    '& .table-cell': {
      justifyContent: 'space-between'
    },
  },
  header: {
    borderBottom: [[1, 'solid', '#929CB020']],
    display: 'flex',
    marginBottom: 22,
    paddingBottom: 16,
    paddingTop: 24
  },
  headerActions: {
    position: 'relative',
    top: 4,
    '& > button': {
      marginRight: 10
    },
    '& > button:last-child': {
      marginRight: 0
    }
  },
  pageInformation: {
    flex: 1,
    paddingLeft: 20
  },
  pageTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
    opacity: 0.9,
    margin: [[0, 0, 8]]
  },
  pageSubtitle: {
    color: '#4F4F4F',
    fontSize: 16,
    margin: 0
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
    paddingLeft: 20,
    paddingRight: 110,
    width: '100%'
  },
  reports: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 24
  },
  graphReport: {
    width: 'calc(66.6666% - 12px)'
  },
  newCustomerReport: {
    width: 'calc(33.3333% - 12px)'
  }
}));

export default styles;
