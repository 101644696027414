import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  header: {
    display: 'flex',
    marginTop: 10
  },
  wrapperContent: {
    padding: [[0, 40, 0, 14]],
    width: '100%'
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
    paddingRight: 70
  },
  wrapperBody: {
    width: '100%',

    '& .table-cell': {
      justifyContent: 'space-between'
    },
    '& .table-cell > .suscription-paid': {
      background: '#4772FA',
      borderRadius: 16,
      color: '#FFFFFF',
      fontSize: 12,
      height: 20,
      lineHeight: '20px',
      padding: [[0, 10]]
    }
  },
  pageInformation: {
    marginBottom: 22,
    flex: 1
  },
  pageTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
    opacity: 0.9,
    margin: [[0, 0, 8]]
  },
  pageSubtitle: {
    color: '#4F4F4F',
    fontSize: 16,
    margin: 0
  }
}));

export default styles;
