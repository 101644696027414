import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 72px)',
    paddingBottom: 72,
    paddingRight: 16
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: 254
  },
  title: {
    color: '#000000',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    margin: [[0, 0, 8]],
    opacity: 0.9,
    textAlign: 'center'
  },
  message: {
    color: '#4F4F4F',
    fontSize: 18,
    lineHeight: '27px',
    margin: [[0, 0, 20]],
  },
  button: {
    textTransform: 'uppercase'
  }
}));
