import React, { useState, useEffect, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MuiButton from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import _remove from 'lodash.remove';

import TextField from '../../shared/fields/text';
import CurrencyField from '../../shared/fields/currency';
import SelectField from '../../shared/fields/select';
import DateField from '../../shared/fields/date';
import TimeField from '../../shared/fields/time';
import RadioGroupField from '../../shared/fields/radio_group';
import SmallCloseIcon from '../../shared/icons/small_close';
import CheckboxIcon from '../../shared/icons/checkbox';

import { currencyFormatter } from '../../../lib/utils';

import { IDENTIFICATION_TYPES, DAYS_OF_WEEK, GENDERS } from '../../../lib/enums';
import { listAvailableServices } from '../../../controllers/available_services';

import useStyles from '../__styles__';

const Information = ({ data, onChangeData, errors, setErrors, onValidateStep }) => {
  const styles = useStyles();
  const [loaded, setLoaded] = useState(false);
  const [availableTime, setAvailableTime] = useState({
    day: '',
    from: null,
    to: null
  });
  const [availableServices, setAvailableServices] = useState([]);
  const [service1, setService1] = useState();
  const [service2, setService2] = useState();

  useEffect(() => {
    const fetch = async () => {
      const { data } = await listAvailableServices();
      const availableServices = data.map(availableService => ({
        value: availableService.name,
        label: availableService.name,
        maxRate: availableService.maxRate,
        minRate: availableService.minRate
      }));
      setAvailableServices(availableServices);
    }

    fetch();
  }, [])

  useEffect(() => {
    if (!loaded) {
      window.scroll({top: 0, left: 0, behavior: 'smooth' });
      setLoaded(true);
    }
  }, [loaded, setLoaded]);

  useEffect(() => {
    if (data.professionGraduationYear && data.professionGraduationYear.match(/\d{4}\-\d{2}\-\d{2}/)) {
      if (!moment(data.professionGraduationYear, 'YYYY-MM-DD').isValid()) {
        setErrors({ professionGraduationYear: 'Fecha invalida' })
      } else {
        setErrors({})
      }
    }
  }, [data.professionGraduationYear]);

  useEffect(() => {
    if (data.specialtyGraduationYear && data.specialtyGraduationYear.match(/\d{4}\-\d{2}\-\d{2}/)) {
      if (!moment(data.specialtyGraduationYear, 'YYYY-MM-DD').isValid()) {
        setErrors({ specialtyGraduationYear: 'Fecha invalida' })
      } else {
        setErrors({})
      }
    }
  }, [data.specialtyGraduationYear]);

  useEffect(() => {
    if (data.birthdate && data.birthdate.match(/\d{4}\-\d{2}\-\d{2}/)) {
      if (!moment(data.birthdate, 'YYYY-MM-DD').isValid()) {
        setErrors({ birthdate: 'Fecha invalida' })
      } else {
        setErrors({})
      }
    }
  }, [data.birthdate]);

  const canSaveAvailableDay = useMemo(() => {
    return availableTime.day && availableTime.from && availableTime.to
  }, [availableTime]);

  const addNewAvailableDay = useCallback(() => {
    setAvailableTime({
      day: '',
      from: null,
      to: null
    });
  }, [availableTime, onChangeData, data.availableTime]);

  const saveAvailableDay = useCallback(() => {
    const newAvailableTime = [...data.availableTime]
    newAvailableTime.push({...availableTime, id: moment().format('x')});
    onChangeData({target: { name: 'availableTime', value: newAvailableTime }});
    setAvailableTime({
      day: '',
      from: null,
      to: null
    });
  }, [availableTime, onChangeData, data.availableTime]);

  const removeAvailableTime = useCallback(removedElement => {
    const newAvailableTime = [...data.availableTime];
    _remove(newAvailableTime, el => el.id === removedElement);
    onChangeData({target: { name: 'availableTime', value: newAvailableTime }});
  }, [onChangeData, data]);

  const updateAvailableTimeField = useCallback(({target: { value, name }}, id) => {
    const newAvailableTime = [...data.availableTime];
    newAvailableTime.find(availableTime => availableTime.id === id)[name] = value;
    onChangeData({target: { name: 'availableTime', value: newAvailableTime }});
  }, [onChangeData, data]);

  const getAvailableServices = useMemo(() => {
    return availableServices.map(availableService => ({
      ...availableService,
      disabled: availableService.value === data.service1 || availableService.value === data.service2
    }))
  }, [availableServices, data.service1, data.service2])

  const handleSelectService = useCallback(({ target: { value, name } }) => {
    const selectedService = availableServices.find(availableService => availableService.value === value)
    onChangeData({ target: { name, value } });
    if (name === 'service1') {
      setService1(selectedService)
    }
    if (name === 'service2') {
      setService2(selectedService)
    }
  }, [onChangeData, availableServices])

  const helperTextForServiceRate = useCallback(service => {
    let minRate = null;
    let maxRate = null;

    if (service === 'service1') {
      if (!service1) {
        return null
      }
      minRate = currencyFormatter(parseInt(service1.minRate), 'cop')
      maxRate = currencyFormatter(parseInt(service1.maxRate), 'cop')
    }
    if (service === 'service2') {
      if (!service2) {
        return null
      }
      minRate = currencyFormatter(parseInt(service2.minRate), 'cop')
      maxRate = currencyFormatter(parseInt(service2.maxRate), 'cop')
    }

    return `El valor del servicio debe estar entre ${minRate} y ${maxRate}`
  }, [service1, service2])

  const handleSetServiceRate = useCallback(({ target: { value, name } }) => {
    onChangeData({ target: { name, value } });
    const minRate = name === 'rateService1' ? parseInt(service1?.minRate) : parseInt(service2?.minRate)
    const maxRate = name === 'rateService1' ? parseInt(service1?.maxRate) : parseInt(service2?.maxRate)
    if (parseInt(value.replace(/\./, '')) < minRate || parseInt(value.replace(/\./, '')) > maxRate) {
      return onValidateStep(false)
    }
    return onValidateStep(true)
  }, [onChangeData, service1, service2, onValidateStep])

  return (
    <div className={styles.stepContent}>
      <div className={styles.formGroup}>
        <p className={styles.formGroupTitle}>Información Personal</p>
        <Grid container spacing={3} className={styles.formGroupContent}>
          <Grid item xs={6}>
            <TextField
              error={Boolean(errors.firstName)}
              errorMessage={errors.firstName}
              label='Nombres'
              name='firstName'
              value={data.firstName}
              onChange={onChangeData}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={Boolean(errors.lastName)}
              errorMessage={errors.lastName}
              label='Apellidos'
              name='lastName'
              value={data.lastName}
              onChange={onChangeData}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={Boolean(errors.email)}
              errorMessage={errors.email}
              label='Correo electrónico'
              name='email'
              value={data.email}
              onChange={onChangeData}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={Boolean(errors.phoneNumber)}
              errorMessage={errors.phoneNumber}
              label='No. Teléfonico'
              name='phoneNumber'
              value={data.phoneNumber}
              onChange={onChangeData}
              mask="cellphone"
              required
            />
          </Grid>
          <Grid item xs={2}>
            <SelectField
              error={Boolean(errors.identificationNumber)}
              errorMessage={errors.identificationNumber}
              label='Tipo'
              name='identificationType'
              value={data.identificationType}
              onChange={onChangeData}
              options={IDENTIFICATION_TYPES}
              required
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              error={Boolean(errors.identificationNumber)}
              errorMessage={errors.identificationNumber}
              label='No de Documento'
              name='identificationNumber'
              value={data.identificationNumber}
              onChange={onChangeData}
              required
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              error={Boolean(errors.idCard)}
              errorMessage={errors.idCard}
              label='Tarjeta Profesional'
              name='idCard'
              value={data.idCard}
              onChange={onChangeData}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={Boolean(errors.residenceCountry)}
              errorMessage={errors.residenceCountry}
              label='País'
              name='residenceCountry'
              value={data.residenceCountry}
              onChange={onChangeData}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={Boolean(errors.residenceCity)}
              errorMessage={errors.residenceCity}
              label='Ciudad'
              name='residenceCity'
              value={data.residenceCity}
              onChange={onChangeData}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              disableToolbar={false}
              error={Boolean(errors.birthdate)}
              errorMessage={errors.birthdate}
              label='Fecha de nacimiento'
              name='birthdate'
              value={data.birthdate}
              onChange={value => onChangeData({target: { name: 'birthdate', value }})}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <RadioGroupField
              error={Boolean(errors.gender)}
              errorMessage={errors.gender}
              options={GENDERS}
              label='Sexo'
              name='gender'
              value={data.gender}
              onChange={onChangeData}
              required
            />
          </Grid>
        </Grid>
      </div>
      <div className={styles.formGroup}>
        <p className={styles.formGroupTitle}>Información academica</p>
        <Grid container spacing={3} className={styles.formGroupContent}>
          <Grid item xs={7}>
            <TextField
              error={Boolean(errors.profession)}
              errorMessage={errors.profession}
              label='Título Profesional'
              name='profession'
              value={data.profession}
              onChange={onChangeData}
              required
            />
          </Grid>
          <Grid item xs={5}>
            <DateField
              disableToolbar={false}
              error={Boolean(errors.professionGraduationYear)}
              errorMessage={errors.professionGraduationYear}
              label='Año de graduación'
              name='professionGraduationYear'
              value={data.professionGraduationYear}
              onChange={value => onChangeData({target: { name: 'professionGraduationYear', value }})}
              required
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              error={Boolean(errors.specialty)}
              errorMessage={errors.specialty}
              label='Especialidad'
              name='specialty'
              value={data.specialty}
              onChange={onChangeData}
            />
          </Grid>
          <Grid item xs={5}>
            <DateField
              disableToolbar={false}
              error={Boolean(errors.specialtyGraduationYear)}
              errorMessage={errors.specialtyGraduationYear}
              label='Año de graduación'
              name='specialtyGraduationYear'
              value={data.specialtyGraduationYear}
              onChange={value => onChangeData({target: { name: 'specialtyGraduationYear', value }})}
            />
          </Grid>
        </Grid>
      </div>
      <div className={styles.formGroup}>
        <p className={styles.formGroupTitle}>Servicios</p>
        <Grid container spacing={3} className={styles.formGroupContent}>
          <Grid item xs={7}>
            <SelectField
              error={Boolean(errors.service1)}
              errorMessage={errors.service1}
              label='Servicio'
              name='service1'
              value={data.service1}
              onChange={handleSelectService}
              options={getAvailableServices}
              required
            />
          </Grid>
          <Grid item xs={5}>
            <CurrencyField
              error={Boolean(errors.rateService1)}
              errorMessage={errors.rateService1}
              label='Tarifa'
              name='rateService1'
              value={data.rateService1}
              onChange={handleSetServiceRate}
              disabled={!data.service1}
              helperText={helperTextForServiceRate('service1')}
              required
            />
          </Grid>
          <Grid item xs={7}>
            <SelectField
              error={Boolean(errors.service2)}
              errorMessage={errors.service2}
              label='Servicio'
              name='service2'
              value={data.service2}
              onChange={handleSelectService}
              options={getAvailableServices}
              displayEmpty
              displayEmptyLabel={null}
            />
          </Grid>
          <Grid item xs={5}>
            <CurrencyField
              error={Boolean(errors.rateService2)}
              errorMessage={errors.rateService2}
              label='Tarifa'
              name='rateService2'
              value={data.rateService2}
              onChange={handleSetServiceRate}
              disabled={!data.service2}
              required={data.service2}
              helperText={helperTextForServiceRate('service2')}
            />
          </Grid>
        </Grid>
      </div>
      <div className={styles.formGroup}>
        <p className={styles.formGroupTitle}>Disponibilidad</p>
        <p className={styles.formGroupTitleMessage}>(Aquí puede indicar los días y el horario en los que estará conectado a la plataforma)</p>
        { data.availableTime.map(availableTime => (
          <Grid container spacing={3} className={clsx(styles.formGroupContent, styles.availableTime)} key={availableTime.id}>
            <Grid item xs={6}>
              <SelectField
                error={Boolean(errors.availableTime)}
                errorMessage={errors.availableTime}
                label='Día'
                name='day'
                value={availableTime.day}
                onChange={evt => updateAvailableTimeField(evt, availableTime.id)}
                options={DAYS_OF_WEEK}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <TimeField
                error={Boolean(errors.availableTime)}
                label='Hora de inicio'
                name='from'
                value={moment(availableTime.from, 'HH:mm')}
                onChange={value => updateAvailableTimeField({target: { name: 'from', value }}, availableTime.id)}
              />
            </Grid>
            <Grid item xs={3}>
              <TimeField
                error={Boolean(errors.availableTime)}
                label='Hora de fin'
                name='to'
                value={moment(availableTime.to, 'HH:mm')}
                onChange={value => updateAvailableTimeField({target: { name: 'to', value }}, availableTime.id)}
              />
            </Grid>
            <Tooltip title="Clic para eliminar" placement="right">
              <IconButton className={styles.removeAvailableTime} onClick={() => removeAvailableTime(availableTime.id)}>
                <SmallCloseIcon size={18}/>
              </IconButton>
            </Tooltip>
          </Grid>
        ))}
        <Grid container spacing={3} className={styles.formGroupContent}>
          <Grid item xs={6}>
            <SelectField
              label='Día'
              name='day'
              value={availableTime.day}
              onChange={({target: { name, value }}) => setAvailableTime({
                ...availableTime,
                [name]: value
              })}
              options={DAYS_OF_WEEK}
              required
            />
          </Grid>
          <Grid item xs={3}>
            <TimeField
              label='Hora de inicio'
              name='from'
              value={availableTime.from ? moment(availableTime.from, 'HH:mm') : null}
              onChange={value => setAvailableTime({
                ...availableTime,
                from: value
              })}
            />
          </Grid>
          <Grid item xs={3}>
            <TimeField
              label='Hora de fin'
              name='to'
              value={availableTime.to ? moment(availableTime.to, 'HH:mm') : null}
              onChange={value => setAvailableTime({
                ...availableTime,
                to: value
              })}
            />
          </Grid>
          <Tooltip title="Clic para guardar" placement="right">
            <IconButton
              className={styles.addAvailableTime}
              onClick={saveAvailableDay}
              disabled={!canSaveAvailableDay}
            >
                <CheckboxIcon color={canSaveAvailableDay ? "#3CD3AE" : "#ddd"} size={18} />
            </IconButton>
          </Tooltip>
        </Grid>
      </div>
    </div>
  )
}

export default Information;
