import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={11} height={16} viewBox="0 0 11 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.364 16v-2.91H1.322C.592 13.09 0 12.44 0 11.637V5.091a5.09 5.09 0 0110.182 0v6.545c0 .804-.592 1.455-1.322 1.455H5.818V16H4.364zm4.002-6.954l-.18.123c-.29.187-.565.286-.913.286-.442 0-.722-.107-1.395-.44a5.144 5.144 0 00-.463-.211.965.965 0 00-.324-.077c-.135 0-.313.063-.652.231l-.11.055c-.645.325-.955.442-1.42.442-.464 0-.78-.118-1.424-.441l-.03-.015v2.637h7.272V8.837a1.44 1.44 0 00-.254.135l-.107.074zM5.091 1.455A3.636 3.636 0 018.727 5.09v2.242a2.767 2.767 0 00-1.05.422l-.175.12-.106.072c-.073.047-.09.053-.123.053-.128 0-.3-.065-.75-.288a6.257 6.257 0 00-.592-.268 2.337 2.337 0 00-.84-.171c-.428 0-.77.119-1.3.383l-.116.058C3.24 7.934 3.062 8 2.91 8c-.154 0-.336-.068-.772-.287a6.299 6.299 0 00-.682-.309V5.091A3.636 3.636 0 015.09 1.455z"
        fill="#262626"
      />
    </svg>
  )
}

export default SvgComponent
