import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 2.25h3a1.5 1.5 0 011.5 1.5v.75h3A1.5 1.5 0 0116.5 6v8.25a1.5 1.5 0 01-1.5 1.5H3a1.5 1.5 0 01-1.5-1.5V6A1.5 1.5 0 013 4.5h3v-.75a1.5 1.5 0 011.5-1.5zM3 6h12v3.75H3V6zm0 8.25v-3h5.25V12h1.5v-.75H15v3H3zm7.5-10.5v.75h-3v-.75h3z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

export default SvgComponent
