import React, { useState, useCallback, useMemo } from 'react';

//Import external libraries
import clsx from 'clsx';
import _get from 'lodash.get';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';

//Import utils or services
import { currencyFormatter } from '../../lib/utils';
import { getCardFranchise, expirationDateValidator, cvvValidator, isCardSupported } from '../../lib/credit_card';
import IdentificationTypes from '../../enums/identification_types';

//Import shared components, order A-Z
import AmexCardIcon from '../shared/icons/credit_cards/amex';
import CreditCardEnabledIcon from '../shared/icons/credit_card_enabled';
import CreditCardDisabledIcon from '../shared/icons/credit_card_disabled';
import DinersCardIcon from '../shared/icons/credit_cards/diners';
import GenericCardIcon from '../shared/icons/credit_cards/generic';
import MasterCardIcon from '../shared/icons/credit_cards/master';
import Modal from '../shared/modal';
import PSEDisabledIcon from '../shared/icons/pse_disabled';
import PSEEnabledIcon from '../shared/icons/pse_enabled';
import SelectOutlinedField from '../shared/outlined_fields/select';
import TextOutlinedField from '../shared/outlined_fields/text';
import VisaCardIcon from '../shared/icons/credit_cards/visa';

//Import controllers
import { PaySubscription } from '../../controllers/subscriptions';

//Import styles
import useStyles from './__styles__/form';

//Import local components

//Import constants or services
const PAYMENT_TYPES = {
  PSE: 'pse',
  CARD: 'card'
}

const STEPS = {
  PAYMENT_METHOD: 'paymentMethod',
  PAYMENT_INFO: 'paymentInfo'
}

const FRANCHISE_ICONS = {
  AMEX: AmexCardIcon,
  DINERS: DinersCardIcon,
  MASTERCARD: MasterCardIcon,
  VISA: VisaCardIcon
}

const Form = ({ onClose, customer, plan, agreement, onAlert, onSubcriptionPaid }) => {
  const styles = useStyles();

  const [errors, setErrors] = useState({});
  const [paymentType, setPaymentType] = useState('card');
  const [currentStep, setCurrentStep] = useState(STEPS.PAYMENT_METHOD);
  const [email] = useState(customer.email);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [identificationNumber, setIdentificationNumber] = useState('');
  const [identificationType, setIdentificationType] = useState('');
  const [phone, setPhone] = useState('');
  const [cardExpirationDate, setCardExpirationDate] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardCVV, setCardCVV] = useState('');
  const [installments, setInstallments] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const cardFranchise = getCardFranchise(cardNumber) || {};

  const selectPaymentType = useCallback(paymentType => {
    setPaymentType(paymentType);
  }, [setPaymentType]);

  const franchiseIcon = () => {
    const Icon = FRANCHISE_ICONS[cardFranchise.code] || GenericCardIcon;
    return <Icon size={36} />
  }

  const validExpirationDate = useMemo(() => {
    if (!cardExpirationDate) {
      return false;
    }
    return expirationDateValidator(cardExpirationDate);
  }, [cardExpirationDate])

  const validateExpirationDate = useCallback(() => {
    if (!validExpirationDate) {
      setErrors({
        ...errors,
        cardExpirationDate: {messages: ['Fecha invalida']}
      });
    } else {
      setErrors({
        ...errors,
        cardExpirationDate: null
      });
    }
  }, [validExpirationDate, errors]);

  const validCVV = useMemo(() => {
    if (!cardNumber || !cardCVV) {
      return false;
    }
    return cvvValidator(cardNumber, cardCVV)
  },  [cardNumber, cardCVV])

  const validateCVV = useCallback(() => {
    if (!validCVV) {
      setErrors({
        ...errors,
        cardCVV: {messages: ['CVV invalido']}
      });
    } else {
      setErrors({
        ...errors,
        cardCVV: null
      });
    }
  }, [validCVV, errors]);

  const validCard = useMemo(() => {
    if (!cardNumber) {
      return false;
    }
    return isCardSupported(cardNumber);
  }, [cardNumber]);

  const validateCard = useCallback(() => {
    const cardErrors = {...errors}
    if (!validCard) {
      cardErrors.cardNumber = {messages: ['Número de tarjeta invalido']}
    } else {
      cardErrors.cardNumber = null;
    }

    if (Object.keys(cardErrors).length) {
      setErrors(cardErrors);
    }

  }, [validCard, errors]);

  const validForm = useMemo(() => {
    if (currentStep === STEPS.PAYMENT_METHOD) {
      return email && paymentType;
    }
    if (currentStep === STEPS.PAYMENT_INFO) {
      return validExpirationDate && validCVV && validCard && firstName && lastName && identificationType && identificationNumber && phone;
    }
    return false
  }, [
    currentStep,
    email,
    paymentType,
    firstName,
    lastName,
    identificationType,
    identificationNumber,
    phone,
    validExpirationDate,
    validCVV,
    validCard
  ]);

  const handleChangeFirstName = useCallback(({ target: { value } }) => {
    setFirstName(value);
  }, []);

  const handleChangeLastName = useCallback(({ target: { value } }) => {
    setLastName(value);
  }, []);

  const handleChangeIdentificationNumber = useCallback(({ target: { value } }) => {
    setIdentificationNumber(value);
  }, []);

  const handleChangeIdentificationType = useCallback(({ target: { value } }) => {
    setIdentificationType(value);
  }, []);

  const handleChangePhone = useCallback(({ target: { value } }) => {
    setPhone(value);
  }, []);

  const handlePayAgreement = useCallback(async () => {
    const [month, year] = cardExpirationDate.split('/');

    const params = {
      numberCard: cardNumber.replace(/-/g, ''),
      expYearCard: year,
      expMonthCard: month,
      cvcCard: cardCVV,
      docType: identificationType,
      docNumber: identificationNumber,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      bill: `Suscripción a ${plan.name}`,
      description: `Suscripción anual del plan ${plan.name}`,
      urlResponse: process.env.REACT_APP_API_BASE_URL,
      urlConfirmation: process.env.REACT_APP_API_BASE_URL,
      currency: 'COP',
      dues: installments,
      paymentMethod: cardFranchise.code
    }
    setIsSubmitting(true);
    const { success, item, errors } = await PaySubscription(agreement.id, params);
    setIsSubmitting(false);
    if (success) {
      onSubcriptionPaid(item.data.rawInvitationToken);
    } else {
      onAlert({ message: errors.errors, type: 'error' });
    }
  }, [
    validForm,
    agreement.id,
    cardExpirationDate,
    cardNumber,
    cardCVV,
    identificationType,
    identificationNumber,
    firstName,
    lastName,
    email,
    phone,
    installments,
    cardFranchise.code
  ]);

  return (
    <Modal
      title={
        <React.Fragment>
          <span className={styles.headerTitle}>Pago suscripción Blue</span>
          <span className={styles.headerPrice}>{currencyFormatter(plan.rate, 'cop')} <span className={styles.headerPriceCurrency}>COP</span></span>
        </React.Fragment>
      }
      buttonSaveLabel={'Continuar'}
      disabled={!validForm}
      onSubmit={evt => {
        evt.preventDefault();
        if (currentStep === STEPS.PAYMENT_METHOD) {
          setCurrentStep(STEPS.PAYMENT_INFO);
        }
        if (currentStep === STEPS.PAYMENT_INFO) {
          handlePayAgreement();
        }
      }}
      isSubmitting={isSubmitting}
      onClose={onClose}
      actionsAlign='left'
      content={(
        <React.Fragment>
          <Slide direction="left" in={currentStep === STEPS.PAYMENT_METHOD} mountOnEnter unmountOnExit exit={false} enter={false}>
            <div>
              <TextOutlinedField
                error={Boolean(errors.email)}
                errorMessage={errors.email}
                label='Correo electrónico'
                name='email'
                value={email}
                size='medium'
                type='email'
                disabled
              />
              <div className={styles.step1}>
                <p className={styles.step}>Paso 1</p>
                <p className={styles.stepTitle}>Seleccione un medio de pago</p>
                <div>
                  <div
                    className={clsx(styles.paymentType, {[styles.paymentTypeActive]: PAYMENT_TYPES.CARD === paymentType})}
                    onClick={() => selectPaymentType(PAYMENT_TYPES.CARD)}
                  >
                    <div className={styles.paymentTypeIconWrapper}>
                      {
                        PAYMENT_TYPES.CARD === paymentType
                        ?
                        <CreditCardEnabledIcon />
                        :
                        <CreditCardDisabledIcon />
                      }
                    </div>
                    <p className={styles.paymentTypeName}>Tarjeta de Crédito y Débito</p>
                  </div>
                  {// <div
                  //   className={clsx(styles.paymentType, {[styles.paymentTypeActive]: PAYMENT_TYPES.PSE === paymentType})}
                  //   onClick={() => selectPaymentType(PAYMENT_TYPES.PSE)}
                  // >
                  //   <div className={styles.paymentTypeIconWrapper}>
                  //     {
                  //       PAYMENT_TYPES.PSE === paymentType
                  //       ?
                  //       <PSEEnabledIcon />
                  //       :
                  //       <PSEDisabledIcon />
                  //     }
                  //   </div>
                  //   <p className={styles.paymentTypeName}>Cuentas de ahorro y corriente</p>
                  // </div>
                  }
                </div>
              </div>
            </div>
          </Slide>
          <Slide direction="left" in={currentStep === STEPS.PAYMENT_INFO} mountOnEnter={false} unmountOnExit>
            <div>
              <div className={styles.step2}>
                <p className={styles.step}>Paso 2</p>
                <p className={styles.stepTitle}>Información de la tarjeta</p>
                <Grid container spacing={2}>
                  {
                    paymentType === PAYMENT_TYPES.PSE
                    &&
                    <Grid item xs={12}>
                      <SelectOutlinedField
                        label='Seleccione su Banco'
                        name='bankId'
                        options={[]}
                        value={''}
                        onChange={null}
                        size='medium'
                        required
                      />
                    </Grid>
                  }

                  <Grid item xs={6}>
                    <TextOutlinedField
                      error={Boolean(errors.firstName)}
                      errorMessage={errors.firstName}
                      label="Nombre"
                      name='firstName'
                      onChange={handleChangeFirstName}
                      value={firstName}
                      required
                      size='medium'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextOutlinedField
                      error={Boolean(errors.lastName)}
                      errorMessage={errors.lastName}
                      label="Apellidos"
                      name='lastName'
                      onChange={handleChangeLastName}
                      value={lastName}
                      required
                      size='medium'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SelectOutlinedField
                      label='Tipo'
                      name='identificationType'
                      options={IdentificationTypes}
                      value={identificationType}
                      onChange={handleChangeIdentificationType}
                      size='medium'
                      required
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextOutlinedField
                      error={Boolean(errors.name)}
                      errorMessage={errors.name}
                      label='Número de documento'
                      name='identificationNumber'
                      onChange={handleChangeIdentificationNumber}
                      value={identificationNumber}
                      required
                      size='medium'
                    />
                  </Grid>
                  {
                    paymentType === PAYMENT_TYPES.CARD
                    &&
                    <React.Fragment>
                      <Grid item xs={12}>
                        <TextOutlinedField
                          label='Número de la tarjeta'
                          name='cardNumber'
                          onChange={({target: { value }}) => setCardNumber(value)}
                          value={cardNumber}
                          required={true}
                          endAdornment={franchiseIcon()}
                          onBlur={validateCard}
                          size='medium'
                          mask='creditCard'
                          error={Boolean(errors.cardNumber)}
                          errorMessage={_get(errors, 'cardNumber.messages.0')}
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextOutlinedField
                          label='MM/AAAA'
                          mask='creditCardExpirationDate'
                          name='cardExpirationDate'
                          onChange={({target: { value }}) => setCardExpirationDate(value)}
                          value={cardExpirationDate}
                          onBlur={validateExpirationDate}
                          error={Boolean(errors.cardExpirationDate)}
                          errorMessage={_get(errors, 'cardExpirationDate.messages.0')}
                          size='medium'
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextOutlinedField
                          label='CVV'
                          mask='fourDigitsCVV'
                          name='cardCVV'
                          onChange={({target: { value }}) => setCardCVV(value)}
                          value={cardCVV}
                          onBlur={validateCVV}
                          error={Boolean(errors.cardCVV)}
                          errorMessage={_get(errors, 'cardCVV.messages.0')}
                          type='password'
                          size='medium'
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <SelectOutlinedField
                          label='Coutas'
                          name='installments'
                          options={[
                            {label: 1, value: 1},
                            {label: 2, value: 2},
                            {label: 3, value: 3},
                            {label: 4, value: 4},
                            {label: 5, value: 5},
                            {label: 6, value: 6},
                            {label: 7, value: 7},
                            {label: 8, value: 8},
                            {label: 9, value: 9},
                            {label: 10, value: 10},
                            {label: 11, value: 11},
                            {label: 12, value: 12}
                          ]}
                          value={installments}
                          onChange={({target: { value }}) => setInstallments(value)}
                          size='medium'
                        />
                      </Grid>
                    </React.Fragment>
                  }
                  <Grid item xs={12}>
                    <TextOutlinedField
                      label='Número móvil'
                      mask='cellphone'
                      name='phone'
                      onChange={handleChangePhone}
                      value={phone}
                      error={Boolean(errors.phone)}
                      errorMessage={_get(errors, 'cardCVV.phone.0')}
                      size='medium'
                      required={true}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Slide>
        </React.Fragment>
      )}
    />
  )
}

export default Form;
