import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    borderRadius: 2,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    marginBottom: 26,
    marginRight: 26,
    width: 296,
    '&:last-child': {
      marginRight: 0
    }
  },
  header: {
    alignItems: 'center',
    background: '#1F2C56',
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    display: 'flex',
    height: 49,
    padding: [[0, 16]]
  },
  name: {
    color: '#FAFAFA',
    flex: 1,
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 0.4,
    margin: 0,
    textTransform: 'uppercase'
  },
  content: {
    padding: [[15, 16]]
  },
  attribute: {
    marginBottom: 11,
    '&:last-child': {
      marginBottom: 0
    }
  },
  attributeLabel: {
    color: '#717992',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 1.5,
    lineHeight: '16px',
    textTransform: 'uppercase',
    margin: 0
  },
  attributeValue: {
    color: '#000000',
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.44px',
    margin: 0
  },
  menuIconColor: {
    color: '#FFFFFF'
  },
  menuItem: {
    borderBottom: [[1, 'solid', '#F1F3F9']],
    color: '#000000',
    fontSize: 14,
    letterSpacing: 0.44,
    lineHeight: '24px',
    '&:last-child': {
      borderBottom: 0
    }
  },
  inactive: {
    backgroundColor: '#B3B3B3'
  },
  wrapped: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  oneColumn: {
    width: '50%'
  }
}));
