import React, { useCallback, useContext, useEffect, useState } from 'react';

//Import external libraries
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

//Import utils or services
import _snakeCase from 'lodash.snakecase';

//Import shared components, order A-Z
import Alert from '../shared/alert';
import Button from '../shared/button';
import Loading from '../shared/loading';
import Table from '../shared/table';
import TableWrapper from '../shared/table_wrapper';

//Import controllers
import { CurrentUserContext } from '../../controllers/users/current';
import { ShowMarketingAgent } from '../../controllers/marketing_agents';
import { ListCustomers } from '../../controllers/customers';
import { ListAnalityCustomers, ListYearsCustomer, CountCustomerLastMonth } from '../../controllers/stats/customers';

//Import styles
import useStyles from './__styles__';

//Import local components
import AssignedPlans from './assigned_plans';
import CurrentLevelInfo from './current_level_info';
import GraphReport from './graph_report';
import Form from './form'
import NewCustomerReport from './new_customer_report';

//Import constants or services
import { columns } from './data_table_schema';

const MarketingDashboard = () => {
  const [alert, setAlert] = useState({});
  const { currentUser } = useContext(CurrentUserContext);
  const [initialMonths] = useState([ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ]);
  const [countMonths, setCountMonths] = useState(initialMonths);
  const [countCustomers, setCountCustomers] = useState(0);
  const [currentScore, setCurrentScore] = useState(0);
  const [currentYear, setCurrentYear] = useState(0);
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(0);
  const [level, setLevel] = useState();
  const [loading, setLoading] = useState(true);
  const [marketingAgent, setMarketingAgent] = useState({
    plans: []
  });
  const [months] = useState([
    "Jan", "Feb", "Mar", "Apr","May", "Jun",
    "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
  ]);
  const [order] = useState('desc');
  const [orderBy] = useState('firstName');
  const [openForm, setOpenForm] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState();
  const styles = useStyles();
  const [year, setYear] = useState([]);
  const [userId, setUserId] = useState();

  const handleCreateCustomer = () => {
    setOpenForm(true);
  }

  const handleNextYear = () => {
    if(index + 1 < Object.keys(year).length){
      setCurrentYear(year[index +1].year);
      setIndex(index + 1);
      fetchAnalityCustomers(year[index +1].year);
    }
  }

  const handlePreviousYear = () => {
    if(index - 1 >= 0){
      setCurrentYear(year[index - 1].year);
      setIndex(index - 1);
      fetchAnalityCustomers(year[index - 1].year);
    }
  }

  const fetchAnalityCustomers = useCallback ( async (by_year) => {
    const { items }  =  await ListAnalityCustomers(by_year, userId);
    const newInitialMonths  = [ ...initialMonths ];

    items.customersCreatedPerMonths.forEach( item => {
      const position = months.findIndex( month => month === item.month);

      if(position){
        newInitialMonths[position] = item.count;
      }

    });
    setCountMonths(newInitialMonths);
    const data = await CountCustomerLastMonth(userId);

    if(data){
      setCountCustomers(data.items.customers[0].count);
    }
  }, [initialMonths, months, userId]);

  const fetchCustomers = useCallback (async (page = 1) =>{
    if(userId){
      setLoading(true);
      const itemsPerPage = 5;
      const { items, pagination} = await ListCustomers(page, {added_by_eq: userId}, `${_snakeCase(orderBy)} ${order}`, itemsPerPage);
      setData(items);
      setPaginationInfo(pagination);
      setLoading(false);
    }
  }, [order, orderBy, userId]);

  const fetchOne = useCallback( async () => {
    if(userId){
      const { item } = await ShowMarketingAgent(userId);
      setMarketingAgent({...item, plans: item.plans || []});

      if (item.currentMarketingScore){
        setCurrentScore(item.currentMarketingScore.score);
        setLevel(item.currentMarketingScore.marketingLevel);
      }
    }
  }, [userId]);

  const fetchYear = useCallback ( async () => {
    if(userId){
      const { items } =  await ListYearsCustomer(userId);
      if(items.years.length){
        setYear(items.years);
        setIndex(0);
        setCurrentYear(items.years[0].year);
        fetchAnalityCustomers(items.years[0].year);
      }
    }
  }, [fetchAnalityCustomers, userId]);

  useEffect(() => {
    setUserId(currentUser.id);
    fetchOne();
    fetchYear();
    fetchCustomers();
  }, [currentUser, fetchOne, fetchCustomers, fetchYear]);

  return (
    <React.Fragment>
      <div className={styles.header}>
        <Alert type={alert.type} message={alert.message} display={!!alert.type}/>
        <div className={styles.wrapperContent}>
          <div className={styles.pageInformation}>
            <p className={styles.pageTitle}>{`${currentUser.firstName} ${currentUser.lastName}`}</p>
            <p className={styles.pageSubtitle}>{currentUser.email}</p>
          </div>
          <div className={styles.headerActions}>
            <Button
              label='Nuevo cliente'
              size='small'
              onClick={handleCreateCustomer}
            />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={clsx(styles.wrapperContent, styles.wrapperBody)}>
          <div className={styles.contentHeader}>
            <AssignedPlans
              plans={marketingAgent.plans.map( plans => ({ label: plans.name }))}
            />
            <CurrentLevelInfo
              name={level ? level.name : ''}
              pointsForNextLevel={level ? level.endPoints - currentScore : 0}
              totalPoints={level ? level.endPoints : 0}
              currentPoints={currentScore}
            />
          </div>
          {
            loading
            &&
            <Loading/>
          }
          {
            !loading
            &&
            <TableWrapper
              totalItems={paginationInfo ? `+${paginationInfo.count}` : '+0'}
              title='Usuarios registrados'
              actions={
                <React.Fragment>
                  <Button
                    label='Ver todos'
                    variant='text'
                    size='small'
                    component={NavLink}
                    to='/customers'
                  />
                </React.Fragment>
              }
              table={
                <Table
                  columns={columns}
                  data={data}
                  loading={false}
                  fetchData={fetchCustomers}
                  onRowClick={null}
                />
              }
            />
          }
          <div className={styles.reports}>
            <div className={styles.graphReport}>
              <GraphReport
                data={countMonths}
                currentYear={currentYear}
                onPreviousYear={handlePreviousYear}
                onNextYear={handleNextYear}
                months={months}
              />
            </div>
            <div className={styles.newCustomerReport}>
              <NewCustomerReport newCustomersInCurrentMonth={countCustomers}/>
            </div>
          </div>
        </div>
        { openForm
          &&
          <Form
            onClose={() => setOpenForm(false)}
            labelButton={'Crear'}
            setAlert={setAlert}
            onComplete={() => {
              fetchCustomers();
              fetchYear();
            }}
          />
        }
      </div>
    </React.Fragment>
  )
}

export default MarketingDashboard;
