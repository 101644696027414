import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import TextField from '../../shared/fields/text';
import CheckboxField from '../../shared/fields/checkbox';

import useStyles from '../__styles__';

import { STEPS } from '../constants';

const Documents = ({ onBack, data, onChangeData}) => {
  const styles = useStyles();

  const [loaded, setLoaded] = useState(false);
  const [isChecked, setIsChecked] = useState(data.acceptedTermsAndConditions);

  useEffect(() => {
    if (!loaded) {
      window.scroll({top: 0, left: 0, behavior: 'smooth' });
      setLoaded(true);
    }
  }, [loaded, setLoaded]);

  const handleBack = useCallback(() => {
    onBack(STEPS.DOCUMENTS);
  }, [onBack])

  const toggleChange = () => {
    onChangeData({target: {name: 'acceptedTermsAndConditions', value: !isChecked}})
    setIsChecked(!isChecked);
  }
  return (
    <div className={styles.stepContent}>
      <Button className={styles.backButton} onClick={handleBack}><ArrowBackIosIcon fontSize="small" />Atras</Button>
      <Grid container spacing={3} className={styles.formGroupContent}>
        <Grid item xs={12}>
          <TextField
            label='¿Cómo conoció esta aplicación?'
            name='howDidYouKnowAboutUs'
            value={data.howDidYouKnowAboutUs}
            onChange={onChangeData}
            multiline={true}
            rows={5}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label='¡Queremos conocerle! Por favor, deje una breve reseña sobre usted.'
            name='description'
            value={data.description}
            onChange={onChangeData}
            multiline={true}
            rows={5}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxField
           checked={isChecked}
           onChange={toggleChange}
           label={(
              <span>Al enviar la documentación acepto las condiciones, términos y restricciones de la plataforma Blue.
              <a
                href="https://blue-assets.s3.amazonaws.com/TERMINOS+Y+CONDICIONES+GENERALES+BLUE.pdf"
                className={styles.openTermsAndConditions}
                target="_blank"
                rel="noopener noreferrer"
              >Ver términos aquí.</a></span>
           )}/>
        </Grid>
      </Grid>
    </div>
  )
}

export default Documents;
