import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={21} height={16} viewBox="0 0 21 16" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.561 8.51a5 5 0 10-7.122 0A7 7 0 002 15.009a1 1 0 001 1h14a1 1 0 001-1 7 7 0 00-4.439-6.5zM10 2.009a3 3 0 110 6 3 3 0 010-6zm-1 8a5.01 5.01 0 00-4.9 4h11.8a5.01 5.01 0 00-4.9-4H9z"
          fill="#262626"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h21v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgComponent
